import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useCreateDocument } from "@/state/mutations/documents/createDocument";
import { useDocumentTypes } from "@/state/queries/documentTypes";
import { useProcesses } from "@/state/queries/processes";
import { useUser } from "@/state/queries/user";
import { Dialog, Grid, Select, Text, TextField } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { capitalize, isEmpty } from "lodash-es";
import { useEffect, useState } from "react";
import { z } from "zod";
import MeridianDialog from "../dialogs";

interface CreateDocumentInputs {
  file: File | null;
  name: string;
  description: string;
  type: string;
  processId: string;
  id: string;
  owner: string;
}

const allowedMimeTypes = new Set([
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
]);

export const CreateDocumentAction: React.FC<{ processId?: string }> = ({
  processId,
}) => {
  const [open, setOpen] = useState(false);
  const { data: user } = useUser();
  const { data: documentTypes } = useDocumentTypes();
  const { data: processes } = useProcesses();
  const {
    mutate: createDocument,
    error: createDocumentError,
    isPending,
    reset: resetMutation,
  } = useCreateDocument();

  const form = useForm<CreateDocumentInputs, any>({
    defaultValues: {
      file: null,
      name: "",
      description: "",
      type: documentTypes?.[0]?.id ?? "",
      owner: user?.id ?? "",
      processId: processId ?? processes?.[0]?.id ?? "",
      id: "",
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        file: z
          .instanceof(File, { message: "File is required" })
          .refine((file) => {
            if (!file) return true;
            if (allowedMimeTypes.has(file.type)) return true;
            return false;
          }, "Allowed formats: pdf, doc(x), xls(x), ppt(x)"),
        name: z.string().min(1, { message: "Document name is required" }),
        description: z.string(),
        type: documentTypes?.length
          ? z.enum(documentTypes.map((dt) => dt.id) as [string, ...string[]])
          : z.string(),
        id: z.string().regex(/^\d{3}-\d{3}$/, {
          message: "Format: xxx-yyy",
        }),
        // TODO add validation that the document ID is unique
      }),
    },
    onSubmit: ({ value }) => {
      const prefix = documentTypes?.find((dt) => dt.id === value.type)?.prefix;
      if (!prefix) {
        throw new Error("Document type not found");
      }
      createDocument(
        {
          ...value,
          id: `${prefix}-${value.id}`,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    resetMutation();
  };

  // Set the document name to the file name if it's not already set
  const file = form.useStore((state) => state.values.file);
  useEffect(() => {
    const documentName = form.getFieldValue("name");
    if (isEmpty(documentName) && file) {
      const autoDocumentName = capitalize(file.name.split(".")[0]);
      form.setFieldValue("name", autoDocumentName);
      form.validateField("name", "change");
    }
  }, [form, file]);

  const documentTypePrefix = form.useStore((state) => {
    const type = state.values.type;
    const documentType = documentTypes?.find((dt) => dt.id === type);
    return documentType?.prefix;
  });

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton permission="write.documents">
          <IconPlus />
          Create document
        </PermissionedButton>
      </Dialog.Trigger>

      <MeridianDialog.Content>
        <MeridianDialog.Header title="Create document" />

        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Form.Fields>
              <Form.TextField<CreateDocumentInputs>
                reactFormField={{ form, name: "name" }}
                fieldProps={{ label: "Document name", required: true }}
                placeholder="Enter document name"
              />
              <Form.TextArea<CreateDocumentInputs>
                reactFormField={{ form, name: "description" }}
                fieldProps={{ label: "Description" }}
                placeholder="Enter description"
              />
              <Grid columns="3" gap="4">
                <Form.Select<CreateDocumentInputs>
                  reactFormField={{ form, name: "type" }}
                  fieldProps={{ label: "Document type", required: true }}
                >
                  <Select.Content>
                    {documentTypes?.map((dt) => (
                      <Select.Item key={dt.id} value={dt.id}>
                        {dt.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Form.Select>
                <Form.ProcessSelect<CreateDocumentInputs>
                  reactFormField={{ form, name: "processId" }}
                  fieldProps={{ label: "Parent Process", required: true }}
                />
                <Form.TextField<CreateDocumentInputs>
                  reactFormField={{ form, name: "id" }}
                  fieldProps={{ label: "Identifier", required: true }}
                  placeholder="500-001"
                >
                  {documentTypePrefix && (
                    <TextField.Slot>
                      <Text>{documentTypePrefix}-</Text>
                    </TextField.Slot>
                  )}
                </Form.TextField>
              </Grid>
              <Form.UserSelect<CreateDocumentInputs>
                reactFormField={{ form, name: "owner" }}
                fieldProps={{ label: "Owner", required: true }}
              />
              <Form.FileUpload<CreateDocumentInputs>
                reactFormField={{ form, name: "file" }}
                fieldProps={{
                  label: "Upload file",
                  required: true,
                }}
              />
            </Form.Fields>
            <Form.Footer
              form={form}
              error={createDocumentError}
              isPending={isPending}
              buttonText="Create document"
              errorPrefix="Failed to create document: "
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

import { Flex } from "@radix-ui/themes";
import React from "react";
import NonIdealState from "./NonIdealState";

export interface EmptyPageProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  action?: React.ReactNode;
  delay?: number;
}

const EmptyPage: React.FC<EmptyPageProps> = ({
  icon,
  title,
  description,
  action,
  delay = 250,
}) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      style={{ height: "100vh", width: "100%" }}
    >
      {show && (
        <NonIdealState
          icon={icon}
          title={title}
          description={description}
          action={action}
        />
      )}
    </Flex>
  );
};

export default EmptyPage;

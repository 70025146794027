import { AlertDialog, Dialog } from "@radix-ui/themes";
import React from "react";

interface MeridianAlertDialogContentProps extends AlertDialog.ContentProps {
  children: React.ReactNode;
  size?: Dialog.ContentProps["size"];
  maxWidth?: Dialog.ContentProps["maxWidth"];
}

export const MeridianAlertDialogContent: React.FC<
  MeridianAlertDialogContentProps
> = ({ children, size = "3", maxWidth = "450px", ...props }) => {
  return (
    <AlertDialog.Content size={size} maxWidth={maxWidth} {...props}>
      {children}
    </AlertDialog.Content>
  );
};

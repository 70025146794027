import { useTenantContext } from "@/contexts/TenantContext";
import { Process, ProcessRevision } from "@/state/queries/processes";
import { useUser } from "@/state/queries/user";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

interface ApproveProcessRevisionInput {
  process: Process;
  revision: ProcessRevision;
}

const getNextRevisionNumber = async (processId: string, tenantId: string) => {
  const { data: latestRevision, error: latestRevisionError } = await supabase
    .from("process_revisions")
    .select("revision_number")
    .eq("process_id", processId)
    .eq("tenant_id", tenantId)
    .not("revision_number", "is", null)
    .order("revision_number", { ascending: false })
    .limit(1);

  if (latestRevisionError) {
    throw latestRevisionError;
  }

  if (!latestRevision || !latestRevision?.[0]?.revision_number) {
    return 1;
  }

  return latestRevision[0].revision_number + 1;
};

const approveProcessRevision = async (
  input: ApproveProcessRevisionInput,
  currentUserId: string
) => {
  const nextRevisionNumber = await getNextRevisionNumber(
    input.process.id,
    input.process.tenantId
  );

  const { error: processRevisionError } = await supabase
    .from("process_revisions")
    .update({
      status: "Released",
      revision_number: nextRevisionNumber,
      approved_at: dayjs().toISOString(),
      approved_by: currentUserId,
    })
    .eq("id", input.revision.id)
    .eq("process_id", input.process.id)
    .eq("tenant_id", input.process.tenantId);

  if (processRevisionError) {
    throw processRevisionError;
  }

  // Mark all other previously released revisions as Obsolete
  const { error: obsoleteProcessRevisionsError } = await supabase
    .from("process_revisions")
    .update({
      status: "Obsolete",
      obsolete_at: dayjs().toISOString(),
    })
    .eq("process_id", input.process.id)
    .eq("status", "Released")
    .neq("id", input.revision.id)
    .eq("tenant_id", input.process.tenantId);

  if (obsoleteProcessRevisionsError) {
    throw obsoleteProcessRevisionsError;
  }

  if (input.process.status !== "Active") {
    const { error: processError } = await supabase
      .from("processes")
      .update({
        status: "Active",
      })
      .eq("id", input.process.id)
      .eq("tenant_id", input.process.tenantId);

    if (processError) {
      throw processError;
    }
  }
};

export const useApproveProcessRevision = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();
  const user = useUser();

  return useMutation({
    mutationFn: (input: ApproveProcessRevisionInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      if (!user.data) {
        throw new Error("User not found");
      }
      return approveProcessRevision(input, user.data.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
      queryClient.invalidateQueries({
        queryKey: ["complianceFrameworks", tenant.tenant?.tenantId],
      });
    },
  });
};

import { diffWords } from "diff";

export function TextDiff({
  beforeText,
  afterText,
}: {
  beforeText: string;
  afterText: string;
}) {
  const diffs = diffWords(beforeText, afterText);
  return diffs.map((part, index) => {
    if (part.added) {
      return <AddedText>{part.value}</AddedText>;
    } else if (part.removed) {
      return <RemovedText>{part.value}</RemovedText>;
    } else {
      return <span key={index}>{part.value}</span>;
    }
  });
}

interface ChangedTextProps {
  as?: "div" | "span";
  children: React.ReactNode;
}

export const AddedText = ({ as = "span", children }: ChangedTextProps) => {
  const style = {
    backgroundColor: "var(--green-3)",
    borderRadius: "var(--radius-1)",
  };
  return as === "span" ? (
    <span style={style}>{children}</span>
  ) : (
    <div style={style}>{children}</div>
  );
};

export const RemovedText = ({ as = "span", children }: ChangedTextProps) => {
  const style = {
    backgroundColor: "var(--red-3)",
    textDecoration: "line-through",
    borderRadius: "var(--radius-1)",
  };
  return as === "span" ? (
    <span style={style}>{children}</span>
  ) : (
    <div style={style}>{children}</div>
  );
};

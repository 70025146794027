import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface ComplianceMappingInput {
  controlId: string;
  frameworkId: string;
  clause: string;
}

export const useAddComplianceMapping = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: async (input: ComplianceMappingInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      const { error } = await supabase
        .from("control_requirements_mapping")
        .insert({
          tenant_id: tenant.tenant.tenantId,
          control_id: input.controlId,
          framework_id: input.frameworkId,
          clause: input.clause,
        });

      if (error) {
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

export const useDeleteComplianceMapping = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: async (input: ComplianceMappingInput) => {
      const { error } = await supabase
        .from("control_requirements_mapping")
        .delete()
        .eq("tenant_id", tenant.tenant.tenantId)
        .eq("control_id", input.controlId)
        .eq("framework_id", input.frameworkId)
        .eq("clause", input.clause);

      if (error) {
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

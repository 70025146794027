import { PermissionedButton } from "@/components/PermissionedButton";
import { useSendRevisionForReview } from "@/state/mutations/documents/sendRevisionForReview";
import { Document } from "@/state/queries/documents";
import { THEME_COLOR } from "@/theme";
import { AlertDialog } from "@radix-ui/themes";
import { IconSend } from "@tabler/icons-react";
import { useState } from "react";
import MeridianAlertDialog from "../alert-dialogs";

export const SendRevisionForReviewAction: React.FC<{
  document: Document;
  revisionId: string;
  isInitialRevision: boolean;
  onSuccess?: () => void;
}> = ({ document, revisionId, isInitialRevision, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: sendRevisionForReview,
    isPending,
    error,
    reset,
  } = useSendRevisionForReview(isInitialRevision);

  const onSubmit = () => {
    sendRevisionForReview(
      { documentId: document.id, revisionId, tenantId: document.tenantId },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton permission="write.documents" variant="soft">
          <IconSend />
          Send for Review
        </PermissionedButton>
      </AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Send for review?"
          description="Once you send this revision for review, you won't be able to make further changes until the review is complete."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Send for review",
            color: THEME_COLOR,
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

import { PermissionedButton } from "@/components/PermissionedButton";
import { useRejectProcessRevision } from "@/state/mutations/processes/revisions/rejectProcessRevision";
import { Process, ProcessRevision } from "@/state/queries/processes";
import { THEME_COLOR } from "@/theme";
import { AlertDialog } from "@radix-ui/themes";
import { IconX } from "@tabler/icons-react";
import { useState } from "react";
import MeridianAlertDialog from "../alert-dialogs";

export const RejectProcessRevisionAction: React.FC<{
  process: Process;
  revision: ProcessRevision;
  onSuccess?: () => void;
}> = ({ process, revision, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: rejectProcessRevision,
    isPending,
    error,
    reset,
  } = useRejectProcessRevision();

  const onSubmit = () => {
    rejectProcessRevision(
      { process, revision },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="surface"
          color="gray"
          size="1"
        >
          <IconX />
          Reject
        </PermissionedButton>
      </AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Reject revision?"
          description="This revision will be moved back to draft status."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Reject revision",
            color: THEME_COLOR,
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

import { useTenantContext } from "@/contexts/TenantContext";
import { Database } from "@/database/generated.types";
import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery, UseQueryResult } from "@tanstack/react-query";
import { every } from "lodash";

export interface ComplianceMappedProcess {
  id: string;
  name: string;
  owner: string;
  status: Database["public"]["Enums"]["process_status"];
  controlLanguage: string;
}

export interface ComplianceRequirement {
  frameworkId: string;
  clause: string;
  title: string;
  language?: string;
  status: "Not started" | "In progress" | "Released";
  processes: ComplianceMappedProcess[];
}

export interface ComplianceFramework {
  id: string;
  displayName: string;
  description?: string;
  requirements: ComplianceRequirement[];
}

const fetchComplianceFrameworks = async (
  tenantId: string
): Promise<ComplianceFramework[]> => {
  const result = await supabase
    .from("framework_subscriptions")
    .select(
      `
    compliance_frameworks (
      id,
      display_name,
      description,
      compliance_framework_requirements (
        framework_id,
        clause,
        title,
        language,
        control_requirements_mapping (
          framework_id,
          controls (
            control_revisions (
              language,
              process_revisions (
                status,
                processes (
                  id,
                  name,
                  status,
                  owned_by
                )
              )
            )
          )
        )
      )
    )
  `
    )
    .eq(
      "compliance_frameworks.compliance_framework_requirements.control_requirements_mapping.tenant_id",
      tenantId
    )
    .eq("tenant_id", tenantId);

  if (result.error) {
    throw result.error;
  }

  if (!result.data) {
    return [];
  }

  const frameworks = result.data
    .map((sub) => sub.compliance_frameworks)
    .filter((framework) => !!framework);

  return frameworks.map((framework) => ({
    id: framework.id,
    displayName: framework.display_name,
    description: framework.description ?? undefined,
    requirements: framework.compliance_framework_requirements.map(
      (requirement) => {
        const processes = requirement.control_requirements_mapping
          .flatMap((mapping) => mapping.controls?.control_revisions)
          .filter(
            (revision) => revision?.process_revisions?.status !== "Obsolete"
          )
          .filter((revision) => revision?.process_revisions?.processes)
          .map((revision) => {
            const process = revision?.process_revisions?.processes;
            return {
              id: process?.id ?? "",
              name: process?.name ?? "",
              owner: process?.owned_by ?? "",
              status: process?.status ?? "Draft",
              controlLanguage: revision?.language ?? "",
            };
          });
        return {
          frameworkId: requirement.framework_id,
          clause: requirement.clause,
          title: requirement.title,
          language: requirement.language ?? undefined,
          status:
            processes.length === 0
              ? "Not started"
              : every(processes, (process) => process.status === "Active")
                ? "Released"
                : every(
                      processes,
                      (process) =>
                        process.status === "In review" ||
                        process.status === "Draft"
                    )
                  ? "In progress"
                  : "Not started",
          processes,
        };
      }
    ),
  }));
};

export const complianceFrameworksQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["complianceFrameworks", tenantId],
    queryFn: () => fetchComplianceFrameworks(tenantId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  });

export const useComplianceFrameworks = (): UseQueryResult<
  ComplianceFramework[]
> => {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(complianceFrameworksQueryOptions(tenant.tenant.tenantId));
};

export const useComplianceFramework = (frameworkId: string) => {
  const { data: frameworks, ...rest } = useComplianceFrameworks();
  return {
    ...rest,
    data: frameworks?.find((framework) => framework.id === frameworkId),
  };
};

export const useFrameworkRequirementLanguage = (
  frameworkId: string,
  clause: string
) => {
  const frameworks = useComplianceFrameworks();
  return frameworks.data
    ?.find((framework) => framework.id === frameworkId)
    ?.requirements.find((requirement) => requirement.clause === clause)
    ?.language;
};

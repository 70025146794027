import { ControlLanguageDiff } from "@/components/controls/ControlLanguageDiff";
import { ControlMapping } from "@/components/controls/ControlMapping";
import { DefaultStatusBadge } from "@/components/DefaultStatusBadge";
import { FullWidthSeparator } from "@/components/dialogs/FullWidthSeparator";
import NonIdealState from "@/components/NonIdealState";
import { PermissionedButton } from "@/components/PermissionedButton";
import { AddComplianceMappingAction } from "@/components/processes/AddComplianceMappingAction";
import { AddControlAction } from "@/components/processes/AddControlAction";
import { ApproveProcessRevisionAction } from "@/components/processes/ApproveProcessRevisionAction";
import { CreateProcessRevisionButton } from "@/components/processes/CreateProcessRevisionButton";
import { DeleteProcessRevisionAction } from "@/components/processes/DeleteProcessRevisionAction";
import { EditControlAction } from "@/components/processes/EditControlAction";
import { RejectProcessRevisionAction } from "@/components/processes/RejectProcessRevisionAction";
import { RemoveControlAction } from "@/components/processes/RemoveControlAction";
import { SendProcessRevisionForReviewAction } from "@/components/processes/SendProcessRevisionForReviewAction";
import TabTitle from "@/components/TabTitle";
import { TextDiff } from "@/components/TextDiff";
import UserName from "@/components/UserName";
import { useComplianceFrameworks } from "@/state/queries/complianceFrameworks";
import {
  Process,
  ProcessControl,
  ProcessRevision,
  useProcess,
} from "@/state/queries/processes";
import { THEME_COLOR } from "@/theme";
import { compareClauses } from "@/utils/compareClauses";
import {
  Box,
  Button,
  Callout,
  Flex,
  Heading,
  Popover,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import {
  IconArrowLeft,
  IconArrowsDiff,
  IconChecklist,
  IconChevronDown,
  IconPencil,
  IconPlus,
  IconVersions,
} from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { z } from "zod";

export const Route = createFileRoute(
  "/_app/$tenantSlug/processes/$processId/controls"
)({
  validateSearch: z.object({
    revision: z.string().optional(),
  }),
  beforeLoad: () => ({
    getTitle: () => "Controls",
  }),
  component: ControlsPage,
});

function ControlsPage() {
  const { processId } = Route.useParams();
  const { revision: revisionId } = Route.useSearch();
  const { data: process } = useProcess(processId);
  const [isComparing, setIsComparing] = useState(false);

  const revision = revisionId
    ? process?.revisions.find((r) => r.id === revisionId)
    : process?.latestRevision;

  // Automatically activate comparison if the revision is pending review
  useEffect(() => {
    if (revision?.status === "Pending review") {
      setIsComparing(true);
    } else {
      setIsComparing(false);
    }
  }, [revision]);

  const canCompareRevision =
    (revision?.status !== "Released" &&
      revision?.revisionNumber &&
      revision.revisionNumber > 1) ||
    (!revision?.revisionNumber &&
      process?.revisions.length &&
      process.revisions.length > 1);

  const comparisonRevision = useMemo(() => {
    if (!process || !revision || !isComparing || !canCompareRevision) {
      return undefined;
    }

    // If comparing a released revision, find the previous released revision
    if (revision.revisionNumber && revision.revisionNumber > 1) {
      return process.revisions.find(
        (r) => r.revisionNumber === revision.revisionNumber! - 1
      );
    }
    // If comparing a draft revision, find the previous revision
    // Assumes revisions are sorted in descending order by creation time
    else {
      return process.revisions[1];
    }
  }, [revision, isComparing, canCompareRevision, process]);

  const title = (
    <Flex align="center" gap="3">
      <Heading size="5">Controls</Heading>
      {process && (
        <RevisionsMenu process={process} currentRevision={revision} />
      )}
      {process && revision && revision.status === "Draft" && !isComparing && (
        <AddControlAction processId={processId} revision={revision}>
          <PermissionedButton
            permission="write.processes"
            size="1"
            variant="soft"
          >
            <IconPlus />
            Add
          </PermissionedButton>
        </AddControlAction>
      )}
    </Flex>
  );

  // TODO: Make this more robust
  // TODO: In comparison mode, this should show controls that were removed
  const sortedControls = revision?.controls.sort((a, b) =>
    compareClauses(a.title.split(" ")[0], b.title.split(" ")[0])
  );

  return (
    <>
      {process && revision && (
        <RevisionBanner process={process} revision={revision} />
      )}
      <TabTitle title={title}>
        {process?.status !== "Draft" && canCompareRevision && (
          <Tooltip content="Compare with previous revision">
            <Button
              variant={isComparing ? "solid" : "soft"}
              color="gray"
              size="1"
              onClick={() => setIsComparing(!isComparing)}
            >
              <IconArrowsDiff />
              Compare changes
            </Button>
          </Tooltip>
        )}
      </TabTitle>
      <Flex align="center"></Flex>
      <Box pt="2">
        {process && revision && !revision.controls.length && (
          <NonIdealState
            icon={<IconChecklist />}
            title="No controls"
            description="No controls have been added to this process yet"
            action={
              <AddControlAction processId={processId} revision={revision}>
                <PermissionedButton permission="write.processes">
                  <IconPlus />
                  Add control
                </PermissionedButton>
              </AddControlAction>
            }
            flexProps={{
              mt: "6",
            }}
          />
        )}
        {process &&
          revision &&
          sortedControls?.map((control) => (
            <ProcessControlComponent
              key={control.controlRevisionId}
              revision={revision}
              control={control}
              isComparing={isComparing}
              compareWithControl={
                isComparing
                  ? comparisonRevision?.controls.find(
                      (c) => c.controlId === control.controlId
                    )
                  : undefined
              }
            />
          ))}
      </Box>
    </>
  );
}

function RevisionBanner({
  process,
  revision,
}: {
  process: Process;
  revision: ProcessRevision;
}) {
  const navigate = Route.useNavigate();

  if (revision.status !== "Draft" && revision.status !== "Pending review") {
    return null;
  }

  const actions = (
    <Flex align="center" gap="2" style={{ gridColumnStart: "2" }}>
      {/* Only show delete if the process is not in draft state. If it is, a delete action is shown on the process page */}
      {process.status !== "Draft" && revision.status === "Draft" && (
        <DeleteProcessRevisionAction
          process={process}
          revision={revision}
          onSuccess={() => {
            navigate({
              search: { revision: undefined },
            });
          }}
        />
      )}
      {revision.status === "Draft" && (
        <SendProcessRevisionForReviewAction
          process={process}
          revision={revision}
        />
      )}
      {revision.status === "Pending review" && (
        <RejectProcessRevisionAction process={process} revision={revision} />
      )}
      {revision.status === "Pending review" && (
        <ApproveProcessRevisionAction process={process} revision={revision} />
      )}
    </Flex>
  );

  const calloutText =
    process.status === "Draft"
      ? "Drafting initial revision of this process"
      : process.status === "In review"
        ? "Reviewing initial revision of this process"
        : revision.status === "Draft"
          ? "You are drafting changes to this process"
          : "Reviewing changes to this process";

  const maybeBackButton = process.status === "Active" && (
    <Callout.Icon>
      <Button
        variant="outline"
        color="gray"
        size="1"
        onClick={() =>
          navigate({
            search: { revision: undefined },
          })
        }
      >
        <IconArrowLeft />
        Back
      </Button>
    </Callout.Icon>
  );

  return (
    <Callout.Root
      color="gray"
      variant="surface"
      size="1"
      mt="3"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Flex gap="2" align="center">
        {maybeBackButton}
        <Callout.Text>{calloutText}</Callout.Text>
      </Flex>
      {actions}
    </Callout.Root>
  );
}

function RevisionsMenu({
  process,
  currentRevision,
}: {
  process: Process;
  currentRevision: ProcessRevision | undefined;
}) {
  const [open, setOpen] = useState(false);
  const navigate = Route.useNavigate();

  if (process.status === "Draft" || process.status === "In review") {
    return null;
  }

  if (
    currentRevision?.status === "Draft" ||
    currentRevision?.status === "Pending review"
  ) {
    return null;
  }

  const title =
    !currentRevision || !currentRevision.revisionNumber
      ? "Revision"
      : currentRevision.revisionNumber
        ? `Revision ${currentRevision.revisionNumber}`
        : currentRevision.status;

  const hasDraftOrPendingRevision = process.revisions.find(
    (revision) =>
      revision.status === "Draft" || revision.status === "Pending review"
  );

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <Button variant="outline" color="gray" size="1">
          <IconVersions />
          {title}
          <IconChevronDown />
        </Button>
      </Popover.Trigger>
      <Popover.Content size="1" minWidth="300px" align="start">
        <Flex direction="column" gap="2">
          <Flex align="center" justify="between">
            <Flex align="center" gap="1" style={{ color: "var(--gray-11)" }}>
              <IconVersions />
              <Heading size="2">Revisions</Heading>
            </Flex>
            {!hasDraftOrPendingRevision && (
              <CreateProcessRevisionButton
                process={process}
                onSuccess={(processRevisionId) => {
                  navigate({
                    search: { revision: processRevisionId },
                  });
                  setOpen(false);
                }}
              />
            )}
          </Flex>
          <FullWidthSeparator />
          <Flex direction="column" gap="2">
            {process.revisions.map((revision) => (
              <Revision
                key={revision.id}
                revision={revision}
                isActive={revision.id === currentRevision?.id}
                onSelect={() => setOpen(false)}
              />
            ))}
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}

function Revision({
  revision,
  isActive,
  onSelect,
}: {
  revision: ProcessRevision;
  isActive: boolean;
  onSelect: () => void;
}) {
  const navigate = Route.useNavigate();

  const title = revision.revisionNumber
    ? `Revision ${revision.revisionNumber}`
    : "Draft Revision";

  let description;
  if (
    revision.status === "Released" &&
    revision.approvedBy &&
    revision.approvedAt
  ) {
    description = (
      <Flex align="center">
        Approved by &nbsp;
        <UserName userId={revision.approvedBy} />
        &nbsp;
        {dayjs(revision.approvedAt).fromNow()}
      </Flex>
    );
  } else {
    description = (
      <Flex align="center">
        Updated by &nbsp;
        <UserName userId={revision.updatedBy} />
        &nbsp;
        {dayjs(revision.updatedAt).fromNow()}
      </Flex>
    );
  }
  return (
    <Button
      type="submit"
      variant="ghost"
      color={isActive ? THEME_COLOR : "gray"}
      data-state={isActive ? "open" : "closed"}
      style={{ justifyContent: "flex-start" }}
      onClick={() => {
        navigate({
          search: { revision: revision.id },
        });
        onSelect();
      }}
    >
      <Flex direction="column" gap="1" align="start">
        <Flex align="center" gap="2">
          <Text
            size="2"
            weight={isActive ? "bold" : "regular"}
            style={{ color: "var(--gray-12)" }}
          >
            {title}
          </Text>
          <DefaultStatusBadge status={revision.status} size="1" />
        </Flex>
        <Text size="1" color="gray">
          {description}
        </Text>
      </Flex>
    </Button>
  );
}

function ProcessControlComponent({
  revision,
  control,
  isComparing,
  compareWithControl,
}: {
  revision: ProcessRevision;
  control: ProcessControl;
  isComparing: boolean;
  compareWithControl?: ProcessControl;
}) {
  const { data: frameworks } = useComplianceFrameworks();

  const isEditable = revision.status === "Draft" && !isComparing;

  return (
    <Box pb="4">
      <Flex align="center" justify="between" minHeight="24px">
        <Heading size="3">
          {isComparing ? (
            <TextDiff
              beforeText={compareWithControl?.title ?? ""}
              afterText={control.title}
            />
          ) : (
            control.title
          )}
        </Heading>
        {isEditable && (
          <Flex gap="2">
            <EditControlAction control={control}>
              <PermissionedButton
                size="1"
                variant="soft"
                permission="write.processes"
              >
                <IconPencil />
                Edit
              </PermissionedButton>
            </EditControlAction>
            <RemoveControlAction
              controlRevisionId={control.controlRevisionId}
            />
          </Flex>
        )}
      </Flex>
      <Box maxWidth="45rem">
        {isComparing ? (
          <ControlLanguageDiff
            beforeValue={compareWithControl?.language ?? ""}
            afterValue={control.language}
          />
        ) : (
          <ReactMarkdown>{control.language}</ReactMarkdown>
        )}
      </Box>
      <Flex direction="column" gap="2">
        <Flex align="center" gap="2">
          <Text size="2" color="gray">
            Mapped requirements:
          </Text>
        </Flex>
        <Flex gap="2" align="center">
          {control.mappings
            .filter((mapping) =>
              frameworks?.some((f) => f.id === mapping.frameworkId)
            )
            .map((mapping) => (
              <ControlMapping
                key={mapping.frameworkId + mapping.clause}
                control={control}
                mapping={mapping}
              />
            ))}
          {!isComparing && (
            <AddComplianceMappingAction control={control}>
              <PermissionedButton
                iconButton
                size="1"
                variant="ghost"
                color="gray"
                permission="write.processes"
              >
                <IconPlus />
              </PermissionedButton>
            </AddComplianceMappingAction>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

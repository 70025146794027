import { THEME_COLOR } from "@/theme";
import { Badge, BadgeProps } from "@radix-ui/themes";

export interface DefaultStatusBadgeProps extends BadgeProps {
  status: string;
}

/**
 * Generic badge that maps common status names to colors. Should eventually
 * be superseded by custom statuses for most use cases, but useful for built-in
 * concepts like documents and processes.
 */
export const DefaultStatusBadge: React.FC<DefaultStatusBadgeProps> = ({
  status,
  color,
  ...props
}) => {
  if (!color) {
    if (status === "Approved" || status === "Released" || status === "Active") {
      color = "green";
    } else if (status === "Pending review" || status === "In review" || status === "In progress") {
      color = THEME_COLOR;
    } else {
      color = "gray";
    }
  }

  return (
    <Badge
      color={(color as any) ?? THEME_COLOR}
      size="2"
      radius="large"
      {...props}
    >
      {status}
    </Badge>
  );
};

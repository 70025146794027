import Form from "@/components/forms";
import { ExecutableAction } from "@/hooks/useActionTypes";
import { useExecuteAction } from "@/state/mutations/actions/executeAction";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useUser } from "@/state/queries/user";
import {
  ActionFormField,
  ActionFormFieldTypeData,
  BooleanFormField,
  DateFormField,
  FileFormField,
  LinkFormField,
  NumberFormField,
  TextFormField,
  TimestampFormField,
  UserIdFormField,
  ValuesFormField,
} from "@/types/actionFormField";
import { ActionOperation } from "@/types/actions";
import { RecordFieldType } from "@/types/recordFields";
import { RecordFieldValues } from "@/types/records";
import { RecordType } from "@/types/recordTypes";
import {
  getActionDefaultValues,
  postProcessActionValues,
} from "@/utils/actions";
import { Dialog, ScrollArea, Select } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useEffect, useState } from "react";
import { z } from "zod";
import MeridianDialog from "../dialogs";
import { FormFieldBaseProps, GenericFormApi } from "../forms/types";
import { ConfirmCancelDialog } from "./ConfirmCancelDialog";
import { FileDropzone } from "./FileDropzone";

export const ActionDialog: React.FC<{
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: ExecutableAction;
  recordType: RecordType;
  selectedRecord: RecordFieldValues | undefined;
  onActionExecuted: (
    action: ExecutableAction,
    newRecordValues?: RecordFieldValues
  ) => void;
}> = ({
  action,
  recordType,
  selectedRecord,
  isOpen,
  setIsOpen,
  onActionExecuted,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const { data: currentUser } = useUser();

  const {
    mutate: executeAction,
    error: executeActionError,
    isPending,
    reset: resetMutation,
  } = useExecuteAction();

  const form = useForm<any, any>({
    defaultValues: getActionDefaultValues(
      action,
      recordType,
      currentUser?.id ?? "",
      selectedRecord
    ),
    validatorAdapter: zodValidator(),
    // TODO Generate validators from action fields
    validators: {
      onChange: z.object({}),
    },
    onSubmit: ({ value }) => {
      executeAction(
        {
          action,
          recordType,
          recordId: selectedRecord?.id,
          fieldValues: postProcessActionValues(action, recordType, value),
        },
        {
          onSuccess: (data) => {
            onActionExecuted(action, data);
            setIsOpen(false);
          },
        }
      );
    },
  });

  // Reset the form and mutation when the dialog is opened or closed
  useEffect(() => {
    form.reset();
    resetMutation();
  }, [form, resetMutation, isOpen]);

  const isDirty = form.useStore((state) => state.isDirty);
  const handleClose = () => {
    if (isDirty) {
      setIsClosing(true);
    } else {
      setIsOpen(false);
    }
  };

  let formFields = action.form.fields.map((field) => (
    <ActionFormFieldComponent
      key={field.id}
      form={form}
      recordTypeId={action.recordTypeId}
      field={field}
    />
  ));
  const hasFormFields = formFields.length > 0;

  const isDeleteAction = ActionOperation.isDelete(action.operation);

  let description = action.description;
  if (!description) {
    if (isDeleteAction) {
      description =
        "Are you sure you want to delete this record? Data associated with this record will be removed from the system.";
    } else if (!hasFormFields) {
      description = "Please confirm you want to execute this action.";
    }
  }

  const handleConfirmCancel = () => {
    setIsClosing(false);
    setIsOpen(false);
  };

  return (
    <>
      <ConfirmCancelDialog
        isOpen={isClosing}
        onOpenChange={setIsClosing}
        onConfirm={handleConfirmCancel}
      />

      <Dialog.Root open={isOpen} onOpenChange={handleClose}>
        <MeridianDialog.Content maxWidth={hasFormFields ? undefined : "450px"}>
          <MeridianDialog.Header
            title={action.name}
            description={description}
            hideSeparator={!hasFormFields}
          />
          <MeridianDialog.Body>
            <Form.Root reactForm={form}>
              {hasFormFields && (
                <ScrollArea
                  type="auto"
                  scrollbars="vertical"
                  size="2"
                  style={{
                    maxHeight: 400,
                    paddingRight: "var(--space-2)",
                  }}
                >
                  <Form.Fields pl="1" pr="4">
                    {formFields}
                  </Form.Fields>
                </ScrollArea>
              )}
              <Form.Footer
                form={form}
                error={executeActionError}
                isPending={isPending}
                buttonText={isDeleteAction ? "Delete" : "Submit"}
                errorPrefix="Failed to execute action: "
                submitButtonProps={{
                  color: isDeleteAction ? "red" : undefined,
                }}
                hideSeparator={!hasFormFields}
              />
            </Form.Root>
          </MeridianDialog.Body>
        </MeridianDialog.Content>
      </Dialog.Root>
    </>
  );
};

const ActionFormFieldComponent: React.FC<{
  form: GenericFormApi<any>;
  recordTypeId: string;
  field: ActionFormField;
}> = ({ form, recordTypeId, field }) => {
  const { data: recordType } = useRecordTypeById(recordTypeId);
  const recordTypeField = recordType?.fields.find(
    (f) => f.id === field.recordFieldId
  );

  // TODO: Figure out what to do for fields that have been removed from the record type
  if (!recordTypeField) {
    return null;
  }

  const reactFormField = { form, name: field.id };
  const fieldProps: FormFieldBaseProps = {
    label: field.name,
    required: field.required,
    helperText: recordTypeField.description,
  };

  return ActionFormFieldTypeData.visit(field.type, {
    number: (value: NumberFormField) => {
      return (
        <Form.TextField
          reactFormField={reactFormField}
          fieldProps={fieldProps}
          type="number"
          placeholder="123"
        />
      );
    },
    boolean: (value: BooleanFormField) => {
      return (
        <Form.Switch reactFormField={reactFormField} fieldProps={fieldProps} />
      );
    },
    text: (value: TextFormField) => {
      const props = {
        reactFormField,
        fieldProps,
        rows: value.multiline ? 4 : undefined,
        placeholder: "Enter value",
      };
      return value.multiline ? (
        <Form.TextArea {...props} />
      ) : (
        <Form.TextField {...props} />
      );
    },
    date: (value: DateFormField) => {
      return (
        <Form.TextField
          reactFormField={reactFormField}
          fieldProps={fieldProps}
          type="date"
        />
      );
    },
    timestamp: (value: TimestampFormField) => {
      return (
        <Form.TextField
          reactFormField={reactFormField}
          fieldProps={fieldProps}
          type="datetime-local"
        />
      );
    },
    values: (value: ValuesFormField) => {
      let options: string[] = [];
      let allowMultiple;
      if (recordTypeField && RecordFieldType.isValues(recordTypeField.type)) {
        options = recordTypeField.type.values.options.map((o) => o.value);
        allowMultiple = recordTypeField.type.values.allowMultiple;
      }
      if (allowMultiple) {
        return (
          <Form.MultiSelect
            reactFormField={reactFormField}
            fieldProps={fieldProps}
            options={options.map((option) => ({
              label: option,
              value: option,
              data: option,
            }))}
          />
        );
      }

      return (
        <Form.Select reactFormField={reactFormField} fieldProps={fieldProps}>
          <Select.Content>
            {options.map((option) => (
              <Select.Item key={option} value={option}>
                {option}
              </Select.Item>
            ))}
          </Select.Content>
        </Form.Select>
      );
    },
    file: (value: FileFormField) => {
      if (!recordTypeField || !RecordFieldType.isFile(recordTypeField.type)) {
        return null;
      }

      const allowMultiple = recordTypeField.type.file.allowMultiple;
      return (
        <FileDropzone
          multiple={allowMultiple}
          reactFormField={reactFormField}
          fieldProps={fieldProps}
        />
      );
    },
    link: (value: LinkFormField) => {
      // TODO: Figure out how to handle this
      if (!recordTypeField || !RecordFieldType.isLink(recordTypeField.type)) {
        return null;
      }

      const allowMultiple = recordTypeField.type.link.allowMultiple;
      if (allowMultiple) {
        return (
          <Form.RecordMultiSelect
            reactFormField={reactFormField}
            fieldProps={fieldProps}
            recordTypeId={recordTypeField.type.link.linkedRecordTypeId}
          />
        );
      }

      return (
        <Form.RecordSelect
          reactFormField={reactFormField}
          fieldProps={fieldProps}
          recordTypeId={recordTypeField.type.link.linkedRecordTypeId}
        />
      );
    },
    userId: (value: UserIdFormField) => {
      // TODO: Figure out how to handle this
      if (!recordTypeField || !RecordFieldType.isUserId(recordTypeField.type)) {
        return null;
      }

      const allowMultiple = recordTypeField.type.userId.allowMultiple;
      if (allowMultiple) {
        return (
          <Form.UserMultiSelect
            reactFormField={reactFormField}
            fieldProps={fieldProps}
          />
        );
      }

      return (
        <Form.UserSelect
          reactFormField={reactFormField}
          fieldProps={fieldProps}
        />
      );
    },
  });
};

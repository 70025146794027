import Form from "@/components/forms";
import { useEditControl } from "@/state/mutations/processes/editControl";
import { useComplianceFrameworks } from "@/state/queries/complianceFrameworks";
import { ProcessControl } from "@/state/queries/processes";
import { Dialog, Flex } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { PropsWithChildren, useState } from "react";
import { ControlMapping } from "../controls/ControlMapping";
import MeridianDialog from "../dialogs";
import {
  FormFieldLabel,
  FormFieldWrapper,
  FormHelperText,
} from "../forms/FormField";

interface EditControlActionProps {
  control: ProcessControl;
}

interface EditControlFormInputs {
  title: string;
  language: string;
}

export const EditControlAction: React.FC<
  PropsWithChildren<EditControlActionProps>
> = ({ control, children }) => {
  const [open, setOpen] = useState(false);
  const { mutate: editControl, error, reset, isPending } = useEditControl();
  const { data: frameworks } = useComplianceFrameworks();

  const form = useForm({
    defaultValues: {
      title: control.title,
      language: control.language,
    },
    onSubmit: ({ value }) => {
      editControl(
        {
          controlRevisionId: control.controlRevisionId,
          title: value.title,
          language: value.language,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    reset();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <MeridianDialog.Content>
        <MeridianDialog.Header title="Edit control" />
        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Form.Fields>
              <Form.TextField<EditControlFormInputs>
                reactFormField={{ form, name: "title" }}
                fieldProps={{
                  label: "Title",
                  required: true,
                }}
              />

              <Form.TextArea<EditControlFormInputs>
                reactFormField={{ form, name: "language" }}
                fieldProps={{
                  label: "Language",
                  required: true,
                }}
                rows={12}
              />

              <form.Subscribe
                selector={(state) => [state.values.language]}
                children={([language]) => (
                  <FormFieldWrapper>
                    <FormFieldLabel name="audit" label="AI Audit" />
                    <FormHelperText>
                      Meridian audits your control language to ensure it is
                      compliant with mapped requirements.
                    </FormHelperText>
                    <Flex gap="2">
                      {control.mappings
                        .filter((mapping) =>
                          frameworks?.some((f) => f.id === mapping.frameworkId)
                        )
                        .map((mapping) => (
                          <ControlMapping
                            key={mapping.frameworkId + mapping.clause}
                            control={control}
                            mapping={mapping}
                          />
                        ))}
                    </Flex>
                  </FormFieldWrapper>
                )}
              />
            </Form.Fields>

            <Form.Footer
              form={form}
              error={error}
              isPending={isPending}
              buttonText="Save changes"
              errorPrefix="Failed to edit control: "
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

import { DefaultStatusBadge } from "@/components/DefaultStatusBadge";
import MeridianTabNav from "@/components/MeridianTabNav";
import PageTitle, { PageTitleTag } from "@/components/PageTitle";
import { PermissionedButton } from "@/components/PermissionedButton";
import { DeleteProcessAction } from "@/components/processes/DeleteProcessAction";
import { EditProcessAction } from "@/components/processes/EditProcessAction";
import { useDocumentsByProcess } from "@/state/queries/documents";
import {
  Process,
  processesQueryOptions,
  useProcess,
} from "@/state/queries/processes";
import { useRecordTypesByProcess } from "@/state/queries/recordLinkTypes";
import { useWorkflowByProcess } from "@/state/queries/workflows";
import { Box, Flex } from "@radix-ui/themes";
import {
  IconArrowRightRhombus,
  IconChecklist,
  IconFileText,
  IconListDetails,
  IconPencil,
} from "@tabler/icons-react";
import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/processes/$processId")({
  beforeLoad: async ({ context, params }) => {
    if (!context.tenant) {
      return { getTitle: () => "Process Details" };
    }
    const processes = await context.queryClient.ensureQueryData(
      processesQueryOptions(context.tenant.tenantId)
    );
    const process = processes.find((p) => p.id === params.processId);
    return {
      getTitle: () => process?.name ?? "Process Details",
    };
  },
  component: ProcessPage,
});

function ProcessPage() {
  const { tenantSlug, processId } = Route.useParams();
  const { data: process, isLoading } = useProcess(processId);
  const { data: documents } = useDocumentsByProcess(processId);
  const { data: workflows } = useWorkflowByProcess(processId);
  const { data: recordTypes } = useRecordTypesByProcess(processId);

  const title = process ? (
    <Flex align="center" gap="4">
      {process.name}
      <EditProcessAction key="edit" process={process}>
        <PermissionedButton
          permission="write.processes"
          variant="ghost"
          color="gray"
          size="3"
          iconButton
        >
          <IconPencil />
        </PermissionedButton>
      </EditProcessAction>
    </Flex>
  ) : (
    "Process"
  );

  const tag = (
    <Flex align="center" gap="2">
      <PageTitleTag label={process?.id ?? "Process"} />
      <DefaultStatusBadge status={process?.status ?? ""} />
    </Flex>
  );

  return (
    <Flex direction="column" gap="5" maxWidth="var(--container-3)">
      <PageTitle
        title={title}
        description={process?.description}
        tag={tag}
        isLoading={isLoading}
      >
        {process && <ProcessActions process={process} />}
      </PageTitle>
      <Box>
        <MeridianTabNav<"/$tenantSlug/processes/$processId">
          childRouteParams={{ tenantSlug, processId }}
          links={[
            {
              to: "/$tenantSlug/processes/$processId/controls",
              label: "Controls",
              icon: <IconChecklist />,
            },
            {
              to: "/$tenantSlug/processes/$processId/workflows",
              label: "Workflows",
              icon: <IconArrowRightRhombus />,
              badge: workflows?.length.toString() ?? undefined,
            },
            {
              to: "/$tenantSlug/processes/$processId/record-types",
              label: "Record Types",
              icon: <IconListDetails />,
              badge: recordTypes?.length.toString() ?? undefined,
            },
            {
              to: "/$tenantSlug/processes/$processId/documents",
              label: "Documents",
              icon: <IconFileText />,
              badge: documents?.length.toString() ?? undefined,
            },
          ]}
        />
        <Outlet />
      </Box>
    </Flex>
  );
}

function ProcessActions({ process }: { process: Process }) {
  const { tenantSlug } = Route.useParams();
  const navigate = useNavigate();

  return (
    <Flex align="center" gap="2">
      {process.status === "Draft" && (
        <DeleteProcessAction
          process={process}
          onSuccess={() => {
            navigate({
              to: "/$tenantSlug/processes",
              params: { tenantSlug },
            });
          }}
        />
      )}
    </Flex>
  );
}

import { Flex } from "@radix-ui/themes";
import React from "react";

interface MeridianDialogBodyProps {
  children: React.ReactNode;
}

export const MeridianDialogBody: React.FC<MeridianDialogBodyProps> = ({
  children,
}) => {
  return (
    <Flex direction="column" pt="3">
      {children}
    </Flex>
  );
};

import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Process } from "../../queries/processes";

// TODO: This should be transactional. Currently deletes Draft revisions and then the process.
// If the process deletion fails, the revisions will still be deleted.
const deleteProcess = async (process: Process) => {
  const { error: revisionsError } = await supabase
    .from("process_revisions")
    .delete()
    .eq("process_id", process.id)
    .eq("tenant_id", process.tenantId)
    .eq("status", "Draft");

  if (revisionsError) {
    throw revisionsError;
  }

  const { error } = await supabase
    .from("processes")
    .delete()
    .eq("id", process.id)
    .eq("tenant_id", process.tenantId);

  if (error) {
    throw error;
  }
};

export const useDeleteProcess = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: deleteProcess,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

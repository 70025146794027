import { MeridianDialogBody } from "./MeridianDialogBody";
import { MeridianDialogContent } from "./MeridianDialogContent";
import { MeridianDialogFooter } from "./MeridianDialogFooter";
import { MeridianDialogHeader } from "./MeridianDialogHeader";

const MeridianDialog = {
  Content: MeridianDialogContent,
  Header: MeridianDialogHeader,
  Body: MeridianDialogBody,
  Footer: MeridianDialogFooter,
};

export default MeridianDialog;

import { ComplianceRequirement } from "@/state/queries/complianceFrameworks";
import { Box, Dialog, Flex, ScrollArea, Tabs } from "@radix-ui/themes";
import { IconFile } from "@tabler/icons-react";
import ReactMarkdown from "react-markdown";
import MeridianDialog from "../dialogs";
import NonIdealState from "../NonIdealState";

interface RequirementDialogProps {
  requirement?: ComplianceRequirement;
  onClose: () => void;
}

export const RequirementDialog = ({
  requirement,
  onClose,
}: RequirementDialogProps) => {
  if (!requirement) {
    return null;
  }
  return (
    <Dialog.Root
      open={!!requirement}
      onOpenChange={(open) => !open && onClose()}
    >
      <MeridianDialog.Content size="3" maxWidth="700px">
        <MeridianDialog.Header
          title={`${requirement?.clause}: ${requirement?.title}`}
        />
        <Tabs.Root defaultValue="requirement" mt="3">
          <Tabs.List>
            <Tabs.Trigger value="requirement">Requirement</Tabs.Trigger>
            <Tabs.Trigger value="control">
              <Flex gap="2">Control Language</Flex>
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="requirement">
            <RequirementTab requirement={requirement} />
          </Tabs.Content>
          <Tabs.Content value="control">
            <MappedResourcesTab requirement={requirement} />
          </Tabs.Content>
        </Tabs.Root>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

const TAB_HEIGHT = "450px";

function RequirementTab({
  requirement,
}: {
  requirement: ComplianceRequirement;
}) {
  const formattedValue = requirement?.language?.replace(/\n/g, "  \n") || "";

  return (
    <ScrollArea type="auto" style={{ height: TAB_HEIGHT }}>
      <Box pr="4">
        <ReactMarkdown
          // Add lower-alpha list style to match AS9100 standard
          components={{
            ol: ({ children }) => {
              return <ol style={{ listStyle: "lower-alpha" }}>{children}</ol>;
            },
          }}
        >
          {formattedValue}
        </ReactMarkdown>
      </Box>
    </ScrollArea>
  );
}

// TODO: Should indicate when the control language is in a draft or in-review state
function MappedResourcesTab({
  requirement,
}: {
  requirement: ComplianceRequirement;
}) {
  const process = requirement.processes[0];
  const content =
    process && process.controlLanguage ? (
      <ReactMarkdown
        components={{
          ol: ({ children }) => {
            return <ol style={{ listStyle: "lower-alpha" }}>{children}</ol>;
          },
        }}
      >
        {requirement.processes[0].controlLanguage}
      </ReactMarkdown>
    ) : (
      <Flex justify="center" align="center" style={{ height: TAB_HEIGHT }}>
        <NonIdealState
          icon={<IconFile />}
          title="No control language added yet"
        />
      </Flex>
    );
  return (
    <ScrollArea type="auto" style={{ height: TAB_HEIGHT }}>
      <Box pr="4">{content}</Box>
    </ScrollArea>
  );
}

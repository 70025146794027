import Form from "@/components/forms";
import { useEditProcess } from "@/state/mutations/processes/editProcess";
import { Process } from "@/state/queries/processes";
import { Button, Dialog, Flex } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { useState } from "react";
import MeridianDialog from "../dialogs";

interface EditProcessFormInputs {
  name: string;
  description: string;
  owner: string;
}

export const EditProcessAction: React.FC<{
  process: Process;
  children: React.ReactNode;
  onSuccess?: () => void;
}> = ({ process, children, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const { mutate: editProcess } = useEditProcess();

  const form = useForm({
    defaultValues: {
      name: process.name,
      description: process.description ?? "",
      owner: process.ownedBy,
    },
    onSubmit: ({ value }) => {
      editProcess(
        {
          id: process.id,
          name: value.name,
          description: value.description,
          owner: value.owner,
        },
        {
          onSuccess: () => {
            setOpen(false);
            if (onSuccess) {
              onSuccess();
            }
          },
        }
      );
    },
  });

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <MeridianDialog.Content>
        <MeridianDialog.Header title="Edit process" />
        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Flex direction="column" gap="4">
              <Form.Fields>
                <Form.TextField<EditProcessFormInputs>
                  reactFormField={{ form, name: "name" }}
                  fieldProps={{ label: "Process name", required: true }}
                  placeholder="Enter process name"
                />
                <Form.TextArea<EditProcessFormInputs>
                  reactFormField={{ form, name: "description" }}
                  fieldProps={{ label: "Description" }}
                />
                <Form.UserSelect<EditProcessFormInputs>
                  reactFormField={{ form, name: "owner" }}
                  fieldProps={{ label: "Owner" }}
                />
              </Form.Fields>
              <Flex gap="3" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                <Button type="submit">Save changes</Button>
              </Flex>
            </Flex>
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

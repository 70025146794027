import NonIdealState from "@/components/NonIdealState";
import { CreateViewDialogContent } from "@/components/records/CreateViewDialogContent";
import { DeleteViewDialog } from "@/components/records/DeleteViewDialog";
import { RecordActions } from "@/components/records/RecordActions";
import { RecordsTable } from "@/components/records/RecordsTable";
import { UpdateViewTitleDialog } from "@/components/records/UpdateViewTitleDialog";
import {
  useViewContext,
  ViewContextProvider,
} from "@/components/records/ViewContext";
import { ExecutableAction } from "@/hooks/useActionTypes";
import { useProcessCellForExport } from "@/hooks/useProcessCellForExport";
import { useStatuses } from "@/hooks/useStatuses";
import { useUpdateView } from "@/state/mutations/views/updateView";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useRecords } from "@/state/queries/records";
import {
  recordViewsQueryOptions,
  useRecordViewById,
  useRecordViewsByRecordType,
} from "@/state/queries/recordViews";
import { RecordFieldValues } from "@/types/records";
import { RecordType } from "@/types/recordTypes";
import { RecordViewConfig } from "@/types/recordViews";
import {
  Button,
  DropdownMenu,
  Flex,
  Popover,
  Select,
  Spinner,
  Text,
} from "@radix-ui/themes";
import {
  IconChevronDown,
  IconCirclePlus,
  IconDownload,
  IconEye,
  IconFile,
  IconFilter,
  IconPencil,
  IconTable,
  IconTrash,
} from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/records/$recordTypeId/views/$viewId"
)({
  beforeLoad: async ({ context, params }) => {
    if (!context.tenant) {
      return { getTitle: () => "View" };
    }
    const recordViews = await context.queryClient.ensureQueryData(
      recordViewsQueryOptions(context.tenant.tenantId)
    );
    const recordView = recordViews.find((view) => view.id === params.viewId);
    return {
      getTitle: () => recordView?.name ?? "View",
    };
  },
  component: RecordView,
});

function RecordView() {
  const navigate = Route.useNavigate();
  const { tenantSlug, recordTypeId, viewId } = Route.useParams();
  const [selectedRecordIds, setSelectedRecordIds] = useState<string[]>([]);
  const gridRef = useRef<AgGridReact<RecordFieldValues>>(null);

  const { data: recordType, isLoading: recordTypeIsLoading } =
    useRecordTypeById(recordTypeId);
  const { data: recordView, isLoading: recordViewIsLoading } =
    useRecordViewById(viewId);
  const { data: records, isLoading } = useRecords(recordTypeId);

  const processCellCallback = useProcessCellForExport(recordType);

  const onActionExecuted = (action: ExecutableAction) => {
    if (action.operation.type === "delete") {
      // Clear selection on delete
      setSelectedRecordIds([]);
    }
  };

  const exportRecords = () => {
    gridRef.current?.api.exportDataAsCsv({
      exportedRows: "all",
      fileName: recordView?.name ?? "records.csv",
      processCellCallback,
    });
  };

  const navigateToRecord = (recordId: string) => {
    navigate({
      to: "/$tenantSlug/records/$recordTypeId/$recordId",
      params: {
        tenantSlug,
        recordTypeId,
        recordId,
      },
    });
  };

  if (recordTypeIsLoading || recordViewIsLoading) {
    return <NonIdealState icon={<Spinner />} title="Loading" />;
  }

  if (!recordType || !recordView) {
    return (
      <NonIdealState
        icon={<IconFile />}
        title="Record type or view not found"
      />
    );
  }

  return (
    <ViewContextProvider recordType={recordType} initialViewConfig={recordView}>
      <Flex direction="column" overflowY="hidden">
        <RecordsToolbar
          recordType={recordType}
          currentRecordView={recordView}
          selectedRecordIds={selectedRecordIds}
          onActionExecuted={onActionExecuted}
        />
        <ViewsToolbar exportRecords={exportRecords} />
        <RecordsTable
          gridRef={gridRef}
          loading={isLoading}
          records={records ?? []}
          navigateToRecord={navigateToRecord}
          onSelectionChange={setSelectedRecordIds}
        />
      </Flex>
    </ViewContextProvider>
  );
}

const Toolbar: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Flex
      justify="between"
      align="center"
      py="2"
      px="4"
      // Prevents jitter as buttons are shown and hidden
      minHeight="48px"
      style={{ borderBottom: "var(--line-border)" }}
    >
      {children}
    </Flex>
  );
};

const RecordsToolbar: React.FC<{
  recordType: RecordType;
  currentRecordView: RecordViewConfig;
  selectedRecordIds: string[];
  onActionExecuted: (
    action: ExecutableAction,
    newRecordValues?: RecordFieldValues
  ) => void;
}> = ({
  recordType,
  currentRecordView,
  selectedRecordIds,
  onActionExecuted,
}) => {
  const navigate = Route.useNavigate();
  const { tenantSlug, recordTypeId } = Route.useParams();
  const { data: recordTypeViews } = useRecordViewsByRecordType(recordTypeId);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  return (
    <Toolbar>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button variant="ghost" color="gray">
            <IconTable />
            {currentRecordView.name}
            <IconChevronDown />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {recordTypeViews?.map((view) => (
            <DropdownMenu.Item
              key={view.id}
              onClick={() =>
                navigate({
                  to: "/$tenantSlug/records/$recordTypeId/views/$viewId",
                  params: {
                    tenantSlug,
                    recordTypeId,
                    viewId: view.id,
                  },
                })
              }
            >
              <IconTable />
              {view.name}
            </DropdownMenu.Item>
          ))}
          <DropdownMenu.Separator />
          <DropdownMenu.Item
            key="create"
            onClick={() => setCreateDialogOpen(true)}
          >
            <IconCirclePlus />
            Create view
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      <CreateViewDialogContent
        open={createDialogOpen}
        onOpenChange={setCreateDialogOpen}
        recordType={recordType}
        onSuccess={(viewId) => {
          navigate({
            to: "/$tenantSlug/records/$recordTypeId/views/$viewId",
            params: {
              tenantSlug,
              recordTypeId,
              viewId,
            },
          });
        }}
      />
      <RecordActions
        recordType={recordType}
        selectedRecordIds={selectedRecordIds}
        onActionExecuted={onActionExecuted}
        actionCountThreshold={5}
        nameLengthThreshold={100}
      />
    </Toolbar>
  );
};

const ViewsToolbar: React.FC<{ exportRecords: () => void }> = ({
  exportRecords,
}) => {
  const { viewConfigHasChanged } = useViewContext();

  return (
    <Toolbar>
      <Flex gap="4">
        <VisibleFieldsMenu />
        <RecordFilterMenu />
      </Flex>
      {!viewConfigHasChanged && (
        <DefaultViewConfigActions exportRecords={exportRecords} />
      )}
      {viewConfigHasChanged && <ChangedViewConfigActions />}
    </Toolbar>
  );
};

const DefaultViewConfigActions: React.FC<{ exportRecords: () => void }> = ({
  exportRecords,
}) => {
  const { tenantSlug, recordTypeId } = Route.useParams();
  const navigate = Route.useNavigate();

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <Flex align="center" gap="4">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button variant="ghost" color="gray">
            View actions
            <IconChevronDown />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Item onClick={() => setUpdateDialogOpen(true)}>
            <IconPencil />
            Update title
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => setDeleteDialogOpen(true)}>
            <IconTrash />
            Delete view
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      <UpdateViewTitleDialog
        isOpen={updateDialogOpen}
        onOpenChange={setUpdateDialogOpen}
        onSuccess={() => {}}
      />
      <DeleteViewDialog
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        onSuccess={() => {
          navigate({
            to: "/$tenantSlug/records/$recordTypeId",
            params: {
              tenantSlug,
              recordTypeId,
            },
          });
        }}
      />
      <Button variant="soft" onClick={exportRecords}>
        <IconDownload />
        Export
      </Button>
    </Flex>
  );
};

const ChangedViewConfigActions: React.FC = () => {
  const { currentViewConfig, resetChanges } = useViewContext();
  const { mutate: updateView, isPending } = useUpdateView();

  return (
    <Flex align="center" gap="4">
      <Button variant="ghost" color="gray" onClick={resetChanges}>
        Discard changes
      </Button>
      <Button loading={isPending} onClick={() => updateView(currentViewConfig)}>
        Save view
      </Button>
    </Flex>
  );
};

const VisibleFieldsMenu: React.FC = () => {
  const {
    recordType,
    tableViewConfig: { columns },
    setTableViewConfig,
  } = useViewContext();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button variant="ghost" color="gray">
          <IconEye />
          Visible fields
          <IconChevronDown />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {recordType.fields
          .filter((field) => field.id !== "id")
          .map((field) => {
            const checked = columns.some(
              (config) => config.fieldId === field.id
            );
            return (
              <DropdownMenu.CheckboxItem
                key={field.id}
                checked={checked}
                onCheckedChange={(checked) => {
                  setTableViewConfig({
                    columns: checked
                      ? [...columns, { fieldId: field.id }]
                      : columns.filter((config) => config.fieldId !== field.id),
                  });
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (checked) {
                    setTableViewConfig({
                      columns: columns.filter(
                        (config) => config.fieldId !== field.id
                      ),
                    });
                  } else {
                    setTableViewConfig({
                      columns: [...columns, { fieldId: field.id }],
                    });
                  }
                }}
              >
                {field.name}
              </DropdownMenu.CheckboxItem>
            );
          })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const ALL_STATUSES = "all";
const RecordFilterMenu: React.FC = () => {
  const { recordType, currentViewConfig, setDefaultFilter } = useViewContext();
  const statuses = useStatuses(recordType.id);

  const status = currentViewConfig.defaultFilter?.statusFilter?.statuses[0];

  const handleStatusChange = (status: string) => {
    if (status === ALL_STATUSES) {
      setDefaultFilter(undefined);
    } else {
      setDefaultFilter({
        type: "statusFilter",
        statusFilter: {
          statuses: [status],
        },
      });
    }
  };

  const statusSelect = (
    <Select.Root
      value={status ?? ALL_STATUSES}
      onValueChange={handleStatusChange}
    >
      <Select.Trigger />
      <Select.Content>
        <Select.Item key={ALL_STATUSES} value={ALL_STATUSES}>
          All
        </Select.Item>
        {statuses.map((status) => (
          <Select.Item key={status.id} value={status.value}>
            {status.value}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button variant="ghost" color="gray">
          <IconFilter />
          Filter
          <IconChevronDown />
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        <Flex align="center" justify="between" gap="2">
          <Text weight="bold">Status</Text>
          <Text>is</Text>
          {statusSelect}
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
};

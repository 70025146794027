import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { skipToken, useQuery } from "@tanstack/react-query";
import { useUser } from "./user";

export const AUDIT_COMPLIANCE_MAPPING_FLAG = "audit_compliance_mapping";
export type FeatureFlag = typeof AUDIT_COMPLIANCE_MAPPING_FLAG;

const fetchFeatureFlags = async (
  userId: string,
  tenantId: string
): Promise<Set<FeatureFlag>> => {
  const [
    { data: userFlags, error: userFlagsError },
    { data: tenantFlags, error: tenantFlagsError },
  ] = await Promise.all([
    supabase
      .schema("tenants")
      .from("user_feature_flags")
      .select("feature_name")
      .eq("user_id", userId),

    supabase
      .schema("tenants")
      .from("tenant_feature_flags")
      .select("feature_name")
      .eq("tenant_id", tenantId),
  ]);

  if (userFlagsError || tenantFlagsError) {
    throw userFlagsError || tenantFlagsError;
  }

  const userFlagsSet = new Set(userFlags.map((flag) => flag.feature_name));
  const tenantFlagsSet = new Set(tenantFlags.map((flag) => flag.feature_name));

  return userFlagsSet.union(tenantFlagsSet) as Set<FeatureFlag>;
};

export const useFeatureFlags = () => {
  const { data: currentUser } = useUser();
  const tenant = useTenantContext();

  return useQuery({
    queryKey: ["featureFlags", tenant.tenant.tenantId],
    queryFn:
      currentUser?.id && tenant.tenant.tenantId
        ? () => fetchFeatureFlags(currentUser.id, tenant.tenant.tenantId)
        : skipToken,
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  });
};

export const useIsFeatureEnabled = (featureName: FeatureFlag) => {
  const { data: featureFlags } = useFeatureFlags();
  return featureFlags?.has(featureName);
};

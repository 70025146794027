import { Dialog, Flex, Text } from "@radix-ui/themes";
import React from "react";
import { FullWidthSeparator } from "./FullWidthSeparator";

interface MeridianDialogHeaderProps {
  title: string;
  actions?: React.ReactNode;
  description?: string;
  hideSeparator?: boolean;
}

export const MeridianDialogHeader: React.FC<MeridianDialogHeaderProps> = ({
  title,
  actions,
  description,
  hideSeparator,
}) => {
  return (
    <>
      <Dialog.Title>
        <Flex align="center" justify="between">
          {title}
          {actions}
        </Flex>
      </Dialog.Title>
      {description && (
        <Dialog.Description mb="2">
          <Text color="gray" size="2">
            {description}
          </Text>
        </Dialog.Description>
      )}
      {!hideSeparator && <FullWidthSeparator />}
    </>
  );
};

import PageTitle from "@/components/PageTitle";
import { useRecordAndLinkTypes } from "@/state/queries/recordLinkTypes";
import { RecordType } from "@/types/recordTypes";
import { Button, Card, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { IconSettings } from "@tabler/icons-react";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/records/")({
  beforeLoad: () => {
    return {
      getTitle: () => "",
    };
  },
  component: RecordsIndex,
});

function RecordsIndex() {
  const { tenantSlug } = Route.useParams();
  const navigate = useNavigate();
  const { data: recordTypes } = useRecordAndLinkTypes();

  return (
    <Flex direction="column" gap="4">
      <PageTitle
        title="Records"
        description="Explore all the types of data tracked in Meridian."
      >
        <Button
          variant="outline"
          color="gray"
          onClick={() =>
            navigate({
              to: "/$tenantSlug/settings/organization/record-types",
              params: { tenantSlug },
            })
          }
        >
          <IconSettings />
          Manage record types
        </Button>
      </PageTitle>
      <Grid columns="3" gap="4">
        {recordTypes?.recordTypes.map((recordType) => (
          <RecordTypeCard key={recordType.id} recordType={recordType} />
        ))}
      </Grid>
    </Flex>
  );
}

function RecordTypeCard({ recordType }: { recordType: RecordType }) {
  const { tenantSlug } = Route.useParams();
  return (
    <Card asChild>
      <Link to={`/${tenantSlug}/records/${recordType.id}`}>
        <Heading size="3">{recordType.pluralName}</Heading>
        <Text color="gray">{recordType.description}</Text>
      </Link>
    </Card>
  );
}

import { Button, Flex, Text } from "@radix-ui/themes";
import { IconChevronRight } from "@tabler/icons-react";
import { isMatch, Link, useLocation, useMatches } from "@tanstack/react-router";
import React, { Fragment } from "react";

const Breadcrumb: React.FC<{ title: string; path: string }> = ({
  title,
  path,
}) => {
  const location = useLocation();
  const isActive = location.pathname === path;
  return (
    <Button variant="ghost" color={isActive ? "iris" : "gray"} asChild>
      <Link to={path}>
        <Text style={{ color: isActive ? "var(--gray-12)" : undefined }}>
          {title}
        </Text>
      </Link>
    </Button>
  );
};

const useBreadcrumbs = () => {
  const matches = useMatches();
  return matches
    .filter((match) => isMatch(match, "context.getTitle"))
    .filter((match) => !!match.context.getTitle())
    .map(({ pathname, context }) => {
      return {
        title: context.getTitle(),
        path: pathname,
      };
    });
};

const AppToolbar: React.FC = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <Flex
      px="4"
      py="2"
      align="center"
      justify="between"
      position="sticky"
      top="0"
      style={{
        backgroundColor: "var(--color-background)",
        zIndex: 1000,
        borderBottom: "var(--line-border)",
      }}
    >
      <Text size="3" weight="bold">
        <Flex align="center" gap="1">
          {breadcrumbs.map((crumb, idx) => {
            if (idx === breadcrumbs.length - 1) {
              return (
                <Breadcrumb
                  key={crumb.path}
                  title={crumb.title}
                  path={crumb.path}
                />
              );
            } else {
              return (
                <Fragment key={crumb.path}>
                  <Breadcrumb
                    key={crumb.path}
                    title={crumb.title}
                    path={crumb.path}
                  />
                  <IconChevronRight />
                </Fragment>
              );
            }
          })}
        </Flex>
      </Text>
    </Flex>
  );
};

export default AppToolbar;

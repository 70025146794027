import { Flex } from "@radix-ui/themes";

import { Badge, Heading } from "@radix-ui/themes";

import { AuditResult, useAiAuditor } from "@/state/queries/aiAuditor";
import { useFrameworkRequirementLanguage } from "@/state/queries/complianceFrameworks";
import {
  ControlRequirementMapping,
  ProcessControl,
} from "@/state/queries/processes";
import {
  Box,
  Button,
  Popover,
  ScrollArea,
  Spinner,
  Tabs,
  Text,
} from "@radix-ui/themes";
import {
  IconAlertCircle,
  IconAlertCircleFilled,
  IconCheck,
  IconTrash,
} from "@tabler/icons-react";
import { useDebounce } from "@uidotdev/usehooks";
import ReactMarkdown from "react-markdown";
import { FullWidthSeparator } from "../dialogs/FullWidthSeparator";
import { PermissionedButton } from "../PermissionedButton";
import { RemoveComplianceMappingAction } from "../processes/RemoveComplianceMappingAction";

export const ControlMapping = ({
  mapping,
  control,
}: {
  mapping: ControlRequirementMapping;
  control: ProcessControl;
}) => {
  const debouncedControlLanguage = useDebounce(control.language, 1000);
  const requirementLanguage = useFrameworkRequirementLanguage(
    mapping.frameworkId,
    mapping.clause
  );
  const { data: auditResult, isLoading: isAiAuditRunning } = useAiAuditor(
    mapping.frameworkId,
    mapping.clause,
    debouncedControlLanguage
  );

  const icon = auditResult ? (
    auditResult.score > 95 ? (
      <IconCheck />
    ) : auditResult.score > 60 ? (
      <IconAlertCircle />
    ) : (
      <IconAlertCircleFilled />
    )
  ) : (
    <IconCheck />
  );

  const color = auditResult
    ? auditResult.score > 95
      ? "green"
      : auditResult.score > 60
        ? "orange"
        : "red"
    : "gray";

  return (
    <Popover.Root>
      <Popover.Trigger disabled={isAiAuditRunning}>
        <Button
          key={mapping.frameworkId + mapping.clause}
          type="button"
          size="1"
          variant="soft"
          color={color}
        >
          <Spinner loading={isAiAuditRunning}>{icon}</Spinner>
          {mapping.frameworkId} {mapping.clause}
        </Button>
      </Popover.Trigger>
      <Popover.Content align="start" size="1">
        {auditResult || isAiAuditRunning ? (
          <ContentWithAuditResult
            mapping={mapping}
            control={control}
            auditResult={auditResult}
            requirementLanguage={requirementLanguage}
          />
        ) : (
          <RequirementContent
            mapping={mapping}
            control={control}
            requirementLanguage={requirementLanguage}
          />
        )}
      </Popover.Content>
    </Popover.Root>
  );
};

const ContentWithAuditResult = ({
  mapping,
  control,
  auditResult,
  requirementLanguage,
}: {
  mapping: ControlRequirementMapping;
  control: ProcessControl;
  auditResult: AuditResult | undefined;
  requirementLanguage: string | undefined;
}) => {
  const color = auditResult
    ? auditResult.score > 95
      ? "green"
      : auditResult.score > 60
        ? "orange"
        : "red"
    : "gray";

  return (
    <Tabs.Root defaultValue="audit">
      <Tabs.List
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Flex>
          <Tabs.Trigger value="audit">
            <Text>Audit Result&nbsp;&nbsp;</Text>
            <Badge size="1" color={color}>
              {auditResult?.score}%
            </Badge>
          </Tabs.Trigger>
          <Tabs.Trigger value="requirement">Requirements</Tabs.Trigger>
        </Flex>
        <RemoveControlMappingButton control={control} mapping={mapping} />
      </Tabs.List>
      <Box pt="3">
        <Tabs.Content value="audit">
          <ScrollArea type="auto" style={{ height: "300px" }}>
            <ReactMarkdown
              components={{
                p: ({ children }) => <Text>{children}</Text>,
              }}
            >
              {auditResult?.summary}
            </ReactMarkdown>
          </ScrollArea>
        </Tabs.Content>
        <Tabs.Content value="requirement">
          <ScrollArea type="auto" style={{ height: "300px" }}>
            <ReactMarkdown
              components={{
                p: ({ children }) => {
                  return <Text>{children}</Text>;
                },
                ol: ({ children }) => {
                  return (
                    <ol style={{ listStyle: "lower-alpha" }}>{children}</ol>
                  );
                },
              }}
            >
              {requirementLanguage}
            </ReactMarkdown>
          </ScrollArea>
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
};

const RequirementContent = ({
  mapping,
  control,
  requirementLanguage,
  showHeader = true,
}: {
  mapping: ControlRequirementMapping;
  control: ProcessControl;
  requirementLanguage: string | undefined;
  showHeader?: boolean;
}) => {
  return (
    <>
      <Flex justify="between" align="center">
        {showHeader && (
          <Heading size="3">
            {mapping.frameworkId} {mapping.clause}
          </Heading>
        )}
        <RemoveControlMappingButton control={control} mapping={mapping} />
      </Flex>
      <FullWidthSeparator my="2" />
      <ScrollArea type="auto" style={{ height: "300px" }}>
        <ReactMarkdown
          components={{
            p: ({ children }) => {
              return <Text>{children}</Text>;
            },
            ol: ({ children }) => {
              return <ol style={{ listStyle: "lower-alpha" }}>{children}</ol>;
            },
          }}
        >
          {requirementLanguage}
        </ReactMarkdown>
      </ScrollArea>
    </>
  );
};

const RemoveControlMappingButton = ({
  control,
  mapping,
}: {
  control: ProcessControl;
  mapping: ControlRequirementMapping;
}) => {
  return (
    <RemoveComplianceMappingAction
      control={control}
      frameworkId={mapping.frameworkId}
      clause={mapping.clause}
    >
      <PermissionedButton
        iconButton
        size="1"
        variant="soft"
        color="gray"
        permission="write.processes"
      >
        <IconTrash />
      </PermissionedButton>
    </RemoveComplianceMappingAction>
  );
};

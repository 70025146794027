import { Button, Dialog } from "@radix-ui/themes";
import { FormApi, ReactFormApi } from "@tanstack/react-form";
import React from "react";

export interface CancelButtonProps {
  form: FormApi<any, any> & ReactFormApi<any, any>;
}

export const CancelButton: React.FC<CancelButtonProps> = ({ form }) => {
  return (
    <Dialog.Close>
      <Button variant="soft" color="gray" size="3">
        Cancel
      </Button>
    </Dialog.Close>
  );
};

import { CreateDocumentAction } from "@/components/documents/CreateDocumentAction";
import PageTitle from "@/components/PageTitle";
import { SearchFilter } from "@/components/SearchFilter";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { BadgeRenderer, UserRenderer } from "@/components/tables/renderers";
import { Document, useDocuments } from "@/state/queries/documents";
import { Flex } from "@radix-ui/themes";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute("/_app/$tenantSlug/documents/")({
  // Empty because the parent route sets the title
  beforeLoad: () => ({
    getTitle: () => "",
  }),
  component: Documents,
});

function Documents() {
  const [filter, setFilter] = useState("");

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title="Documents"
        description="Manage official organization documents while maintaining compliance."
      />
      <Flex justify="between" align="center" py="2">
        <SearchFilter
          filter={filter}
          setFilter={setFilter}
          placeholder="Search documents"
        />
        <CreateDocumentAction />
      </Flex>
      <DocumentsTable filterText={filter} />
    </Flex>
  );
}

const getColDefs = (tenantSlug: string): ColDef<Document>[] => [
  {
    field: "title",
    headerName: "Title",
    cellRenderer: ({ data, value }: { data: Document; value: string }) => (
      <Link to={`/${tenantSlug}/documents/${data.id}`}>{value}</Link>
    ),
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    width: 90,
    cellRenderer: BadgeRenderer,
  },
  {
    field: "id",
    headerName: "ID",
    width: 110,
  },
  {
    field: "owner",
    headerName: "Owner",
    cellRenderer: UserRenderer,
    width: 180,
  },
  {
    field: "processId",
    headerName: "Linked Process",
    cellRenderer: (props: ICellRendererParams) => (
      <Link to={`/${tenantSlug}/processes/${props.data.processId}`}>
        {props.data.processName}
      </Link>
    ),
    flex: 1,
  },
];

const DocumentsTable: React.FC<{
  filterText: string;
}> = ({ filterText }) => {
  const navigate = useNavigate();
  const { tenantSlug } = Route.useParams();
  const { data: documents, isLoading } = useDocuments();

  return (
    <BaseAgGrid<Document>
      loading={isLoading}
      rowData={documents}
      columnDefs={getColDefs(tenantSlug)}
      quickFilterText={filterText}
      selectableRows
      onRowClicked={(rowClick) => {
        if (rowClick.data) {
          navigate({
            to: "/$tenantSlug/documents/$documentId",
            params: { tenantSlug, documentId: rowClick.data.id },
          });
        }
      }}
      autoSizeStrategy={{
        type: "fitCellContents",
      }}
    />
  );
};

export default Documents;

import { PermissionedButton } from "@/components/PermissionedButton";
import { useSendProcessRevisionForReview } from "@/state/mutations/processes/revisions/sendProcessRevisionForReview";
import { Process, ProcessRevision } from "@/state/queries/processes";
import { THEME_COLOR } from "@/theme";
import { AlertDialog } from "@radix-ui/themes";
import { IconSend } from "@tabler/icons-react";
import { useState } from "react";
import MeridianAlertDialog from "../alert-dialogs";

export const SendProcessRevisionForReviewAction: React.FC<{
  process: Process;
  revision: ProcessRevision;
  onSuccess?: () => void;
}> = ({ process, revision, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: sendProcessRevisionForReview,
    isPending,
    error,
    reset,
  } = useSendProcessRevisionForReview();

  const onSubmit = () => {
    sendProcessRevisionForReview(
      { process, revision },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          color={THEME_COLOR}
          size="1"
          disabled={revision.controls.length === 0}
        >
          <IconSend />
          Send for review
        </PermissionedButton>
      </AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Send for review?"
          description="Once you send this revision for review, you won't be able to make further changes until the review is complete."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Send for review",
            color: THEME_COLOR,
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

import NonIdealState from "@/components/NonIdealState";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import {
  RecordTypeRenderer,
  TimestampRenderer,
} from "@/components/tables/renderers";
import { Workflow } from "@/state/queries/workflows";
import { IconArrowRightRhombus } from "@tabler/icons-react";
import { Link, useNavigate } from "@tanstack/react-router";
import { ColDef } from "ag-grid-community";

const WorkflowsTable: React.FC<{
  workflows: Workflow[];
  tenantSlug: string;
  filter?: string;
}> = ({ workflows, tenantSlug, filter }) => {
  const navigate = useNavigate();

  const columnDefs: ColDef<Workflow>[] = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: ({ data, value }: { data: Workflow; value: string }) => (
        <Link
          to="/$tenantSlug/settings/organization/workflows/$workflowId"
          params={{ tenantSlug, workflowId: data.id }}
        >
          {value}
        </Link>
      ),
      width: 200,
    },
    {
      field: "primaryRecordTypeId",
      headerName: "Primary Record Type",
      width: 200,
      cellRenderer: RecordTypeRenderer,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      width: 130,
      cellRenderer: TimestampRenderer,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  return (
    <>
      <BaseAgGrid
        columnDefs={columnDefs}
        rowData={workflows}
        quickFilterText={filter}
        selectableRows
        onRowClicked={(rowClick) => {
          if (rowClick.data) {
            navigate({
              to: "/$tenantSlug/settings/organization/workflows/$workflowId",
              params: { tenantSlug, workflowId: rowClick.data.id },
            });
          }
        }}
        autoSizeStrategy={{
          type: "fitCellContents",
        }}
        noRowsOverlayComponent={() => (
          <NonIdealState
            icon={<IconArrowRightRhombus />}
            title="No workflows yet"
          />
        )}
      />
    </>
  );
};

export default WorkflowsTable;

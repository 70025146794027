import { RecordField } from "@/types/recordFields";
import { RecordFieldValues } from "@/types/records";
import { RecordType } from "@/types/recordTypes";
import { Flex, Text } from "@radix-ui/themes";
import { IconArrowsDiagonal } from "@tabler/icons-react";
import { Link } from "@tanstack/react-router";
import {
  CellClickedEvent,
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  RowSelectedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { isEmpty, keyBy } from "lodash-es";
import { useMemo } from "react";
import { BaseAgGrid } from "../tables/BaseAgGrid";
import { getFormatterForFieldType } from "../tables/formatters";
import {
  getRendererForFieldType,
  getRendererParamsForFieldType,
} from "../tables/renderers";
import { useViewContext } from "./ViewContext";

export const RecordsTable: React.FC<{
  gridRef: React.RefObject<AgGridReact<RecordFieldValues>>;
  loading: boolean;
  records: RecordFieldValues[];
  navigateToRecord: (recordId: string) => void;
  onSelectionChange: (selectedIds: string[]) => void;
}> = ({ gridRef, loading, records, navigateToRecord, onSelectionChange }) => {
  const { recordType, currentViewConfig, tableViewConfig, setTableViewConfig } =
    useViewContext();

  const fieldsById = useMemo(() => {
    return keyBy(recordType.fields, "id");
  }, [recordType]);

  const filteredRecords = useMemo(() => {
    return records?.filter((record) =>
      !currentViewConfig?.defaultFilter ||
      isEmpty(currentViewConfig.defaultFilter)
        ? true
        : record.status ===
          currentViewConfig.defaultFilter.statusFilter?.statuses[0]
    );
  }, [records, currentViewConfig]);

  const colDefs = useMemo(() => {
    const colDefs: ColDef<RecordFieldValues>[] = [
      {
        field: "id",
        headerName: recordType.name,
        width: 160,
        pinned: "left",
        suppressMovable: true,
        cellStyle: {
          cursor: "pointer",
        },
        onCellClicked: (params: CellClickedEvent<RecordFieldValues>) => {
          navigateToRecord(params.data?.id);
        },
        cellRenderer: (props: ICellRendererParams) => {
          return (
            <Flex align="center" gap="2">
              <Link>{props.value}</Link>
              {/* TODO: This is hacky, make this better */}
              <Text
                className="cell-icon"
                style={{
                  color: "var(--gray-10)",
                  opacity: 0,
                  transition: "opacity 0.2s",
                }}
                mt="1"
              >
                <IconArrowsDiagonal />
              </Text>
            </Flex>
          );
        },
      },
    ];
    return [
      ...colDefs,
      ...tableViewConfig.columns
        .filter((config) => fieldsById[config.fieldId])
        .map((config) => fieldsById[config.fieldId])
        .map((field) => fieldToColDef(recordType, field)),
    ];
  }, [recordType, tableViewConfig, navigateToRecord, fieldsById]);

  const handleRowSelected = (event: RowSelectedEvent<RecordFieldValues>) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChange(selectedRows.map((row) => row.id));
  };

  // Attach an event handler to add a class when the table is scrolled off the left border
  const onGridReady = ({ api }: GridReadyEvent) => {
    const leftPinnedContainer = document.querySelector(
      ".ag-pinned-left-cols-container"
    );
    if (!leftPinnedContainer) return;

    let lastScrollState = false;
    let ticking = false;

    api.addEventListener("bodyScroll", (event) => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const isScrolled = event.left > 25;
          if (isScrolled !== lastScrollState) {
            leftPinnedContainer.classList.toggle(
              "is-table-scrolled",
              isScrolled
            );
            lastScrollState = isScrolled;
          }
          ticking = false;
        });
        ticking = true;
      }
    });
  };

  // TODO: This is probably inefficient
  const handleColumnsChanged = () => {
    const colState = gridRef.current?.api.getColumnState();
    if (colState) {
      const columns = colState
        .filter((gridCol) => gridCol.colId !== "id")
        .filter((gridCol) => !gridCol.hide)
        .map((gridCol) => ({
          fieldId: gridCol.colId,
          // TODO: Bring this back when we want to save column widths
          // width: gridCol.width,
        }));
      setTableViewConfig({
        ...tableViewConfig,
        columns,
      });
    }
  };

  return (
    <BaseAgGrid<RecordFieldValues>
      ref={gridRef}
      height="100vh"
      loading={loading}
      rowData={filteredRecords}
      columnDefs={colDefs}
      getRowId={({ data }) => data.id}
      fullBleed
      selectableRows
      suppressMoveWhenColumnDragging
      rowSelection={{
        mode: "singleRow",
        checkboxes: false,
        enableClickSelection: true,
        enableSelectionWithoutKeys: true,
      }}
      autoSizeStrategy={{
        type: "fitCellContents",
      }}
      onGridReady={onGridReady}
      onRowSelected={handleRowSelected}
      // TODO: Bring this back when we want to save column widths
      // onColumnResized={handleColumnsChanged}
      onColumnMoved={handleColumnsChanged}
      onColumnVisible={handleColumnsChanged}
    />
  );
};

const fieldToColDef = (
  recordType: RecordType,
  field: RecordField
): ColDef<RecordFieldValues> => {
  return {
    field: field.id,
    headerName: field.name,
    valueFormatter: getFormatterForFieldType(field.type),
    cellRenderer: getRendererForFieldType(field.type),
    cellRendererParams: getRendererParamsForFieldType(recordType, field.type),
    maxWidth: 400,
  };
};

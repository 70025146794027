import { PermissionedButton } from "@/components/PermissionedButton";
import {
  CreateOrUpdateRecordTypeAction,
  RecordTypeDialogOperation,
} from "@/components/record-types/CreateOrUpdateRecordTypeAction";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import TabTitle from "@/components/TabTitle";
import { useRecordAndLinkTypesByProcess } from "@/state/queries/recordLinkTypes";
import { RecordType } from "@/types/recordTypes";
import { Flex } from "@radix-ui/themes";
import { IconCubeOff, IconPlus } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ColDef } from "ag-grid-community";

export const Route = createFileRoute(
  "/_app/$tenantSlug/processes/$processId/record-types"
)({
  beforeLoad: () => {
    return {
      getTitle: () => "Record Types",
    };
  },
  component: ProcessRecordTypes,
});

function ProcessRecordTypes() {
  const { processId } = Route.useParams();

  return (
    <>
      <TabTitle title="Record Types">
        <CreateOrUpdateRecordTypeAction
          processId={processId}
          operation={RecordTypeDialogOperation.create({})}
        >
          <PermissionedButton permission="write.processes">
            <IconPlus />
            Add record type
          </PermissionedButton>
        </CreateOrUpdateRecordTypeAction>
      </TabTitle>
      <ProcessRecordTypesTable />
    </>
  );
}

function ProcessRecordTypesTable() {
  const { tenantSlug, processId } = Route.useParams();
  const { data: recordTypes, isLoading } =
    useRecordAndLinkTypesByProcess(processId);

  const colDefs: ColDef<RecordType>[] = [
    {
      field: "name",
      headerName: "Record Type",
      width: 140,
      cellRenderer: ({ data }: { data: RecordType }) => (
        <Flex align="center" gap="2">
          {/* <Box height="28px" style={{ color: "var(--gray-11)" }}>
            {data.icon}
          </Box> */}
          <Link
            to="/$tenantSlug/settings/organization/record-types/$recordTypeId"
            params={{
              tenantSlug,
              recordTypeId: data.id,
            }}
          >
            {data.name}
          </Link>
        </Flex>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 220,
    },
    {
      headerName: "Fields",
      cellRenderer: ({ data }: { data: RecordType }) => (
        <Flex gap="2">{data.fields.map((field) => field.name).join(", ")}</Flex>
      ),
      flex: 1,
    },
  ];

  return (
    <BaseAgGrid<RecordType>
      loading={isLoading}
      rowData={recordTypes}
      columnDefs={colDefs}
      noRowsOverlayComponentParams={{
        icon: <IconCubeOff />,
        title: "No record types",
      }}
    />
  );
}

import Form from "@/components/forms";
import { useCreateWorkflow } from "@/state/mutations/workflows/createWorkflow";
import { useRecordAndLinkTypes } from "@/state/queries/recordLinkTypes";
import { Dialog } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { isEmpty } from "lodash-es";
import { useEffect, useState } from "react";
import { PermissionedButton } from "../PermissionedButton";
import MeridianDialog from "../dialogs";

type CreateWorkflowFormInputs = {
  name: string;
  description: string;
  processId: string;
  primaryRecordTypeId: string;
};

export const CreateWorkflowAction = ({ processId }: { processId?: string }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: createWorkflow,
    error,
    isPending,
    reset: resetMutation,
  } = useCreateWorkflow();
  const { data: recordAndLinkTypes } = useRecordAndLinkTypes();

  const form = useForm<CreateWorkflowFormInputs>({
    defaultValues: {
      name: "",
      description: "",
      primaryRecordTypeId: "",
      processId: processId ?? "",
    },
    onSubmit: ({ value }) => {
      createWorkflow(value, {
        onSuccess: () => {
          setOpen(false);
        },
      });
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    resetMutation();
    form.reset();
  };

  const primaryRecordTypeId = form.useStore(
    (state) => state.values.primaryRecordTypeId
  );
  useEffect(() => {
    const processId = form.getFieldValue("processId");
    if (isEmpty(processId) && primaryRecordTypeId && recordAndLinkTypes) {
      const recordType = recordAndLinkTypes.recordTypes.find(
        (rt) => rt.id === primaryRecordTypeId
      );
      if (recordType) {
        form.setFieldValue("processId", recordType.processId);
        form.validateField("processId", "change");
      }
    }
  }, [form, recordAndLinkTypes, primaryRecordTypeId]);

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton permission="write.processes">
          <IconPlus />
          Create workflow
        </PermissionedButton>
      </Dialog.Trigger>

      <MeridianDialog.Content>
        <MeridianDialog.Header title="Create workflow" />
        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Form.TextField<CreateWorkflowFormInputs>
              reactFormField={{ form, name: "name" }}
              fieldProps={{ label: "Name", required: true }}
              placeholder="Workflow name"
            />
            <Form.RecordTypeSelect<CreateWorkflowFormInputs>
              reactFormField={{ form, name: "primaryRecordTypeId" }}
              fieldProps={{ label: "Primary Record Type", required: true }}
            />
            <Form.ProcessSelect<CreateWorkflowFormInputs>
              reactFormField={{ form, name: "processId" }}
              fieldProps={{ label: "Parent Process", required: true }}
            />
            <Form.TextArea<CreateWorkflowFormInputs>
              reactFormField={{ form, name: "description" }}
              fieldProps={{ label: "Description" }}
              placeholder="Describe this workflow"
            />

            <Form.Footer
              form={form}
              error={error}
              isPending={isPending}
              buttonText="Create workflow"
              errorPrefix="Failed to create workflow: "
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

/* eslint-disable @typescript-eslint/no-redeclare */
import { KeyedTypeUnion, createTypeUnion } from "keyed-type-union";

/**
 * Defining this placeholder typing for now. We should be able to add some
 * more information on each record once we load them, such as the id, status,
 * and recordTypeId.
 */
export type RecordFieldValues = { [fieldId: string]: any };

// TODO: These types should maybe live under action forms instead, as record field values
// are always just arrays of filePaths.
export interface ExistingFile {
  filePath: string;
}

export interface NewFile {
  file: File;
}

export type FileValue = KeyedTypeUnion<{
  existing: ExistingFile;
  new: NewFile;
}>;
export const FileValue = createTypeUnion<FileValue>({
  existing: undefined,
  new: undefined,
});

import { useTenantContext } from "@/contexts/TenantContext";
import { Database } from "@/database/generated.types";
import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery, UseQueryResult } from "@tanstack/react-query";

export type ProcessStatus = Database["public"]["Enums"]["process_status"];
export type ProcessRevisionStatus =
  Database["public"]["Enums"]["process_revision_status"];

export interface Process {
  id: string;
  createdAt: string;
  createdBy: string;
  description?: string;
  name: string;
  ownedBy: string;
  tenantId: string;
  updatedAt: string;
  updatedBy: string;
  status: ProcessStatus;
  revisions: ProcessRevision[];
  latestRevision: ProcessRevision;
}

export interface ProcessRevision {
  id: string;
  status: ProcessRevisionStatus;
  revisionNumber: number | undefined;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  approvedAt: string | undefined;
  approvedBy: string | undefined;
  controls: ProcessControl[];
}

export interface ProcessControl {
  controlId: string;
  controlRevisionId: string;
  title: string;
  language: string;

  mappings: ControlRequirementMapping[];
}

export interface ControlRequirementMapping {
  frameworkId: string;
  clause: string;
}

const fetchProcesses = async (tenantId: string): Promise<Process[]> => {
  const { data, error } = await supabase
    .from("processes")
    .select(
      `
      *,
      process_revisions (
        id,
        status,
        revision_number,
        created_at,
        created_by,
        updated_at,
        updated_by,
        approved_at,
        approved_by,
        control_revisions (
          id,
          control_id,
          title,
          language,
          controls (
            control_requirements_mapping (
              framework_id,
              clause
            )          
          )
        )
      )
    `
    )
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  if (!data) {
    return [];
  }

  return data.map((process) => {
    let revisions = process.process_revisions.map((revision) => ({
      id: revision.id,
      status: revision.status,
      revisionNumber: revision.revision_number ?? undefined,
      createdAt: revision.created_at,
      createdBy: revision.created_by,
      updatedAt: revision.updated_at,
      updatedBy: revision.updated_by,
      approvedAt: revision.approved_at ?? undefined,
      approvedBy: revision.approved_by ?? undefined,
      controls: revision.control_revisions.map((control_revision) => ({
        controlRevisionId: control_revision.id,
        controlId: control_revision.control_id,
        language: control_revision.language ?? "",
        title: control_revision.title ?? "",
        mappings:
          control_revision.controls?.control_requirements_mapping.map(
            (mapping) => ({
              frameworkId: mapping.framework_id,
              clause: mapping.clause,
            })
          ) ?? [],
      })),
    }));

    revisions = revisions.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    const latestRevision =
      revisions.find((r) => r.status === "Released") ?? revisions[0];

    return {
      id: process.id,
      status: process.status,
      createdAt: process.created_at,
      createdBy: process.created_by,
      description: process.description ?? undefined,
      name: process.name,
      ownedBy: process.owned_by,
      tenantId: process.tenant_id,
      updatedAt: process.updated_at,
      updatedBy: process.updated_by,
      revisions,
      latestRevision,
    };
  });
};

export const processesQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["processes", tenantId],
    queryFn: () => fetchProcesses(tenantId),
    staleTime: 1000 * 60 * 5,
    retry: false,
  });

export const useProcesses = (): UseQueryResult<Process[]> => {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(processesQueryOptions(tenant.tenant.tenantId));
};

export const useProcess = (processId: string) => {
  const { data: processes, ...rest } = useProcesses();

  return {
    ...rest,
    data: processes?.find((process) => process.id === processId),
  };
};

import Form from "@/components/forms";
import { useCreateRecordType } from "@/state/mutations/record-types/createRecordType";
import { useUpdateRecordType } from "@/state/mutations/record-types/updateRecordType";
import { Dialog, Grid } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { createTypeUnion, KeyedTypeUnion } from "keyed-type-union";
import { PropsWithChildren, useState } from "react";
import { z } from "zod";
import { RecordType, RecordTypeWithoutFields } from "../../types/recordTypes";
import MeridianDialog from "../dialogs";

export type RecordTypeDialogOperation = KeyedTypeUnion<{
  create: {};
  update: {
    recordType: RecordType;
  };
}>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecordTypeDialogOperation =
  createTypeUnion<RecordTypeDialogOperation>({
    create: undefined,
    update: undefined,
  });

interface RecordTypeDialogProps {
  processId?: string;
  operation: RecordTypeDialogOperation;
}

interface RecordTypeFormInputs {
  name: string;
  pluralName: string;
  id: string;
  key: string;
  description: string;
  processId: string;
}

export const CreateOrUpdateRecordTypeAction: React.FC<
  PropsWithChildren<RecordTypeDialogProps>
> = ({ processId, operation, children }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: createRecordType,
    error: createRecordTypeError,
    isPending: isPendingCreateRecordType,
    reset: resetCreateRecordType,
  } = useCreateRecordType();
  const {
    mutate: updateRecordType,
    error: updateRecordTypeError,
    isPending: isPendingUpdateRecordType,
    reset: resetUpdateRecordType,
  } = useUpdateRecordType();
  const defaultValues = RecordTypeDialogOperation.isUpdate(operation)
    ? {
        ...operation.update.recordType,
        description: operation.update.recordType.description ?? "",
      }
    : {
        name: "",
        pluralName: "",
        id: "",
        key: "",
        description: "",
        processId: processId ?? "",
      };

  const form = useForm<RecordTypeFormInputs, any>({
    defaultValues,
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        name: z.string().min(1, { message: "Record type name is required" }),
        pluralName: z
          .string()
          .min(1, { message: "Record type plural name is required" }),
        id: z
          .string()
          .min(1, { message: "Record type id is required" })
          .regex(/^[a-z][a-z_]*$/, {
            message: "ID must be lowercase alphabetical with underscores",
          }),
        key: z
          .string()
          .min(2, { message: "Record type key is required" })
          .max(6)
          .regex(/^[A-Z]+$/, { message: "Key must be 2-6 uppercase letters" }),
      }),
    },
    onSubmit: ({ value }) => {
      const recordType: RecordTypeWithoutFields = {
        id: value.id,
        name: value.name,
        pluralName: value.pluralName,
        key: value.key,
        description: value.description,
        processId: value.processId,
        source: "Managed",
        icon: "",
      };
      if (RecordTypeDialogOperation.isCreate(operation)) {
        createRecordType(recordType, { onSuccess: () => setOpen(false) });
      } else {
        updateRecordType(recordType, { onSuccess: () => setOpen(false) });
      }
    },
  });

  // TODO make this actually work
  // Auto-generate the key, plural name, and id from the name if they are not already set
  // const name = form.useStore((state) => state.values.name);
  // useEffect(() => {
  //   if (!form.getFieldMeta("key")?.isBlurred) {
  //     form.setFieldValue("key", name.split(" ").join("").toUpperCase());
  //     form.validateField("key", "change");
  //   }
  //   if (!form.getFieldMeta("pluralName")?.isTouched) {
  //     form.setFieldValue("pluralName", name + "s");
  //     form.validateField("pluralName", "change");
  //   }
  //   if (!form.getFieldMeta("id")?.isTouched) {
  //     form.setFieldValue("id", name.toLowerCase().split(" ").join("_"));
  //     form.validateField("id", "change");
  //   }
  // }, [form, name]);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    resetCreateRecordType();
    resetUpdateRecordType();
  };

  const title = RecordTypeDialogOperation.isCreate(operation)
    ? "Create record type"
    : "Update record type";

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <MeridianDialog.Content maxWidth="700px" aria-describedby={undefined}>
        <MeridianDialog.Header title={title} />
        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Grid columns="2" gap="4" my="2">
              <Form.TextField<RecordTypeFormInputs>
                reactFormField={{ form, name: "name" }}
                fieldProps={{ label: "Name (singular)", required: true }}
                placeholder="Supplier"
              />
              <Form.TextField<RecordTypeFormInputs>
                reactFormField={{ form, name: "pluralName" }}
                fieldProps={{ label: "Name (plural)", required: true }}
                placeholder="Suppliers"
              />
              <Form.TextField<RecordTypeFormInputs>
                reactFormField={{ form, name: "id" }}
                fieldProps={{ label: "ID", required: true }}
                placeholder="record_type_id"
                disabled={RecordTypeDialogOperation.isUpdate(operation)}
              />
              <Form.TextField<RecordTypeFormInputs>
                reactFormField={{ form, name: "key" }}
                fieldProps={{ label: "Key", required: true }}
                placeholder="KEY"
                disabled={RecordTypeDialogOperation.isUpdate(operation)}
              />
              <Form.ProcessSelect<RecordTypeFormInputs>
                reactFormField={{ form, name: "processId" }}
                fieldProps={{
                  label: "Parent Process",
                  required: true,
                }}
              />
              <Form.TextArea<RecordTypeFormInputs>
                reactFormField={{ form, name: "description" }}
                fieldProps={{
                  label: "Description",
                  style: { gridColumn: "span 2" },
                }}
                placeholder="Describe this record type"
              />
            </Grid>
            <Form.Footer
              form={form}
              error={createRecordTypeError || updateRecordTypeError}
              isPending={isPendingCreateRecordType || isPendingUpdateRecordType}
              buttonText={
                RecordTypeDialogOperation.isCreate(operation)
                  ? "Create record type"
                  : "Update record type"
              }
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

import { useDeleteView } from "@/state/mutations/views/deleteView";
import { AlertDialog } from "@radix-ui/themes";
import MeridianAlertDialog from "../alert-dialogs";
import { useViewContext } from "./ViewContext";

export const DeleteViewDialog: React.FC<{
  onSuccess?: () => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}> = ({ onSuccess, open, onOpenChange }) => {
  const { currentViewConfig } = useViewContext();
  const { mutate: deleteView, isPending, error, reset } = useDeleteView();

  const onSubmit = () => {
    deleteView(
      { id: currentViewConfig.id },
      {
        onSuccess: () => {
          onOpenChange(false);
          onSuccess?.();
        },
      }
    );
  };

  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={handleOpenChange}>
      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Delete view?"
          description="This view will be permanently deleted. The records in this view will not be affected."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Delete view",
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

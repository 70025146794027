import Form from "@/components/forms";
import { useAddComplianceMapping } from "@/state/mutations/processes/complianceMappings";
import { useComplianceFrameworks } from "@/state/queries/complianceFrameworks";
import { ProcessControl } from "@/state/queries/processes";
import { compareClauses } from "@/utils/compareClauses";
import { Dialog, Select } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { PropsWithChildren, useMemo, useState } from "react";
import MeridianDialog from "../dialogs";

interface AddComplianceMappingActionProps {
  control: ProcessControl;
}

interface AddComplianceMappingFormInputs {
  frameworkId: string;
  clause: string;
}

export const AddComplianceMappingAction: React.FC<
  PropsWithChildren<AddComplianceMappingActionProps>
> = ({ control, children }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: addComplianceMapping,
    error,
    reset,
    isPending,
  } = useAddComplianceMapping();
  const { data: frameworks } = useComplianceFrameworks();

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    reset();
  };

  const form = useForm<AddComplianceMappingFormInputs>({
    defaultValues: {
      frameworkId: "AS9100D",
      clause: "",
    },
    onSubmit: ({ value }) => {
      addComplianceMapping(
        {
          controlId: control.controlId,
          frameworkId: value.frameworkId,
          clause: value.clause,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const clausesByFramework = useMemo(
    () =>
      frameworks?.reduce(
        (acc, framework) => {
          acc[framework.id] = framework.requirements.map((requirement) => ({
            id: requirement.clause,
            title: requirement.title,
          }));
          return acc;
        },
        {} as Record<string, { id: string; title: string }[]>
      ),
    [frameworks]
  );

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <MeridianDialog.Content>
        <MeridianDialog.Header title="Map to requirement" />
        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Form.Fields>
              <Form.Select<AddComplianceMappingFormInputs>
                reactFormField={{ form, name: "frameworkId" }}
                fieldProps={{
                  label: "Framework",
                }}
              >
                <Select.Content>
                  {frameworks?.map((framework) => (
                    <Select.Item value={framework.id}>
                      {framework.displayName}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Form.Select>

              <Form.Select<AddComplianceMappingFormInputs>
                reactFormField={{ form, name: "clause" }}
                fieldProps={{
                  label: "Clause",
                }}
              >
                <form.Subscribe
                  selector={(state) => [state.values.frameworkId]}
                  children={([frameworkId]) => {
                    const clauses = clausesByFramework?.[frameworkId];
                    const sortedClauses = clauses
                      ?.filter(
                        (c) =>
                          !control.mappings.some(
                            (m) =>
                              m.frameworkId === frameworkId && m.clause === c.id
                          )
                      )
                      .sort((a, b) => compareClauses(a.id, b.id));
                    return (
                      <Select.Content>
                        {sortedClauses?.map((clause) => (
                          <Select.Item value={clause.id}>
                            {clause.id} - {clause.title}
                          </Select.Item>
                        ))}
                      </Select.Content>
                    );
                  }}
                />
              </Form.Select>
            </Form.Fields>

            <Form.Footer
              form={form}
              error={error}
              isPending={isPending}
              buttonText="Add mapping"
              errorPrefix="Failed to add mapping: "
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

import { MeridianAlertDialogContent } from "./MeridianAlertDialogContent";
import { MeridianAlertDialogFooter } from "./MeridianAlertDialogFooter";
import { MeridianAlertDialogHeader } from "./MeridianAlertDialogHeader";

const MeridianAlertDialog = {
  Content: MeridianAlertDialogContent,
  Header: MeridianAlertDialogHeader,
  Footer: MeridianAlertDialogFooter,
};

export default MeridianAlertDialog;

import { AlertDialog, Flex, Text } from "@radix-ui/themes";
import React from "react";
import { FormError } from "../forms/FormError";

interface MeridianAlertDialogHeaderProps {
  title: string;
  description: string;
  errorMessage?: string;
}

export const MeridianAlertDialogHeader: React.FC<
  MeridianAlertDialogHeaderProps
> = ({ title, description, errorMessage }) => {
  return (
    <>
      <AlertDialog.Title>{title}</AlertDialog.Title>

      <AlertDialog.Description>
        <Flex direction="column" gap="2">
          <Text color="gray">{description}</Text>
          {errorMessage && <FormError message={errorMessage} />}
        </Flex>
      </AlertDialog.Description>
    </>
  );
};

import Form from "@/components/forms";
import { useUpdateView } from "@/state/mutations/views/updateView";
import { Dialog } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import MeridianDialog from "../dialogs";
import { useViewContext } from "./ViewContext";

interface UpdateViewTitleInputs {
  name: string;
}

export const UpdateViewTitleDialog: React.FC<{
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
}> = ({ isOpen, onOpenChange, onSuccess }) => {
  const { currentViewConfig } = useViewContext();
  const { mutate: updateView, isPending, error, reset } = useUpdateView();

  const form = useForm<UpdateViewTitleInputs, any>({
    defaultValues: {
      name: currentViewConfig.name,
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        name: z.string().min(2, { message: "View name is required" }),
      }),
    },
    onSubmit: ({ value }) => {
      updateView(
        {
          ...currentViewConfig,
          name: value.name,
        },
        { onSuccess }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);
    if (!open) {
      reset();
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      <MeridianDialog.Content maxWidth="450px">
        <MeridianDialog.Header title="Update view title" />

        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Form.TextField<UpdateViewTitleInputs>
              reactFormField={{ form, name: "name" }}
              fieldProps={{ label: "Title", required: true }}
              placeholder="Enter view title"
            />

            <Form.Footer
              form={form}
              error={error}
              isPending={isPending}
              buttonText="Update view"
              errorPrefix="Failed to update view: "
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

import {
  BooleanDefaultValue,
  BooleanFormField,
  DateDefaultValue,
  DateFormField,
  FileDefaultValue,
  FileFormField,
  LinkDefaultValue,
  LinkFormField,
  NumberDefaultValue,
  TextDefaultValue,
  TextFormField,
  TimestampDefaultValue,
  TimestampFormField,
  UserIdDefaultValue,
  UserIdFormField,
  ValuesDefaultValue,
  ValuesFormField,
} from "@/types/actionFormField";

import { FormFieldLabel } from "@/components/forms/FormField";
import {
  ActionFormFieldTypeData,
  NumberFormField,
} from "@/types/actionFormField";
import { ActionOperation } from "@/types/actions";
import { Flex, RadioCards, Text } from "@radix-ui/themes";
import { FormApi, ReactFormApi, useField } from "@tanstack/react-form";
import { createContext, useContext } from "react";
import { ActionConfiguration } from "./EditActionDialogContent";

export const FormFieldTypeConfiguration = ({
  form,
  index,
}: {
  form: FormApi<ActionConfiguration> & ReactFormApi<ActionConfiguration>;
  index: number;
}) => {
  const field = useField<ActionConfiguration, `form.fields[${number}].type`>({
    form,
    name: `form.fields[${index}].type`,
  });

  const operation = form.state.values.operation;

  const setValue = (value: ActionFormFieldTypeData) => {
    field.setValue(value);
  };

  const typeConfig = ActionFormFieldTypeData.visit<React.ReactNode>(
    field.state.value,
    {
      number: (value: NumberFormField) => (
        <NumberFormFieldConfiguration value={value} />
      ),
      boolean: (value: BooleanFormField) => (
        <BooleanFormFieldConfiguration value={value} />
      ),
      text: (value: TextFormField) => (
        <TextFormFieldConfiguration value={value} />
      ),
      date: (value: DateFormField) => (
        <DateFormFieldConfiguration value={value} />
      ),
      timestamp: (value: TimestampFormField) => (
        <TimestampFormFieldConfiguration value={value} />
      ),
      link: (value: LinkFormField) => (
        <LinkFormFieldConfiguration value={value} />
      ),
      values: (value: ValuesFormField) => (
        <ValuesFormFieldConfiguration value={value} />
      ),
      userId: (value: UserIdFormField) => (
        <UserIdFormFieldConfiguration value={value} />
      ),
      file: (value: FileFormField) => (
        <FileFormFieldConfiguration value={value} />
      ),
    }
  );

  return (
    <FormFieldTypeConfigContext.Provider value={{ setValue, operation }}>
      {typeConfig}
    </FormFieldTypeConfigContext.Provider>
  );
};

const NumberFormFieldConfiguration = ({
  value,
}: {
  value: NumberFormField;
}) => {
  return (
    <DefaultValueConfiguration
      value={value}
      noDefaultValue={ActionFormFieldTypeData.number({
        defaultValue: undefined,
      })}
      currentValue={ActionFormFieldTypeData.number({
        defaultValue: NumberDefaultValue.currentValue({}),
      })}
    />
  );
};

const TextFormFieldConfiguration = ({ value }: { value: TextFormField }) => {
  const { operation, setValue } = useFormFieldTypeConfigContext();

  const formattingValue = !value.multiline ? "single_line" : "multiline";
  const handleFormattingChange = (option: "single_line" | "multiline") => {
    if (option === "single_line") {
      setValue(
        ActionFormFieldTypeData.text({
          multiline: false,
          defaultValue: value.defaultValue,
        })
      );
    } else {
      setValue(
        ActionFormFieldTypeData.text({
          multiline: true,
          defaultValue: value.defaultValue,
        })
      );
    }
  };

  const defaultValueOption = !value.defaultValue ? "none" : "current_value";

  const handleDefaultValueChange = (option: "none" | "current_value") => {
    if (option === "none") {
      setValue(
        ActionFormFieldTypeData.text({
          multiline: value.multiline,
          defaultValue: undefined,
        })
      );
    } else if (option === "current_value") {
      setValue(
        ActionFormFieldTypeData.text({
          multiline: value.multiline,
          defaultValue: TextDefaultValue.currentValue({}),
        })
      );
    }
  };

  return (
    <>
      <Flex direction="column" gap="2">
        <FormFieldLabel name="formatting" label="Formatting" />
        <RadioCards.Root
          value={formattingValue}
          onValueChange={handleFormattingChange}
          size="1"
        >
          <RadioCards.Item value="single_line">
            <Flex direction="column" width="100%">
              <Text weight="bold">Single Line</Text>
              <Text size="1">Single line of text input</Text>
            </Flex>
          </RadioCards.Item>
          <RadioCards.Item value="multiline">
            <Flex direction="column" width="100%">
              <Text weight="bold">Multiline</Text>
              <Text size="1">Multiple lines of text</Text>
            </Flex>
          </RadioCards.Item>
        </RadioCards.Root>
      </Flex>
      {ActionOperation.isUpdate(operation) && (
        <Flex direction="column" gap="2">
          <FormFieldLabel name="default-value" label="Default Value" />
          <RadioCards.Root
            value={defaultValueOption}
            onValueChange={handleDefaultValueChange}
            size="1"
          >
            <RadioCards.Item value="none">
              <Flex direction="column" width="100%">
                <Text weight="bold">None</Text>
                <Text size="1">No default value</Text>
              </Flex>
            </RadioCards.Item>
            <RadioCards.Item value="current_value">
              <Flex direction="column" width="100%">
                <Text weight="bold">Current Value</Text>
                <Text size="1">Set to current value on the record</Text>
              </Flex>
            </RadioCards.Item>
          </RadioCards.Root>
        </Flex>
      )}
    </>
  );
};

const BooleanFormFieldConfiguration = ({
  value,
}: {
  value: BooleanFormField;
}) => {
  return (
    <DefaultValueConfiguration
      value={value}
      noDefaultValue={ActionFormFieldTypeData.boolean({
        defaultValue: undefined,
      })}
      currentValue={ActionFormFieldTypeData.boolean({
        defaultValue: BooleanDefaultValue.currentValue({}),
      })}
    />
  );
};

// TODO: There's a lot of copy/pasting here, clean this up
const DateFormFieldConfiguration = ({ value }: { value: DateFormField }) => {
  const { operation, setValue } = useFormFieldTypeConfigContext();

  const handleChange = (value: "none" | "current_date" | "current_value") => {
    if (value === "none") {
      setValue(
        ActionFormFieldTypeData.date({
          defaultValue: undefined,
        })
      );
    } else if (value === "current_date") {
      setValue(
        ActionFormFieldTypeData.date({
          defaultValue: DateDefaultValue.currentDate({}),
        })
      );
    } else if (value === "current_value") {
      setValue(
        ActionFormFieldTypeData.date({
          defaultValue: DateDefaultValue.currentValue({}),
        })
      );
    }
  };

  const cardValue = !value.defaultValue
    ? "none"
    : DateDefaultValue.visit(value.defaultValue, {
        static: () => "none",
        currentDate: () => "current_date",
        currentValue: () => "current_value",
      });

  return (
    <>
      <Flex direction="column" gap="2">
        <FormFieldLabel name="default-value" label="Default Value" />
        <RadioCards.Root
          value={cardValue}
          onValueChange={handleChange}
          size="1"
        >
          <RadioCards.Item value="none">
            <Flex direction="column" width="100%">
              <Text weight="bold">None</Text>
              <Text size="1">No default value</Text>
            </Flex>
          </RadioCards.Item>
          <RadioCards.Item value="current_date">
            <Flex direction="column" width="100%">
              <Text weight="bold">Current Date</Text>
              <Text size="1">Automatically set to current date</Text>
            </Flex>
          </RadioCards.Item>
          {ActionOperation.isUpdate(operation) && (
            <RadioCards.Item value="current_value">
              <Flex direction="column" width="100%">
                <Text weight="bold">Current Value</Text>
                <Text size="1">Set to current value on the record</Text>
              </Flex>
            </RadioCards.Item>
          )}
        </RadioCards.Root>
      </Flex>
    </>
  );
};

// TODO: There's a lot of copy/pasting here, clean this up
const TimestampFormFieldConfiguration = ({
  value,
}: {
  value: TimestampFormField;
}) => {
  const { operation, setValue } = useFormFieldTypeConfigContext();

  const handleChange = (
    option: "none" | "current_timestamp" | "current_value"
  ) => {
    if (option === "none") {
      setValue(
        ActionFormFieldTypeData.timestamp({
          defaultValue: undefined,
        })
      );
    } else if (option === "current_timestamp") {
      setValue(
        ActionFormFieldTypeData.timestamp({
          defaultValue: TimestampDefaultValue.currentTimestamp({}),
        })
      );
    } else if (option === "current_value") {
      setValue(
        ActionFormFieldTypeData.timestamp({
          defaultValue: TimestampDefaultValue.currentValue({}),
        })
      );
    }
  };

  const cardValue = !value.defaultValue
    ? "none"
    : TimestampDefaultValue.visit(value.defaultValue, {
        static: () => "none",
        currentTimestamp: () => "current_timestamp",
        currentValue: () => "current_value",
      });

  return (
    <>
      <Flex direction="column" gap="2">
        <FormFieldLabel name="default-value" label="Default Value" />
        <RadioCards.Root
          value={cardValue}
          onValueChange={handleChange}
          size="1"
        >
          <RadioCards.Item value="none">
            <Flex direction="column" width="100%">
              <Text weight="bold">None</Text>
              <Text size="1">No default value</Text>
            </Flex>
          </RadioCards.Item>
          <RadioCards.Item value="current_timestamp">
            <Flex direction="column" width="100%">
              <Text weight="bold">Current Timestamp</Text>
              <Text size="1">Automatically set to current timestamp</Text>
            </Flex>
          </RadioCards.Item>
          {ActionOperation.isUpdate(operation) && (
            <RadioCards.Item value="current_value">
              <Flex direction="column" width="100%">
                <Text weight="bold">Current Value</Text>
                <Text size="1">Set to current value on the record</Text>
              </Flex>
            </RadioCards.Item>
          )}
        </RadioCards.Root>
      </Flex>
    </>
  );
};

// TODO: There's a lot of copy/pasting here, clean this up
const UserIdFormFieldConfiguration = ({
  value,
}: {
  value: UserIdFormField;
}) => {
  const { operation, setValue } = useFormFieldTypeConfigContext();

  const handleChange = (option: "none" | "current_user" | "current_value") => {
    if (option === "none") {
      setValue(
        ActionFormFieldTypeData.userId({
          defaultValue: undefined,
        })
      );
    } else if (option === "current_user") {
      setValue(
        ActionFormFieldTypeData.userId({
          defaultValue: UserIdDefaultValue.currentUser({}),
        })
      );
    } else if (option === "current_value") {
      setValue(
        ActionFormFieldTypeData.userId({
          defaultValue: UserIdDefaultValue.currentValue({}),
        })
      );
    }
  };

  const cardValue = !value.defaultValue
    ? "none"
    : UserIdDefaultValue.visit(value.defaultValue, {
        static: () => "none",
        currentUser: () => "current_user",
        currentValue: () => "current_value",
      });

  return (
    <>
      <Flex direction="column" gap="2">
        <FormFieldLabel name="default-value" label="Default Value" />
        <RadioCards.Root
          value={cardValue}
          onValueChange={handleChange}
          size="1"
        >
          <RadioCards.Item value="none">
            <Flex direction="column" width="100%">
              <Text weight="bold">None</Text>
              <Text size="1">No default value</Text>
            </Flex>
          </RadioCards.Item>
          <RadioCards.Item value="current_user">
            <Flex direction="column" width="100%">
              <Text weight="bold">Current User</Text>
              <Text size="1">Automatically set to current user</Text>
            </Flex>
          </RadioCards.Item>
          {ActionOperation.isUpdate(operation) && (
            <RadioCards.Item value="current_value">
              <Flex direction="column" width="100%">
                <Text weight="bold">Current Value</Text>
                <Text size="1">Set to current value on the record</Text>
              </Flex>
            </RadioCards.Item>
          )}
        </RadioCards.Root>
      </Flex>
    </>
  );
};

const LinkFormFieldConfiguration = ({ value }: { value: LinkFormField }) => {
  return (
    <DefaultValueConfiguration
      value={value}
      noDefaultValue={ActionFormFieldTypeData.link({
        defaultValue: undefined,
      })}
      currentValue={ActionFormFieldTypeData.link({
        defaultValue: LinkDefaultValue.currentValue({}),
      })}
    />
  );
};

const ValuesFormFieldConfiguration = ({
  value,
}: {
  value: ValuesFormField;
}) => {
  return (
    <DefaultValueConfiguration
      value={value}
      noDefaultValue={ActionFormFieldTypeData.values({
        defaultValue: undefined,
      })}
      currentValue={ActionFormFieldTypeData.values({
        defaultValue: ValuesDefaultValue.currentValue({}),
      })}
    />
  );
};

const FileFormFieldConfiguration = ({ value }: { value: FileFormField }) => {
  return (
    <DefaultValueConfiguration
      value={value}
      noDefaultValue={ActionFormFieldTypeData.file({
        defaultValue: undefined,
      })}
      currentValue={ActionFormFieldTypeData.file({
        defaultValue: FileDefaultValue.currentValue({}),
      })}
    />
  );
};

/**
 * Context for form field type configuration. Reduces some prop drilling.
 */
const FormFieldTypeConfigContext = createContext<{
  setValue: (value: ActionFormFieldTypeData) => void;
  operation: ActionOperation;
}>({
  setValue: () => {},
  operation: ActionOperation.create({}),
});

const useFormFieldTypeConfigContext = () => {
  return useContext(FormFieldTypeConfigContext);
};

/**
 * Handles boilerplate for common case of default value configuration. Reusable across multiple field types.
 */
const DefaultValueConfiguration = ({
  value,
  noDefaultValue,
  currentValue,
}: {
  value:
    | NumberFormField
    | BooleanFormField
    | TextFormField
    | DateFormField
    | TimestampFormField
    | UserIdFormField
    | LinkFormField
    | ValuesFormField
    | FileFormField;
  noDefaultValue: ActionFormFieldTypeData;
  currentValue: ActionFormFieldTypeData;
}) => {
  const { operation, setValue } = useFormFieldTypeConfigContext();

  const defaultValueOption = !value.defaultValue ? "none" : "current_value";

  const handleDefaultValueChange = (option: "none" | "current_value") => {
    if (option === "none") {
      setValue(noDefaultValue);
    } else {
      setValue(currentValue);
    }
  };

  return (
    ActionOperation.isUpdate(operation) && (
      <Flex direction="column" gap="2">
        <FormFieldLabel name="default-value" label="Default Value" />
        <RadioCards.Root
          value={defaultValueOption}
          onValueChange={handleDefaultValueChange}
          size="1"
        >
          <RadioCards.Item value="none">
            <Flex direction="column" width="100%">
              <Text weight="bold">None</Text>
              <Text size="1">No default value</Text>
            </Flex>
          </RadioCards.Item>
          <RadioCards.Item value="current_value">
            <Flex direction="column" width="100%">
              <Text weight="bold">Current Value</Text>
              <Text size="1">Set to current value on the record</Text>
            </Flex>
          </RadioCards.Item>
        </RadioCards.Root>
      </Flex>
    )
  );
};
import { Text } from "@radix-ui/themes";

export const ErrorValue = ({
  text = "Failed to display value",
}: {
  text?: string;
}) => (
  <Text style={{ fontStyle: "italic" }} color="gray">
    {text}
  </Text>
);

import {
  ActionFormFieldTypeData,
  BooleanDefaultValue,
  BooleanFormField,
  DateDefaultValue,
  DateFormField,
  FileDefaultValue,
  FileFormField,
  LinkDefaultValue,
  LinkFormField,
  NumberDefaultValue,
  NumberFormField,
  TextDefaultValue,
  TextFormField,
  TimestampDefaultValue,
  TimestampFormField,
  UserIdDefaultValue,
  UserIdFormField,
  ValuesDefaultValue,
  ValuesFormField,
} from "@/types/actionFormField";
import { Action, ActionOperation } from "@/types/actions";
import {
  LinkedRecordField,
  NumberRecordField,
  RecordFieldType,
  StatusRecordField,
  TextRecordField,
  ValuesRecordField,
} from "@/types/recordFields";
import { FileValue, RecordFieldValues } from "@/types/records";
import { RecordType } from "@/types/recordTypes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const recordFieldTypeToActionFormField = (
  type: RecordFieldType,
  operation: ActionOperation
): ActionFormFieldTypeData => {
  const shouldDefaultToCurrentValue = ActionOperation.isUpdate(operation);

  return RecordFieldType.visit<ActionFormFieldTypeData>(type, {
    number: (value: NumberRecordField) =>
      ActionFormFieldTypeData.number({
        defaultValue: shouldDefaultToCurrentValue
          ? NumberDefaultValue.currentValue({})
          : undefined,
      }),
    boolean: (value: {}) =>
      ActionFormFieldTypeData.boolean({
        defaultValue: shouldDefaultToCurrentValue
          ? BooleanDefaultValue.currentValue({})
          : undefined,
      }),
    text: (value: TextRecordField) =>
      ActionFormFieldTypeData.text({
        defaultValue: shouldDefaultToCurrentValue
          ? TextDefaultValue.currentValue({})
          : undefined,
        multiline: false,
      }),
    values: (value: ValuesRecordField) =>
      ActionFormFieldTypeData.values({
        defaultValue: shouldDefaultToCurrentValue
          ? ValuesDefaultValue.currentValue({})
          : undefined,
      }),
    link: (value: LinkedRecordField) =>
      ActionFormFieldTypeData.link({
        defaultValue: shouldDefaultToCurrentValue
          ? LinkDefaultValue.currentValue({})
          : undefined,
      }),
    date: (value: {}) =>
      ActionFormFieldTypeData.date({
        defaultValue: shouldDefaultToCurrentValue
          ? DateDefaultValue.currentValue({})
          : undefined,
      }),
    timestamp: (value: {}) =>
      ActionFormFieldTypeData.timestamp({
        defaultValue: shouldDefaultToCurrentValue
          ? TimestampDefaultValue.currentValue({})
          : undefined,
      }),
    file: (value: {}) =>
      ActionFormFieldTypeData.file({
        defaultValue: undefined,
      }),
    userId: (value: {}) =>
      ActionFormFieldTypeData.userId({
        defaultValue: shouldDefaultToCurrentValue
          ? UserIdDefaultValue.currentValue({})
          : undefined,
      }),
    id: (value: {}) => {
      throw new Error("ID field is not supported");
    },
    status: (value: StatusRecordField) => {
      throw new Error("Status field is not supported");
    },
  });
};

export const getActionDefaultValues = (
  action: Action,
  recordType: RecordType,
  currentUserId: string,
  selectedRecord?: RecordFieldValues
): { [fieldId: string]: any } => {
  const result: { [fieldId: string]: any } = {};
  for (const field of action.form.fields) {
    const maybeCurrentValue = selectedRecord?.[field.recordFieldId];
    const recordField = recordType.fields.find(
      (f) => f.id === field.recordFieldId
    );

    const defaultValue = ActionFormFieldTypeData.visit(field.type, {
      number: (value: NumberFormField) => {
        return (
          value.defaultValue &&
          NumberDefaultValue.visit(value.defaultValue, {
            static: ({ value }) => value,
            currentValue: () => maybeCurrentValue,
          })
        );
      },
      boolean: (value: BooleanFormField) => {
        return (
          value.defaultValue &&
          BooleanDefaultValue.visit(value.defaultValue, {
            static: ({ value }) => value,
            currentValue: () => maybeCurrentValue,
          })
        );
      },
      text: (value: TextFormField) => {
        return (
          value.defaultValue &&
          TextDefaultValue.visit(value.defaultValue, {
            static: ({ value }) => value,
            currentValue: () => maybeCurrentValue,
          })
        );
      },
      date: (value: DateFormField) => {
        return (
          value.defaultValue &&
          DateDefaultValue.visit(value.defaultValue, {
            static: ({ value }) => value,
            currentDate: () => dayjs().format("YYYY-MM-DD"),
            // TODO
            currentValue: () => maybeCurrentValue,
          })
        );
      },
      timestamp: (value: TimestampFormField) => {
        return (
          value.defaultValue &&
          TimestampDefaultValue.visit(value.defaultValue, {
            static: ({ value }) => value,
            // Generate timestamp in local time zone for use in input components
            // We will convert to UTC when saving
            currentTimestamp: () => dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            // TODO
            currentValue: () =>
              maybeCurrentValue
                ? dayjs(maybeCurrentValue).format("YYYY-MM-DDTHH:mm:ss")
                : undefined,
          })
        );
      },
      link: (value: LinkFormField) => {
        return (
          value.defaultValue &&
          LinkDefaultValue.visit(value.defaultValue, {
            static: ({ recordId }) => recordId,
            currentValue: () => maybeCurrentValue,
          })
        );
      },
      values: (value: ValuesFormField) => {
        return (
          value.defaultValue &&
          ValuesDefaultValue.visit(value.defaultValue, {
            // TODO: Handle multiple values more cleanly
            static: ({ value }) => (value.length > 0 ? value[0] : undefined),
            currentValue: () => maybeCurrentValue,
          })
        );
      },
      userId: (value: UserIdFormField) => {
        const recordFieldType = recordField?.type;
        if (recordFieldType && !RecordFieldType.isUserId(recordFieldType)) {
          return undefined;
        }
        const allowMultiple = recordFieldType?.userId.allowMultiple;
        return (
          value.defaultValue &&
          UserIdDefaultValue.visit(value.defaultValue, {
            // TODO: Handle multiple values more cleanly
            static: ({ value }) => (value.length > 0 ? value[0] : undefined),
            currentUser: () =>
              allowMultiple ? [currentUserId] : currentUserId,
            currentValue: () => maybeCurrentValue,
          })
        );
      },
      file: (value: FileFormField) => {
        // If there's a type mismatch, default to no value
        const recordFieldType = recordField?.type;
        if (recordFieldType && !RecordFieldType.isFile(recordFieldType)) {
          return undefined;
        }
        // If default value config is not set, default to no value
        if (
          !value.defaultValue ||
          !FileDefaultValue.isCurrentValue(value.defaultValue)
        ) {
          return undefined;
        }
        // If there's no current value, default to no value
        if (!maybeCurrentValue) {
          return undefined;
        }

        const allowMultiple = recordFieldType?.file.allowMultiple;
        if (allowMultiple) {
          return maybeCurrentValue.map((filePath: string) =>
            FileValue.existing({ filePath })
          );
        }
        return [FileValue.existing({ filePath: maybeCurrentValue })];
      },
    });

    if (defaultValue != null) {
      result[field.id] = defaultValue;
    }
  }
  return result;
};

export const postProcessActionValues = (
  action: Action,
  recordType: RecordType,
  values: { [fieldId: string]: any }
) => {
  const result: { [fieldId: string]: any } = {};
  for (const [fieldId, value] of Object.entries(values)) {
    const field = action.form.fields.find((f) => f.id === fieldId);
    if (!field) {
      throw new Error(`Field ${fieldId} not found in action configuration`);
    }
    // TODO: Will need to update this if we add multiple timestamps
    if (ActionFormFieldTypeData.isTimestamp(field.type)) {
      // Convert to UTC
      result[fieldId] = dayjs(value).utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
    } else {
      result[fieldId] = value;
    }
  }
  return result;
};

import { useDeleteRevision } from "@/state/mutations/documents/deleteRevision";
import { Document } from "@/state/queries/documents";
import { AlertDialog } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { PermissionedButton } from "../PermissionedButton";
import MeridianAlertDialog from "../alert-dialogs";

export const DeleteRevisionAction: React.FC<{
  document: Document;
  revisionId: string;
  onSuccess?: () => void;
}> = ({ document, revisionId, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: deleteRevision,
    isPending,
    error,
    reset,
  } = useDeleteRevision();

  const onSubmit = () => {
    deleteRevision(
      { id: revisionId, tenantId: document.tenantId },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.documents"
          variant="soft"
          color="gray"
        >
          <IconTrash />
          Delete
        </PermissionedButton>
      </AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Delete revision?"
          description="This draft revision and its associated files will be deleted. This action cannot be undone."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Reject revision",
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

import PageTitle from "@/components/PageTitle";
import { AddProcessAction } from "@/components/processes/AddProcessAction";
import { SearchFilter } from "@/components/SearchFilter";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import {
  DefaultStatusRenderer,
  UserRenderer,
} from "@/components/tables/renderers";
import { Process, useProcesses } from "@/state/queries/processes";
import { Flex } from "@radix-ui/themes";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute("/_app/$tenantSlug/processes/")({
  beforeLoad: () => {
    return {
      getTitle: () => "",
    };
  },
  component: ProcessesIndex,
});

function ProcessesIndex() {
  const [filter, setFilter] = useState("");

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title="Processes"
        description="Define processes to control business activities and map to compliance requirements."
      />
      <Flex justify="between" align="center" py="2">
        <SearchFilter
          filter={filter}
          setFilter={setFilter}
          placeholder="Search processes"
        />
        <AddProcessAction />
      </Flex>
      <ProcessesTable filterText={filter} />
    </Flex>
  );
}

function ProcessesTable({ filterText }: { filterText: string }) {
  const navigate = useNavigate();
  const { tenantSlug } = Route.useParams();
  const { data: processes, isLoading } = useProcesses();
  const columns: ColDef<Process>[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      cellRenderer: (props: ICellRendererParams) => (
        <Link
          to="/$tenantSlug/processes/$processId"
          params={{
            tenantSlug,
            processId: props.data.id,
          }}
        >
          {props.data.name}
        </Link>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      cellRenderer: DefaultStatusRenderer,
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "ownedBy",
      headerName: "Owner",
      cellRenderer: UserRenderer,
      width: 180,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];

  return (
    <BaseAgGrid<Process>
      loading={isLoading}
      rowData={processes}
      columnDefs={columns}
      quickFilterText={filterText}
      selectableRows
      onRowClicked={(rowClick) => {
        if (rowClick.data) {
          navigate({
            to: "/$tenantSlug/processes/$processId",
            params: { tenantSlug, processId: rowClick.data.id },
          });
        }
      }}
      autoSizeStrategy={{
        type: "fitCellContents",
      }}
    />
  );
}

import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useCreateDocumentRevision } from "@/state/mutations/documents/createDocumentRevision";
import { Document } from "@/state/queries/documents";
import { Dialog } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useState } from "react";
import { z } from "zod";
import MeridianDialog from "../dialogs";

interface CreateDocumentRevisionInputs {
  file: File | null;
}

// TODO dedupe this
const allowedMimeTypes = new Set([
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
]);

export const CreateDocumentRevisionAction: React.FC<{ document: Document }> = ({
  document,
}) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: createDocumentRevision,
    error: createDocumentRevisionError,
    isPending,
    reset: resetMutation,
  } = useCreateDocumentRevision();

  const form = useForm<CreateDocumentRevisionInputs, any>({
    defaultValues: {
      file: null,
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        file: z
          .instanceof(File, { message: "File is required" })
          .refine((file) => {
            if (!file) return true;
            if (allowedMimeTypes.has(file.type)) return true;
            return false;
          }, "Allowed formats: pdf, doc(x), xls(x), ppt(x)"),
      }),
    },
    onSubmit: ({ value }) => {
      createDocumentRevision(
        {
          ...value,
          document,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    resetMutation();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton permission="write.documents" variant="soft">
          <IconPlus />
          Create revision
        </PermissionedButton>
      </Dialog.Trigger>

      <MeridianDialog.Content>
        <MeridianDialog.Header
          title="Create revision"
          description="Upload a new revision of this document. The new revision will not be released until it goes through the approval workflow."
        />

        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Form.Fields mt="2">
              <Form.FileUpload<CreateDocumentRevisionInputs>
                reactFormField={{ form, name: "file" }}
                fieldProps={{
                  label: "Upload file",
                  required: true,
                }}
                accept={Array.from(allowedMimeTypes).join(",")}
              />
            </Form.Fields>
            <Form.Footer
              form={form}
              error={createDocumentRevisionError}
              isPending={isPending}
              buttonText="Create revision"
              errorPrefix="Failed to create new revision: "
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

import Form from "@/components/forms";
import { useAddControl } from "@/state/mutations/processes/addControl";
import { ProcessRevision } from "@/state/queries/processes";
import { Dialog } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { PropsWithChildren, useState } from "react";
import MeridianDialog from "../dialogs";

interface AddControlActionProps {
  processId: string;
  revision: ProcessRevision;
}

interface AddControlFormInputs {
  title: string;
  language: string;
}

export const AddControlAction: React.FC<
  PropsWithChildren<AddControlActionProps>
> = ({ processId, revision, children }) => {
  const [open, setOpen] = useState(false);
  const { mutate: addControl, error, reset, isPending } = useAddControl();

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    reset();
  };

  const form = useForm({
    defaultValues: {
      title: "",
      language: "",
    },
    onSubmit: ({ value }) => {
      addControl(
        {
          processId,
          processRevisionId: revision.id,
          title: value.title,
          language: value.language,
          mappings: [],
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <MeridianDialog.Content>
        <MeridianDialog.Header title="Add control" />
        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Form.Fields>
              <Form.TextField<AddControlFormInputs>
                reactFormField={{ form, name: "title" }}
                fieldProps={{
                  label: "Title",
                  required: true,
                }}
              />

              <Form.TextArea<AddControlFormInputs>
                reactFormField={{ form, name: "language" }}
                fieldProps={{
                  label: "Language",
                  required: true,
                }}
                rows={12}
              />
            </Form.Fields>

            <Form.Footer
              form={form}
              error={error}
              isPending={isPending}
              buttonText="Add control"
              errorPrefix="Failed to add control: "
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

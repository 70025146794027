import ReactMarkdown from "react-markdown";
import remarkParse from "remark-parse";
import { unified } from "unified";
import { Node } from "unist";
import { visit } from "unist-util-visit";
import { AddedText, RemovedText, TextDiff } from "../TextDiff";

interface MarkdownNode extends Node {
  type: string;
  value?: string;
  children?: MarkdownNode[];
  ordered?: boolean;
  spread?: boolean;
}

export function ControlLanguageDiff({
  beforeValue,
  afterValue,
}: {
  beforeValue: string;
  afterValue: string;
}) {
  const beforeAST = unified()
    .use(remarkParse)
    .parse(beforeValue) as MarkdownNode;
  const afterAST = unified().use(remarkParse).parse(afterValue) as MarkdownNode;

  function getOriginalContent(node: MarkdownNode, value: string): string {
    return value.slice(
      node.position?.start.offset || 0,
      node.position?.end.offset || 0
    );
  }

  function getNodeText(node: MarkdownNode): string {
    let text = "";
    visit(node, "text", (node: any) => {
      text += node.value || "";
    });
    return text;
  }

  function renderNode(
    beforeNode: MarkdownNode | null,
    afterNode: MarkdownNode | null
  ): JSX.Element | null {
    // Handle added nodes
    const beforeNodeIsEmptyRoot =
      beforeNode?.type === "root" && beforeNode.children?.length === 0;
    if (afterNode && (!beforeNode || beforeNodeIsEmptyRoot)) {
      const content = afterNode.position
        ? getOriginalContent(afterNode, afterValue)
        : getNodeText(afterNode);

      return (
        <AddedText as="div">
          <ReactMarkdown>{content}</ReactMarkdown>
        </AddedText>
      );
    }

    // Handle removed nodes
    const afterNodeIsEmptyRoot =
      afterNode?.type === "root" && afterNode.children?.length === 0;
    if (beforeNode && (!afterNode || afterNodeIsEmptyRoot)) {
      const content = beforeNode.position
        ? getOriginalContent(beforeNode, beforeValue)
        : getNodeText(beforeNode);
      return (
        <RemovedText as="div">
          <ReactMarkdown>{content}</ReactMarkdown>
        </RemovedText>
      );
    }

    if (!beforeNode || !afterNode) return null;

    switch (beforeNode.type) {
      case "root":
        const maxLength = Math.max(
          beforeNode.children?.length || 0,
          afterNode.children?.length || 0
        );

        return (
          <>
            {Array.from({ length: maxLength }).map((_, index) =>
              renderNode(
                beforeNode.children?.[index] || null,
                afterNode.children?.[index] || null
              )
            )}
          </>
        );

      case "paragraph":
        return (
          <p>
            <TextDiff
              beforeText={getNodeText(beforeNode)}
              afterText={getNodeText(afterNode)}
            />
          </p>
        );

      case "list":
        const ListTag = beforeNode.ordered ? "ol" : "ul";
        const beforeItems = beforeNode.children || [];
        const afterItems = afterNode.children || [];

        const beforeTexts = beforeItems.map((item) => getNodeText(item));
        const afterTexts = afterItems.map((item) => getNodeText(item));

        const itemMapping = new Map<number, number>();

        beforeTexts.forEach((beforeText, beforeIndex) => {
          const bestMatchIndex = afterTexts.findIndex(
            (afterText, afterIndex) =>
              !Array.from(itemMapping.values()).includes(afterIndex) &&
              (afterText === beforeText ||
                afterText.includes(beforeText) ||
                beforeText.includes(afterText))
          );

          if (bestMatchIndex !== -1) {
            itemMapping.set(beforeIndex, bestMatchIndex);
          }
        });

        return (
          <ListTag>
            {beforeItems.map((beforeItem, beforeIndex) => {
              const afterIndex = itemMapping.get(beforeIndex);
              // If no match found, this item was removed
              if (afterIndex === undefined) {
                return (
                  <li
                    style={{
                      backgroundColor: "var(--red-3)",
                      textDecoration: "line-through",
                      borderRadius: "var(--radius-1)",
                    }}
                  >
                    <TextDiff
                      beforeText={getNodeText(beforeItem)}
                      afterText={""}
                    />
                  </li>
                );
              }
              return renderNode(beforeItem, afterItems[afterIndex]);
            })}
            {afterItems.map((afterItem, afterIndex) => {
              // If this after item wasn't matched to any before item, it's new
              if (!Array.from(itemMapping.values()).includes(afterIndex)) {
                return (
                  <li
                    style={{
                      backgroundColor: "var(--green-3)",
                      borderRadius: "var(--radius-1)",
                    }}
                  >
                    <TextDiff
                      beforeText={""}
                      afterText={getNodeText(afterItem)}
                    />
                  </li>
                );
              }
              return null;
            })}
          </ListTag>
        );

      case "listItem":
        return (
          <li>
            <TextDiff
              beforeText={getNodeText(beforeNode)}
              afterText={getNodeText(afterNode)}
            />
          </li>
        );

      case "heading":
        return <ReactMarkdown>{getNodeText(afterNode)}</ReactMarkdown>;

      default:
        return <ReactMarkdown>{getNodeText(afterNode)}</ReactMarkdown>;
    }
  }

  return <>{renderNode(beforeAST, afterAST)}</>;
}

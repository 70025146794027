import { PermissionedButton } from "@/components/PermissionedButton";
import { useRemoveControl } from "@/state/mutations/processes/removeControl";
import { AlertDialog } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import MeridianAlertDialog from "../alert-dialogs";

interface RemoveControlProps {
  controlRevisionId: string;
}

export const RemoveControlAction: React.FC<RemoveControlProps> = ({
  controlRevisionId,
}) => {
  const [open, setOpen] = useState(false);
  const { mutate: removeControl, isPending, error, reset } = useRemoveControl();

  const onSubmit = () => {
    removeControl(
      { controlRevisionId },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
          size="1"
          iconButton
        >
          <IconTrash />
        </PermissionedButton>
      </AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Remove control?"
          description="This control will be removed from the process and the associated language will be deleted."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Remove control",
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

import { AlertDialog, Button, ButtonProps, Flex } from "@radix-ui/themes";
import React from "react";

interface SubmitButtonProps extends ButtonProps {
  label: string;
}

interface MeridianAlertDialogFooterProps {
  submitButton: SubmitButtonProps;
  onConfirm: () => void;
  cancelButtonLabel?: string;
  isPending?: boolean;
}

export const MeridianAlertDialogFooter: React.FC<
  MeridianAlertDialogFooterProps
> = ({
  submitButton: { label, color = "red", ...submitButtonProps },
  onConfirm,
  cancelButtonLabel = "Cancel",
  isPending,
}) => {
  return (
    <Flex gap="3" justify="end" mt="4">
      <AlertDialog.Cancel>
        <Button size="3" variant="soft" color="gray">
          {cancelButtonLabel}
        </Button>
      </AlertDialog.Cancel>
      <Button
        size="3"
        variant="solid"
        color={color}
        onClick={onConfirm}
        disabled={isPending}
        {...submitButtonProps}
      >
        {label}
      </Button>
    </Flex>
  );
};

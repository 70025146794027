import { SearchFilter } from "@/components/SearchFilter";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { TextRenderer } from "@/components/tables/renderers";
import { TenantUser, useTenantUsers } from "@/state/queries/tenantUsers";
import { Flex } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";
import { ColDef } from "ag-grid-community";
import { capitalize } from "lodash-es";
import { useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/members/users"
)({
  beforeLoad: () => ({ getTitle: () => "Users" }),
  component: Users,
});

function Users() {
  const { data: users } = useTenantUsers();
  const [filter, setFilter] = useState("");

  const columnDefs: ColDef<TenantUser>[] = [
    {
      field: "fullName",
      headerName: "Name",
      cellRenderer: TextRenderer,
      flex: 1,
    },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "role",
      valueFormatter: ({ value }) => capitalize(value),
      headerName: "Role",
      flex: 1,
    },
  ];

  return (
    <>
      <Flex justify="between" align="center" py="2">
        <SearchFilter
          filter={filter}
          setFilter={setFilter}
          placeholder="Search users"
        />
      </Flex>
      <BaseAgGrid
        columnDefs={columnDefs}
        rowData={users ? Object.values(users) : []}
        quickFilterText={filter}
      />
    </>
  );
}

import { Flex, FlexProps, Heading, Text } from "@radix-ui/themes";
import React from "react";

interface NonIdealStateProps {
  icon: React.ReactNode;
  title?: string;
  description?: string;
  action?: React.ReactNode;
  size?: "small" | "medium";
  flexProps?: FlexProps;
}

const NonIdealState: React.FC<NonIdealStateProps> = ({
  icon,
  title,
  description,
  action,
  size,
  flexProps,
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      width="100%"
      height="100%"
      gap="3"
      {...flexProps}
    >
      {React.cloneElement(icon as React.ReactElement, {
        color: "var(--gray-8)",
        stroke: 1.5,
        style: {
          width: size === "small" ? "40px" : "60px",
          height: size === "small" ? "40px" : "60px",
        },
      })}
      {title && (
        <Heading size={size === "small" ? "2" : "4"} color="gray">
          {title}
        </Heading>
      )}
      {description && (
        <Text
          size={size === "small" ? "2" : "3"}
          color="gray"
          style={{ maxWidth: "400px", textAlign: "center" }}
        >
          {description}
        </Text>
      )}
      {action}
    </Flex>
  );
};

export default NonIdealState;

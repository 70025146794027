import { EmptyValue } from "@/components/EmptyValue";
import NonIdealState from "@/components/NonIdealState";
import {
  RecordFieldsDataList,
  RecordFieldsDataListDiff,
} from "@/components/records/RecordFieldsDataList";
import RelativeTime from "@/components/RelativeTime";
import { StatusBadge } from "@/components/statuses/StatusBadge";
import User from "@/components/User";
import { useStatuses } from "@/hooks/useStatuses";
import {
  RecordHistoryItem,
  useRecordHistory,
} from "@/state/queries/recordHistory";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { STATUS_RECORD_FIELD } from "@/types/recordFieldConstants";
import * as Collapsible from "@radix-ui/react-collapsible";
import { Button, Flex, Heading, Spinner, Text } from "@radix-ui/themes";
import {
  IconArrowRight,
  IconChevronDown,
  IconChevronUp,
  IconListDetails,
} from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/records/$recordTypeId/$recordId/history"
)({
  beforeLoad: () => ({ getTitle: () => "History" }),
  component: RecordHistory,
});

function RecordHistory() {
  const { recordId, recordTypeId } = Route.useParams();
  const { data: recordHistory } = useRecordHistory(recordId);

  const sortedRecordHistory = useMemo(() => {
    return (
      recordHistory?.sort((a, b) => {
        if (dayjs(a.timestamp).isBefore(dayjs(b.timestamp))) {
          return 1;
        }
        return -1;
      }) ?? []
    );
  }, [recordHistory]);

  if (!recordHistory) {
    return <NonIdealState icon={<Spinner />} title="Loading history" />;
  }

  return (
    <Flex direction="column" gap="4" mt="4">
      <StatusHistory
        sortedItems={sortedRecordHistory}
        recordTypeId={recordTypeId}
      />

      <Heading size="4">All changes</Heading>

      {recordHistory.length === 0 && (
        <NonIdealState icon={<IconListDetails />} title="No history" />
      )}
      {sortedRecordHistory.map((item) => (
        <RecordHistoryItemComponent key={item.eventId} item={item} />
      ))}
    </Flex>
  );
}

function StatusHistory({
  sortedItems,
  recordTypeId,
}: {
  sortedItems: RecordHistoryItem[];
  recordTypeId: string;
}) {
  const statuses = useStatuses(recordTypeId);

  const statusChanges = [...sortedItems].reverse().filter((item) => {
    if (item.operation === "delete") {
      return false;
    } else if (item.operation === "create") {
      return STATUS_RECORD_FIELD.id in item.updatedFieldValues;
    } else {
      return (
        STATUS_RECORD_FIELD.id in item.updatedFieldValues &&
        STATUS_RECORD_FIELD.id in item.previousFieldValues &&
        item.updatedFieldValues[STATUS_RECORD_FIELD.id] !==
          item.previousFieldValues[STATUS_RECORD_FIELD.id]
      );
    }
  });

  if (statusChanges.length === 0) {
    return null;
  }

  return (
    <>
      <Heading size="4">Status changes</Heading>
      <Flex direction="row" gap="4">
        {statusChanges.map((item, index) => {
          const status = statuses.find(
            (s) => s.value === item.updatedFieldValues[STATUS_RECORD_FIELD.id]
          );
          if (!status) {
            return null;
          }
          return (
            <Flex gap="4" align="center">
              <Flex direction="column" gap="3" align="center">
                <StatusBadge status={status} />
                <Flex direction="column" gap="1" align="center">
                  <Text size="1">
                    <User
                      userId={item.userId}
                      hoverOpenDelay={800}
                      displayOptions={{ name: true, avatar: false }}
                    />
                  </Text>
                  <Text size="1">
                    <RelativeTime timestamp={item.timestamp} />
                  </Text>
                </Flex>
              </Flex>
              {index < statusChanges.length - 1 && <IconArrowRight />}
            </Flex>
          );
        })}
      </Flex>
    </>
  );
}

function RecordHistoryItemComponent({ item }: { item: RecordHistoryItem }) {
  const [isOpen, setIsOpen] = useState(false);
  const actionDescription =
    item.operation === "create"
      ? "created"
      : item.operation === "update"
        ? "updated"
        : "deleted";
  return (
    <Flex direction="column" gap="1">
      <Flex align="center" gap="1">
        <Text weight="bold">
          <User userId={item.userId} hoverOpenDelay={800} />
        </Text>
        <Text>{actionDescription} record</Text>
        <Text weight="bold">
          <RelativeTime timestamp={item.timestamp} />
        </Text>
      </Flex>
      {item.operation !== "delete" && (
        <Flex direction="column" ml="7" pt="2">
          <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
            <Collapsible.Trigger asChild>
              <Button variant="ghost" color="gray" size="1">
                Show changes
                {isOpen ? <IconChevronUp /> : <IconChevronDown />}
              </Button>
            </Collapsible.Trigger>
            <Collapsible.Content>
              <RecordHistoryItemFields item={item} />
            </Collapsible.Content>
          </Collapsible.Root>
        </Flex>
      )}
    </Flex>
  );
}

function RecordHistoryItemFields({ item }: { item: RecordHistoryItem }) {
  const { data: recordType } = useRecordTypeById(item.recordTypeId);
  if (!recordType) {
    return null;
  }

  const changedFields = Object.keys(item.updatedFieldValues).filter(
    (fieldId) =>
      JSON.stringify(item.previousFieldValues?.[fieldId]) !==
      JSON.stringify(item.updatedFieldValues[fieldId])
  );

  if (changedFields.length === 0) {
    return <EmptyValue text="No fields changed" />;
  }

  if (item.operation === "update") {
    return (
      <RecordFieldsDataListDiff
        recordType={recordType}
        beforeRecord={item.previousFieldValues}
        afterRecord={item.updatedFieldValues}
        displayOptions={{
          fieldsToShow: changedFields,
        }}
      />
    );
  }

  return (
    <RecordFieldsDataList
      recordType={recordType}
      record={item.updatedFieldValues}
      dataListProps={{ orientation: "vertical" }}
      displayOptions={{ itemDisplayStyle: "new" }}
    />
  );
}

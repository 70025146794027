import { Dialog } from "@radix-ui/themes";
import React from "react";

interface MeridianDialogContentProps extends Dialog.ContentProps {
  children: React.ReactNode;
  size?: Dialog.ContentProps["size"];
  maxWidth?: Dialog.ContentProps["maxWidth"];
}

export const MeridianDialogContent: React.FC<MeridianDialogContentProps> = ({
  children,
  size = "2",
  maxWidth = "600px",
  ...props
}) => {
  return (
    <Dialog.Content size={size} maxWidth={maxWidth} {...props}>
      {children}
    </Dialog.Content>
  );
};

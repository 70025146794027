import { useDeleteComplianceMapping } from "@/state/mutations/processes/complianceMappings";
import { ProcessControl } from "@/state/queries/processes";
import { THEME_COLOR } from "@/theme";
import { AlertDialog } from "@radix-ui/themes";
import { PropsWithChildren, useState } from "react";
import MeridianAlertDialog from "../alert-dialogs";

export const RemoveComplianceMappingAction: React.FC<
  PropsWithChildren<{
    control: ProcessControl;
    frameworkId: string;
    clause: string;
    onSuccess?: () => void;
  }>
> = ({ control, frameworkId, clause, onSuccess, children }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: deleteMapping,
    isPending,
    error,
    reset,
  } = useDeleteComplianceMapping();

  const onSubmit = () => {
    deleteMapping(
      { controlId: control.controlId, frameworkId, clause },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>{children}</AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Remove compliance mapping?"
          description="The mapping to this compliance requirement will be removed from this control."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Remove mapping",
            color: THEME_COLOR,
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

import { useTenantContext } from "@/contexts/TenantContext";
import { Process, ProcessRevision } from "@/state/queries/processes";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface CreateProcessRevisionInput {
  process: Process;
  latestRevision: ProcessRevision;
}

const createProcessRevision = async (input: CreateProcessRevisionInput) => {
  const { data: processRevision, error: processRevisionError } = await supabase
    .from("process_revisions")
    .insert({
      process_id: input.process.id,
      tenant_id: input.process.tenantId,
    })
    .select("id")
    .single();

  if (processRevisionError) {
    throw processRevisionError;
  }

  // Duplicate all existing control revisions under the new process revision
  const { error: controlRevisionsError } = await supabase
    .from("control_revisions")
    .insert(
      input.latestRevision.controls.map((c) => ({
        process_revision_id: processRevision.id,
        tenant_id: input.process.tenantId,
        title: c.title,
        language: c.language,
        control_id: c.controlId,
      }))
    )
    .select("id");

  if (controlRevisionsError) {
    // Attempt to clean up the process revision if the control revision creation fails
    // Note: This is hacky, the write should be transactional instead
    await supabase
      .from("process_revisions")
      .delete()
      .eq("id", processRevision.id)
      .eq("tenant_id", input.process.tenantId);
    throw controlRevisionsError;
  }

  return processRevision.id;
};

export const useCreateProcessRevision = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: CreateProcessRevisionInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return createProcessRevision(input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

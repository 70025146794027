import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { LinkType } from "@/types/linkTypes";
import { RecordField } from "@/types/recordFields";
import { RecordType } from "@/types/recordTypes";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const fetchRecordAndLinkTypes = async (
  tenantId: string
): Promise<{ recordTypes: RecordType[]; linkTypes: LinkType[] }> => {
  const [recordTypesResult] = await Promise.all([
    supabase.from("record_types").select("*").eq("tenant_id", tenantId),
    // supabase.from("link_types").select("*").eq("tenant_id", tenantId),
  ]);

  if (recordTypesResult.error) {
    throw recordTypesResult.error;
  }

  const recordTypes = (recordTypesResult.data || []).map((rt) => ({
    id: rt.id,
    processId: rt.process_id,
    key: rt.key,
    name: rt.name,
    pluralName: rt.plural_name,
    description: rt.description ?? undefined,
    icon: "",
    // icon: <IconListDetails />,
    source: rt.source,
    // TODO add validation here
    fields: rt.fields as unknown as RecordField[],
    titleFieldId: rt.title_field_id ?? undefined,
  }));

  // TODO Bring back link types - not needed for now
  // const linkTypes = (linkTypesResult.data || []).map((lt) => ({
  //   id: lt.id,
  //   processId: lt.process_id,
  //   name: lt.name,
  //   pluralName: lt.plural_name,
  //   description: lt.description ?? undefined,
  //   tenantId: lt.tenant_id,
  //   createdAt: lt.created_at,
  //   updatedAt: lt.updated_at,
  //   definition: lt.definition as any,
  // }));

  return { recordTypes, linkTypes: [] };
};

export const recordAndLinkTypesQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["recordAndLinkTypes", tenantId],
    queryFn: () => fetchRecordAndLinkTypes(tenantId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  });

export const useRecordAndLinkTypes = () => {
  const { tenant } = useTenantContext();
  return useQuery(recordAndLinkTypesQueryOptions(tenant.tenantId));
};

export const useRecordAndLinkTypesByProcess = (processId: string) => {
  const { data: recordTypesAndLinks, ...rest } = useRecordAndLinkTypes();

  // Use useMemo to prevent creating a new array on every render
  const filteredRecordTypes = useMemo(
    () =>
      recordTypesAndLinks?.recordTypes.filter(
        (rt) => rt.processId === processId
      ),
    [recordTypesAndLinks?.recordTypes, processId]
  );

  return {
    ...rest,
    data: filteredRecordTypes,
  };
};

export const useRecordTypeById = (recordTypeId: string) => {
  const { data: recordTypesAndLinks, ...rest } = useRecordAndLinkTypes();
  return {
    ...rest,
    data: recordTypesAndLinks?.recordTypes.find((rt) => rt.id === recordTypeId),
  };
};


export const useRecordTypesByProcess = (processId: string) => {
  const { data: recordTypesAndLinks, ...rest } = useRecordAndLinkTypes();
  return {
    ...rest,
    data: recordTypesAndLinks?.recordTypes.filter(
      (rt) => rt.processId === processId
    ),
  };
};

import { PermissionedButton } from "@/components/PermissionedButton";
import { useDeleteRecordFields } from "@/state/mutations/record-types/deleteRecordFields";
import { RecordField } from "@/types/recordFields";
import { AlertDialog } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import MeridianAlertDialog from "../alert-dialogs";

export const DeleteFieldsAction: React.FC<{
  recordTypeId: string;
  fields: RecordField[];
  onDelete?: () => void;
}> = ({ recordTypeId, fields, onDelete }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: deleteRecordFields,
    isPending,
    error,
    reset,
  } = useDeleteRecordFields();

  const onSubmit = () => {
    deleteRecordFields(
      {
        recordTypeId,
        fieldIds: fields.map((field) => field.id),
      },
      {
        onSuccess: () => {
          onDelete?.();
          setOpen(false);
        },
      }
    );
  };

  const title = `Delete ${fields.length > 1 ? fields.length : ""} field${fields.length === 1 ? "" : "s"}`;

  return (
    <AlertDialog.Root
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        if (!isOpen) reset();
      }}
    >
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
        >
          <IconTrash />
          {title}
        </PermissionedButton>
      </AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Delete fields?"
          description="These fields will be permanently deleted from this record type, including any stored field values."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: title,
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

import { useStatuses } from "@/hooks/useStatuses";
import { fetchDocumentFile } from "@/state/queries/documentFile";
import { fetchRecordFile } from "@/state/queries/recordFile";
import { RecordField, RecordFieldType } from "@/types/recordFields";
import { RecordType } from "@/types/recordTypes";
import { Badge, Flex, Link, Text } from "@radix-ui/themes";
import { IconExternalLink } from "@tabler/icons-react";
import { ICellRendererParams } from "ag-grid-community";
import { DefaultStatusBadge } from "../DefaultStatusBadge";
import { EmptyValue } from "../EmptyValue";
import { ErrorValue } from "../ErrorValue";
import FormattedDate from "../FormattedDate";
import { RecordTypeLabel } from "../record-types/RecordTypeLabel";
import { RecordLink } from "../records/RecordLink";
import RelativeTime from "../RelativeTime";
import { StatusBadge } from "../statuses/StatusBadge";
import { isValidFileLink, StorageFileLink } from "../StorageFileLink";
import User from "../User";

export const getRendererForFieldType = (type: RecordField["type"]) => {
  return RecordFieldType.visit(type, {
    id: () => TextRenderer,
    status: () => StatusRenderer,

    text: () => TextRenderer,
    number: () => TextRenderer,
    date: () => TextRenderer,
    timestamp: () => TimestampRenderer,
    boolean: () => TextRenderer,

    values: ({ allowMultiple }) =>
      allowMultiple ? BadgeListRenderer : BadgeRenderer,
    userId: ({ allowMultiple }) =>
      allowMultiple ? UserListRenderer : UserRenderer,
    link: ({ allowMultiple }) =>
      allowMultiple ? RecordLinkListRenderer : RecordLinkRenderer,
    file: ({ allowMultiple }) =>
      allowMultiple ? RecordFileLinkListRenderer : RecordFileLinkRenderer,
  });
};

export const getRendererParamsForFieldType = (
  recordType: RecordType,
  type: RecordField["type"]
) => {
  const params = { recordTypeId: recordType.id };
  const additionalParams = RecordFieldType.visit(type, {
    values: () => undefined,
    status: () => undefined,
    userId: () => undefined,
    date: () => undefined,
    timestamp: () => undefined,
    number: () => undefined,
    boolean: () => undefined,
    text: () => undefined,
    file: () => undefined,
    link: (value) => ({ linkedRecordTypeId: value.linkedRecordTypeId }),
    id: () => undefined,
  });
  return { ...params, ...additionalParams };
};

export const EmailRenderer = (props: ICellRendererParams) => {
  return (
    <Link href={`mailto:${props.data.point_of_contact_email}`}>
      {props.data.point_of_contact_email}
    </Link>
  );
};

export const WebsiteRenderer = (props: ICellRendererParams) => {
  if (!props.data.website) {
    return <EmptyValue />;
  }
  return (
    <Flex align="center" height="100%">
      <WebsiteLink url={props.data.website} />
    </Flex>
  );
};

export const WebsiteLink = ({ url }: { url?: string | null }) => {
  if (!url) {
    return <EmptyValue />;
  }
  return (
    <Link href={url} target="_blank">
      <Flex align="center" gap="1" height="100%">
        <Text>{url.replace(/^(https?:\/\/)?(www\.)?/, "")} </Text>
        <IconExternalLink />
      </Flex>
    </Link>
  );
};

export const BadgeListRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return (
    <Flex display="inline-flex" gap="2">
      {props.value.map((value: string) => (
        <Badge color="gray">{value}</Badge>
      ))}
    </Flex>
  );
};

export const BadgeRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue text="-" />;
  }

  // Hack: ICellRendererParams can't handle cellRendererParams
  return (
    <DefaultStatusBadge status={props.value} color={(props as any).color} />
  );
};

export const StatusRenderer = (props: ICellRendererParams) => {
  // Add class to parent to vertically center the cell
  // Class is defined in baseAgGrid.css
  props.eParentOfValue.classList.add("mrdn-vertically-centered-cell-parent");
  props.eGridCell.classList.add("mrdn-hide-overflow");

  const recordTypeId = (props as any).recordTypeId;
  const statuses = useStatuses(recordTypeId);

  if (!recordTypeId || !statuses.length) {
    return <DefaultStatusBadge status={props.value} color="gray" />;
  }

  const status = statuses.find((s) => s.value === props.value);

  if (!status) {
    return <DefaultStatusBadge status={props.value} color="gray" />;
  }

  return <StatusBadge status={status} />;
};

export const DefaultStatusRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return <DefaultStatusBadge status={props.value} />;
};

export const TextRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return (
    <Text>
      {props.formatValue ? props.formatValue(props.value) : props.value}
    </Text>
  );
};

export const FileLinkRenderer = (
  props: ICellRendererParams,
  fetchFile: (filePath: string) => Promise<string>
) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  if (!isValidFileLink(props.value)) {
    return <ErrorValue />;
  }
  return <StorageFileLink filePath={props.value} fetchFile={fetchFile} />;
};

export const RecordFileLinkRenderer = (props: ICellRendererParams) => {
  return FileLinkRenderer(props, fetchRecordFile);
};

export const RecordFileLinkListRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return (
    <Flex display="inline-flex" gap="2">
      {props.value.map((filePath: string) => (
        <StorageFileLink filePath={filePath} fetchFile={fetchRecordFile} />
      ))}
    </Flex>
  );
};

export const RecordLinkRenderer = (props: ICellRendererParams) => {
  const linkedRecordTypeId = (props as any).linkedRecordTypeId;
  if (!linkedRecordTypeId) {
    return props.value;
  }
  return (
    <RecordLink recordTypeId={linkedRecordTypeId} recordId={props.value} />
  );
};

export const RecordLinkListRenderer = (props: ICellRendererParams) => {
  const linkedRecordTypeId = (props as any).linkedRecordTypeId;
  if (!linkedRecordTypeId) {
    return props.value;
  }

  if (!props.value) {
    return <EmptyValue />;
  }
  return (
    <Flex display="inline-flex" gap="2">
      {props.value.map((recordId: string) => (
        <RecordLink recordTypeId={linkedRecordTypeId} recordId={recordId} />
      ))}
    </Flex>
  );
};

export const DocumentFileLinkRenderer = (props: ICellRendererParams) => {
  return FileLinkRenderer(props, fetchDocumentFile);
};

export const PlainLinkRenderer = (props: ICellRendererParams) => {
  return <Link underline="always">{props.value}</Link>;
};

export const UserListRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return (
    <Flex gap="2">
      {props.value.map((userId: string) => (
        <User userId={userId} />
      ))}
    </Flex>
  );
};

export const UserRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue text="None" />;
  }
  return <User userId={props.value} />;
};

export const DateRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return <FormattedDate dateString={props.value} />;
};

export const TimestampRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return <RelativeTime timestamp={props.value} />;
};

export const RecordTypeRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return <RecordTypeLabel recordTypeId={props.value} />;
};

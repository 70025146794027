import { PermissionedButton } from "@/components/PermissionedButton";
import { useDeleteRecordType } from "@/state/mutations/record-types/deleteRecordType";
import { RecordType } from "@/types/recordTypes";
import { AlertDialog } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import MeridianAlertDialog from "../alert-dialogs";

export const DeleteRecordTypeAction: React.FC<{
  recordType: RecordType;
  onDelete?: () => void;
}> = ({ recordType, onDelete }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: deleteRecordType,
    isPending,
    error,
    reset,
  } = useDeleteRecordType();

  const onSubmit = () => {
    deleteRecordType(recordType.id, {
      onSuccess: () => {
        setOpen(false);
        onDelete?.();
      },
    });
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
        >
          <IconTrash />
          Delete
        </PermissionedButton>
      </AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Delete record type?"
          description="This record type will no longer be available and any associated records will be deleted."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Delete record type",
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

import { Inset, Separator, SeparatorProps } from "@radix-ui/themes";

interface FullWidthSeparatorProps extends SeparatorProps {
  size?: SeparatorProps["size"];
}

export const FullWidthSeparator: React.FC<FullWidthSeparatorProps> = ({
  size = "4",
  ...props
}) => {
  return (
    <Inset side="x">
      <Separator size={size} {...props} />
    </Inset>
  );
};

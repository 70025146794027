import { supabase } from "@/supabaseClient";
import { useMutation } from "@tanstack/react-query";

interface LoginInput {
  email: string;
  password: string;
}

const login = async (input: LoginInput) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email: input.email,
    password: input.password,
  });

  if (error) {
    throw error;
  }

  return data;
};

export const useLogin = () => {
  return useMutation({
    mutationFn: (input: LoginInput) => login(input),
  });
};

import { useTenantUsers } from "@/state/queries/tenantUsers";
import { RecordFieldType } from "@/types/recordFields";
import { RecordType } from "@/types/recordTypes";
import { ProcessCellForExportParams } from "ag-grid-community";
import dayjs from "dayjs";
import { keyBy } from "lodash-es";

export const useProcessCellForExport = (recordType: RecordType | undefined) => {
  const { data: users } = useTenantUsers();
  const userMap = keyBy(users, "userId");

  const recordTypeFields = keyBy(recordType?.fields, "id");

  return (params: ProcessCellForExportParams) => {
    if (!recordType) {
      return defaultExportFormatter(params);
    }
    const field = recordTypeFields[params.column.getColId()];
    if (!field) {
      return defaultExportFormatter(params);
    }

    if (RecordFieldType.isTimestamp(field.type) && params.value) {
      return dayjs(params.value).format("YYYY-MM-DD HH:mm:ss");
    }
    if (RecordFieldType.isDate(field.type) && params.value) {
      return dayjs(params.value).format("YYYY-MM-DD");
    }
    if (RecordFieldType.isFile(field.type) && params.value) {
      return params.value.split("/").pop();
    }
    if (RecordFieldType.isUserId(field.type) && params.value) {
      const user = userMap[params.value];
      return user?.fullName ?? user?.email ?? params.value;
    }

    return defaultExportFormatter(params);
  };
};

const defaultExportFormatter = (params: ProcessCellForExportParams) => {
  const formattedValue = params.formatValue(params.value);
  return formattedValue !== "-" ? formattedValue : undefined;
};

import { FileValue } from "@/types/records";
import { Badge, Flex, IconButton, Text } from "@radix-ui/themes";
import { IconX } from "@tabler/icons-react";
import { DeepKeys, useField } from "@tanstack/react-form";
import { FileRejection, useDropzone } from "react-dropzone";
import { FormField } from "../forms/FormField";
import { BaseFieldProps } from "../forms/types";

export interface FileDropzoneProps<TData> extends BaseFieldProps<TData> {
  multiple?: boolean;
}

/**
 * A file dropzone component for use in action forms.
 * Stores values using FileValue objects to support both existing and new files,
 * which are then picked up and handled in the executeAction.ts mutation.
 */
export function FileDropzone<TData>(props: FileDropzoneProps<TData>) {
  const { reactFormField, fieldProps, multiple } = props;

  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const currentFiles = (field.state.value as FileValue[] | null) ?? [];

  // Simple typed wrapper around field.handleChange.
  // If multiple is false, we only allow one file to be uploaded.
  const handleFieldChange = (value: FileValue[]) => {
    if (!multiple) {
      const newValue = value.length > 0 ? [value[0]] : [];
      field.handleChange(newValue as any);
    } else {
      field.handleChange(value as any);
    }
  };

  // TODO: Handle file rejections
  const onDrop = (files: File[], fileRejections: FileRejection[]) => {
    const newFiles: FileValue[] = files.map((file) => FileValue.new({ file }));
    if (!multiple) {
      // Overwrite current files (the function above will select the first one)
      handleFieldChange(newFiles);
    } else {
      // Append new files to the list of files
      handleFieldChange([...currentFiles, ...newFiles]);
    }
  };

  const removeFileAtIndex = (index: number) => {
    handleFieldChange(currentFiles.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    multiple: multiple,
    preventDropOnDocument: true,
    maxFiles: 5,
    maxSize: 50 * 1024 * 1024, // 50MB
  });

  let filesLabel = "No files uploaded";
  if (multiple && currentFiles.length > 0) {
    filesLabel = `${currentFiles.length} file${currentFiles.length > 1 ? "s" : ""}:`;
  } else if (currentFiles.length > 0) {
    filesLabel = "File: ";
  }
  const filesPreview = (
    <Flex align="center" gap="2" pb="1" style={{ overflowX: "scroll" }}>
      <Text size="2" color="gray" style={{ whiteSpace: "nowrap" }}>
        {filesLabel}
      </Text>
      {currentFiles.length > 0 &&
        currentFiles.map((file, index) => {
          return (
            <UploadedFile
              key={index}
              file={file}
              onRemove={() => removeFileAtIndex(index)}
            />
          );
        })}
    </Flex>
  );

  return (
    <FormField
      reactForm={reactFormField.form}
      reactFormField={field}
      {...fieldProps}
    >
      <Flex direction="column" gap="2">
        <Flex
          {...getRootProps()}
          flexGrow="1"
          height="65px"
          align="center"
          justify="center"
          style={{
            backgroundColor: isDragActive
              ? "var(--accent-3)"
              : "var(--accent-2)",
            border: isDragActive
              ? "3px solid var(--accent-9)"
              : "1px dashed var(--accent-6)",
            borderRadius: "var(--radius-1)",
            cursor: "copy",
          }}
        >
          <input {...getInputProps()} />
          <Text size="2" color="gray">
            Drag and drop {multiple ? "files" : "a file"} here, or click to
            select {multiple ? "files" : "a file"}
          </Text>
        </Flex>
        {filesPreview}
      </Flex>
    </FormField>
  );
}

const UploadedFile = ({
  file,
  onRemove,
}: {
  file: FileValue;
  onRemove: () => void;
}) => {
  return (
    <Badge size="3">
      {FileValue.visit(file, {
        existing: (file) => file.filePath.split("/").pop(),
        new: (file) => file.file.name,
      })}
      <IconButton type="button" size="1" variant="ghost" onClick={onRemove}>
        <IconX />
      </IconButton>
    </Badge>
  );
};

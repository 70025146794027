import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface FrameworkMapping {
  frameworkId: string;
  clause: string;
}

export interface AddProcessControlInput {
  processId: string;
  processRevisionId: string;
  title: string;
  language: string;
  mappings: FrameworkMapping[];
}

const addControl = async (input: AddProcessControlInput, tenantId: string) => {
  const { data: control, error: controlError } = await supabase
    .from("controls")
    .insert({
      tenant_id: tenantId,
      process_id: input.processId,
    })
    .select("id")
    .single();

  if (controlError) {
    throw controlError;
  }

  const { error } = await supabase.from("control_revisions").insert({
    control_id: control.id,
    tenant_id: tenantId,
    process_revision_id: input.processRevisionId,
    language: input.language,
    title: input.title,
  });

  if (error) {
    throw error;
  }

  // TODO: Insert into control_requirements_mapping
};

export const useAddControl = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: AddProcessControlInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return addControl(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

import PageTitle from "@/components/PageTitle";
import { PermissionedButton } from "@/components/PermissionedButton";
import {
  CreateOrUpdateRecordTypeAction,
  RecordTypeDialogOperation,
} from "@/components/record-types/CreateOrUpdateRecordTypeAction";
import { SearchFilter } from "@/components/SearchFilter";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { useRecordAndLinkTypes } from "@/state/queries/recordLinkTypes";
import { RecordType } from "@/types/recordTypes";
import { Flex } from "@radix-ui/themes";
import { IconCubePlus } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ColDef } from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/settings/organization/record-types/"
)({
  beforeLoad: () => ({ getTitle: () => "" }),
  component: RecordTypes,
});

function RecordTypes() {
  const { tenantSlug } = Route.useParams();
  const navigate = Route.useNavigate();
  const [filter, setFilter] = useState("");
  const { data: recordTypesAndLinks } = useRecordAndLinkTypes();
  const recordTypes = recordTypesAndLinks?.recordTypes || [];

  const columnDefs: ColDef[] = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: ({ data, value }: { data: RecordType; value: string }) => (
        <Link
          to="/$tenantSlug/settings/organization/record-types/$recordTypeId"
          params={{ tenantSlug, recordTypeId: data.id }}
        >
          {value}
        </Link>
      ),
      width: 200,
    },
    { field: "key", headerName: "Key", width: 90 },
    {
      headerName: "Fields",
      valueGetter: ({ data }: { data: RecordType }) =>
        `${data.fields.length} fields`,
      width: 100,
    },
    { field: "description", headerName: "Description", flex: 1 },
  ];

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title="Record Types"
        description="Configure the types of records that can be stored and tracked in Meridian."
      />
      <Flex justify="between" align="center" py="2">
        <SearchFilter
          filter={filter}
          setFilter={setFilter}
          placeholder="Search record types"
        />
        <CreateOrUpdateRecordTypeAction
          operation={RecordTypeDialogOperation.create({})}
        >
          <PermissionedButton permission="write.processes">
            <IconCubePlus />
            Create record type
          </PermissionedButton>
        </CreateOrUpdateRecordTypeAction>
      </Flex>
      <BaseAgGrid<RecordType>
        rowData={recordTypes}
        columnDefs={columnDefs}
        quickFilterText={filter}
        selectableRows
        autoSizeStrategy={{
          type: "fitCellContents",
        }}
        onRowClicked={(rowClick) => {
          if (rowClick.data) {
            navigate({
              to: "/$tenantSlug/settings/organization/record-types/$recordTypeId",
              params: { tenantSlug, recordTypeId: rowClick.data.id },
            });
          }
        }}
      />
    </Flex>
  );
}

import { useWorkflowEditorContext } from "../WorkflowEditorContext";

import { Flex } from "@radix-ui/themes";

import MeridianDialog from "@/components/dialogs";
import Form from "@/components/forms";
import { StatusDisplaySelect } from "@/components/statuses/StatusDisplaySelect";
import { useForm } from "@tanstack/react-form";
import { StatusNodeData, StatusNodeType } from "../graph/StatusNode";

export const EditStatusDialogContent = ({ node }: { node: StatusNodeType }) => {
  const { clearEditingNode, updateNode } = useWorkflowEditorContext();

  const form = useForm<StatusNodeData>({
    defaultValues: node.data,
    onSubmit: async ({ value }) => {
      updateNode({
        ...node,
        data: value,
      });
      clearEditingNode();
    },
  });

  return (
    <MeridianDialog.Content maxWidth="450px">
      <MeridianDialog.Header title="Edit status" />
      <MeridianDialog.Body>
        <Form.Root reactForm={form}>
          <Flex direction="column" gap="4">
            <Form.Fields>
              <Flex align="end" gap="2">
                <StatusDisplaySelect<StatusNodeData>
                  reactFormField={{ form, name: "display" }}
                  fieldProps={{
                    label: "",
                  }}
                />
                <Form.TextField<StatusNodeData>
                  reactFormField={{ form, name: "value" }}
                  fieldProps={{
                    label: "Name",
                    required: true,
                    style: { flexGrow: "1" },
                  }}
                  placeholder="Enter status name"
                  required
                />
              </Flex>
            </Form.Fields>
            <Form.Footer
              form={form}
              error={null}
              isPending={false}
              buttonText="Save changes"
            />
          </Flex>
        </Form.Root>
      </MeridianDialog.Body>
    </MeridianDialog.Content>
  );
};

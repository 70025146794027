import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface EditProcessControlInput {
  controlRevisionId: string;
  title: string;
  language: string;
}

const editControl = async (
  input: EditProcessControlInput,
  tenantId: string
) => {
  const { error } = await supabase
    .from("control_revisions")
    .update({
      title: input.title,
      language: input.language,
    })
    .eq("tenant_id", tenantId)
    .eq("id", input.controlRevisionId);

  if (error) {
    throw error;
  }
};

export const useEditControl = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: EditProcessControlInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return editControl(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

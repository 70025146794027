import MeridianTabNav from "@/components/MeridianTabNav";
import PageTitle from "@/components/PageTitle";
import { RecordActions } from "@/components/records/RecordActions";
import { useRecordTypeById } from "@/state/queries/recordLinkTypes";
import { useRecord } from "@/state/queries/records";
import { Box, Container, Flex, Skeleton } from "@radix-ui/themes";
import { IconHistory, IconListDetails } from "@tabler/icons-react";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/records/$recordTypeId/$recordId"
)({
  beforeLoad: async ({ context, params }) => {
    if (!context.tenant) {
      return { getTitle: () => "Record" };
    }

    // TODO: Grab the title field for the breadcrumbs as well
    return {
      getTitle: () => params.recordId,
    };
  },
  component: Record,
});

const titleSize = (title?: string) => {
  if (!title) {
    return "7";
  }
  if (title.length <= 20) {
    return "7";
  }
  if (title.length <= 40) {
    return "6";
  }
  if (title.length <= 60) {
    return "5";
  }
  return "4";
};

function Record() {
  const { tenantSlug, recordTypeId, recordId } = Route.useParams();
  const { data: recordType } = useRecordTypeById(recordTypeId);
  const { data: record } = useRecord(recordTypeId, recordId);
  const navigate = Route.useNavigate();
  const isLoaded = recordType && record;

  const title: string | undefined = recordType?.titleFieldId
    ? record?.[recordType.titleFieldId]
    : record?.id;

  const navigateToRecordType = () => {
    navigate({
      to: "/$tenantSlug/records/$recordTypeId",
      params: {
        tenantSlug,
        recordTypeId,
      },
    });
  };

  return (
    // TODO: This is recreating layout from AppLayout.tsx; we should clean this up
    <Container
      p="5"
      align={{ sm: "left", md: "center" }}
      size={{ md: "3", lg: "4" }}
    >
      <Flex direction="column" gap="5">
        <PageTitle
          title={isLoaded ? title : <Skeleton>Record</Skeleton>}
          tag={isLoaded ? recordType.pluralName : ""}
          titleSize={titleSize(title)}
        >
          {isLoaded && (
            <RecordActions
              recordType={recordType}
              selectedRecordIds={[recordId]}
              onActionExecuted={(action) => {
                if (action.operation.type === "delete") {
                  navigateToRecordType();
                }
              }}
            />
          )}
        </PageTitle>
        <Box>
          <MeridianTabNav<"/$tenantSlug/records/$recordTypeId/$recordId">
            childRouteParams={{ tenantSlug, recordTypeId, recordId }}
            links={[
              {
                to: "/$tenantSlug/records/$recordTypeId/$recordId/fields",
                label: "Fields",
                icon: <IconListDetails />,
              },
              {
                to: "/$tenantSlug/records/$recordTypeId/$recordId/history",
                label: "History",
                icon: <IconHistory />,
              },
            ]}
          />
          <Outlet />
        </Box>
      </Flex>
    </Container>
  );
}

export default Record;

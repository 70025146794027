import { Flex } from "@radix-ui/themes";
import { LayoutProps, MarginProps } from "@radix-ui/themes/props";
import React from "react";
import { FullWidthSeparator } from "./FullWidthSeparator";

interface MeridianDialogFooterProps extends MarginProps, LayoutProps {
  children: React.ReactNode;
  hideSeparator?: boolean;
}

export const MeridianDialogFooter: React.FC<MeridianDialogFooterProps> = ({
  children,
  hideSeparator,
  ...props
}) => {
  return (
    <Flex direction="column" gap="4" {...props}>
      {!hideSeparator && <FullWidthSeparator />}
      {children}
    </Flex>
  );
};

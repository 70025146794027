import { AlertDialog } from "@radix-ui/themes";
import React from "react";
import MeridianAlertDialog from "../alert-dialogs";

interface ConfirmCancelDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => void;
}

export const ConfirmCancelDialog: React.FC<ConfirmCancelDialogProps> = ({
  isOpen,
  onOpenChange,
  onConfirm,
}) => {
  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Discard changes?"
          description="You have unsaved changes that will be lost if you leave."
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Discard changes",
          }}
          cancelButtonLabel="Keep editing"
          onConfirm={onConfirm}
          isPending={false}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

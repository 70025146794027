import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Process, ProcessRevision } from "../../../queries/processes";

const deleteProcessRevision = async ({
  process,
  revision,
}: {
  process: Process;
  revision: ProcessRevision;
}) => {
  const { error } = await supabase
    .from("process_revisions")
    .delete()
    .eq("id", revision.id)
    .eq("process_id", process.id)
    .eq("tenant_id", process.tenantId);

  if (error) {
    throw error;
  }
};

export const useDeleteProcessRevision = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: deleteProcessRevision,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

import { useTenantContext } from "@/contexts/TenantContext";
import { Process, ProcessRevision } from "@/state/queries/processes";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface RejectProcessRevisionInput {
  process: Process;
  revision: ProcessRevision;
}

const rejectProcessRevision = async (input: RejectProcessRevisionInput) => {
  const { error: processRevisionError } = await supabase
    .from("process_revisions")
    .update({
      status: "Draft",
      pending_review_at: null,
    })
    .eq("id", input.revision.id)
    .eq("process_id", input.process.id)
    .eq("tenant_id", input.process.tenantId);

  if (processRevisionError) {
    throw processRevisionError;
  }

  // If the process is in initial review, update its status to draft
  if (input.process.status === "In review") {
    const { error: processError } = await supabase
      .from("processes")
      .update({
        status: "Draft",
      })
      .eq("id", input.process.id)
      .eq("tenant_id", input.process.tenantId);

    if (processError) {
      throw processError;
    }
  }
};

export const useRejectProcessRevision = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: RejectProcessRevisionInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return rejectProcessRevision(input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

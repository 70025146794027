import { PermissionedButton } from "@/components/PermissionedButton";
import { useApproveRevision } from "@/state/mutations/documents/approveRevision";
import { Document } from "@/state/queries/documents";
import { THEME_COLOR } from "@/theme";
import { AlertDialog } from "@radix-ui/themes";
import { IconCheck } from "@tabler/icons-react";
import { useState } from "react";
import MeridianAlertDialog from "../alert-dialogs";

export const ApproveRevisionAction: React.FC<{
  document: Document;
  revisionId: string;
  isInitialRevision: boolean;
  onSuccess?: () => void;
}> = ({ document, revisionId, isInitialRevision, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: approveRevision,
    isPending,
    error,
    reset,
  } = useApproveRevision(isInitialRevision);

  const onSubmit = () => {
    approveRevision(
      { documentId: document.id, revisionId, tenantId: document.tenantId },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.documents"
          variant="soft"
          color="green"
        >
          <IconCheck />
          Approve
        </PermissionedButton>
      </AlertDialog.Trigger>

      <MeridianAlertDialog.Content>
        <MeridianAlertDialog.Header
          title="Approve revision?"
          description="Once approved, this revision will be released and become active. To make further changes, create a new revision in the Revisions tab."
          errorMessage={error?.message}
        />

        <MeridianAlertDialog.Footer
          submitButton={{
            label: "Approve revision",
            color: THEME_COLOR,
          }}
          onConfirm={onSubmit}
          isPending={isPending}
        />
      </MeridianAlertDialog.Content>
    </AlertDialog.Root>
  );
};

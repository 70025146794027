import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useCreateGroup } from "@/state/mutations/groups/createGroup";
import { useUpdateGroup } from "@/state/mutations/groups/updateGroup";
import { TenantGroup } from "@/state/queries/tenantGroups";
import { Dialog } from "@radix-ui/themes";
import { IconPencil, IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useState } from "react";
import { z } from "zod";
import MeridianDialog from "../dialogs";

interface CreateOrUpdateGroupFormInputs {
  name: string;

  /**
   * List of user IDs of group members
   */
  members: string[];
}

export const CreateOrUpdateGroupAction: React.FC<{
  operation: "create" | "update";
  group?: TenantGroup;
}> = ({ operation, group }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: createGroup,
    error: createGroupError,
    isPending: isPendingCreateGroup,
    reset: resetCreateGroup,
  } = useCreateGroup();
  const {
    mutate: updateGroup,
    error: updateGroupError,
    isPending: isPendingUpdateGroup,
    reset: resetUpdateGroup,
  } = useUpdateGroup();

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    resetCreateGroup();
    resetUpdateGroup();
  };

  const handleSuccess = () => {
    setOpen(false);
    resetCreateGroup();
    resetUpdateGroup();
  };

  const form = useForm<CreateOrUpdateGroupFormInputs, any>({
    defaultValues: {
      name: group?.name ?? "",
      members: group?.users.map((user) => user.userId) ?? [],
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        name: z.string().min(1, { message: "Group name is required" }),
        members: z.array(z.string()),
      }),
    },
    onSubmit: ({ value }) => {
      if (operation === "create") {
        createGroup(
          {
            name: value.name,
            members: value.members,
          },
          { onSuccess: handleSuccess }
        );
      } else if (group) {
        updateGroup(
          {
            input: {
              name: value.name,
              membersAdded: value.members.filter(
                (member) =>
                  !group.users.map((user) => user.userId).includes(member)
              ),
              membersRemoved: group.users
                .filter((user) => !value.members.includes(user.userId))
                .map((user) => user.userId),
            },
            groupId: group.id,
          },
          { onSuccess: handleSuccess }
        );
      }
    },
  });

  const title = operation === "create" ? "Create group" : "Update group";

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton
          permission="write.groups"
          variant={operation === "create" ? "solid" : "soft"}
        >
          {operation === "create" ? <IconPlus /> : <IconPencil />}
          {title}
        </PermissionedButton>
      </Dialog.Trigger>

      <MeridianDialog.Content maxWidth="450px">
        <MeridianDialog.Header title={title} />
        <MeridianDialog.Body>
          <Form.Root reactForm={form}>
            <Form.Fields>
              <Form.TextField<CreateOrUpdateGroupFormInputs>
                reactFormField={{ form, name: "name" }}
                fieldProps={{
                  label: "Group name",
                  required: true,
                }}
              />
              <Form.UserMultiSelect<CreateOrUpdateGroupFormInputs>
                reactFormField={{ form, name: "members" }}
                fieldProps={{ label: "Members", required: true }}
              />
            </Form.Fields>
            <Form.Footer
              form={form}
              error={createGroupError || updateGroupError}
              isPending={isPendingCreateGroup || isPendingUpdateGroup}
              buttonText="Submit"
              errorPrefix="Failed to save group: "
            />
          </Form.Root>
        </MeridianDialog.Body>
      </MeridianDialog.Content>
    </Dialog.Root>
  );
};

import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { keyBy, mapValues } from "lodash-es";
import { z } from "zod";

export type Tenants = { [tenantSlug: string]: Tenant };

export interface Tenant {
  tenantId: string;
  name: string;
  slug: string;
  userPermissions: Set<string>;
  logoPath?: string;
  recordsSchema: string;
}

const userPermissionsByTenantIdSchema = z.record(
  z.string(),
  z.array(z.string())
);
const fetchTenants = async (): Promise<Tenants> => {
  // Skip making request if user is not signed in
  const { data: session } = await supabase.auth.getSession();
  if (session.session == null) {
    throw new Error("User not signed in");
  }

  const [
    { data: userPermissions, error: userPermissionsError },
    { data: tenantRoles, error: tenantRolesError },
  ] = await Promise.all([
    supabase.rpc("get_user_permissions"),
    supabase.schema("tenants").from("tenants").select("*"),
  ]);

  if (userPermissionsError) {
    throw userPermissionsError;
  }
  if (tenantRolesError) {
    throw tenantRolesError;
  }

  if (!userPermissions || !tenantRoles) {
    return {};
  }

  const userPermissionsByTenantId =
    userPermissionsByTenantIdSchema.parse(userPermissions);

  return mapValues(keyBy(tenantRoles, "slug"), (tenant) => ({
    tenantId: tenant.id,
    name: tenant.name,
    slug: tenant.slug,
    userPermissions: new Set(userPermissionsByTenantId[tenant.id]),
    logoPath: tenant.logo_path ?? undefined,
    recordsSchema: tenant.records_schema,
  }));
};

export const tenantsQueryOptions = queryOptions({
  queryKey: ["tenants"],
  queryFn: fetchTenants,
  staleTime: Infinity,
  retry: false,
});

export const useTenants = () => {
  return useQuery(tenantsQueryOptions);
};

import { useTenantContext } from "@/contexts/TenantContext";
import { ExecutableAction } from "@/hooks/useActionTypes";
import { supabase } from "@/supabaseClient";
import { ActionFormFieldTypeData } from "@/types/actionFormField";
import { RecordFieldType } from "@/types/recordFields";
import { FileValue } from "@/types/records";
import { RecordType } from "@/types/recordTypes";
import { meridianNanoid } from "@/utils/meridianNanoid";
import { maybeConvertApiError } from "@/utils/requests";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

/**
 * This type is shared with the backend. All changes must be reflected there.
 */
export interface ExecuteActionRequest {
  tenantId: string;
  workflowId: string;
  actionId: string;
  recordId: string;
  fieldValues: { [fieldId: string]: any };
}

interface ExecuteActionParams {
  tenantId: string;
  action: ExecutableAction;
  recordType: RecordType;
  recordId?: string;
  fieldValues: { [fieldId: string]: any };
}

/**
 * Uploads a file to storage and creates a record_files entry.
 * Returns the newly created storage file path.
 */
const uploadFile = async (
  file: File,
  tenantId: string,
  recordTypeId: string,
  recordId: string,
  fieldId: string
) => {
  const fileId = meridianNanoid();
  const filePath = `${tenantId}/${fileId}/${file.name}`;

  // Upload file to storage using generated ID
  const { error: storageError } = await supabase.storage
    .from("record_files")
    .upload(filePath, file);

  if (storageError) {
    throw new Error(storageError.message);
  }

  // Create record_files entry with same ID
  const { error } = await supabase.from("record_files").insert({
    id: fileId,
    tenant_id: tenantId,
    record_type_id: recordTypeId,
    record_id: recordId ?? "",
    field_id: fieldId,
    original_filename: file.name,
    content_type: file.type,
    file_path: filePath,
  });

  if (error) {
    throw new Error(error.message);
  }

  return filePath;
};

/**
 * Given a record FileValue, returns the file path to add to the record field.
 * Existing files are simply returned, while new files are uploaded to storage
 * and the new file path is returned.
 */
const getFilePathToAdd = async (
  file: FileValue,
  input: ExecuteActionParams,
  fieldId: string
) => {
  return FileValue.visit(file, {
    existing: async (file) => file.filePath,
    new: async (file) => {
      const filePath = await uploadFile(
        file.file,
        input.tenantId,
        input.recordType.id,
        input.recordId ?? "",
        fieldId
      );
      return filePath;
    },
  });
};

const FileValueSchema: z.Schema<FileValue[]> = z.array(
  z.discriminatedUnion("type", [
    z.object({
      type: z.literal("existing"),
      existing: z.object({
        filePath: z.string(),
      }),
    }),
    z.object({
      type: z.literal("new"),
      new: z.object({
        file: z.instanceof(File),
      }),
    }),
  ])
);

const handleFileUploads = async (input: ExecuteActionParams) => {
  // Handle file upload fields
  const fileFieldUploadPaths: { [fieldId: string]: string } = {};
  for (const [fieldId, value] of Object.entries(input.fieldValues)) {
    const field = input.action.form.fields.find((f) => f.id === fieldId);

    if (field && ActionFormFieldTypeData.isFile(field.type)) {
      // TODO: Figure out how to handle file uploads for create actions
      if (!input.recordId) {
        // https://linear.app/meridian-tech/issue/MER-74
        throw new Error(
          "Uploading files while creating a record is not currently supported"
        );
      }

      const recordField = input.recordType.fields.find((f) => f.id === fieldId);
      if (!recordField) {
        throw new Error(
          `Field ${fieldId} not found in record type ${input.recordType.id}`
        );
      }

      if (!RecordFieldType.isFile(recordField.type)) {
        throw new Error(
          `Field ${fieldId} is not a file field in record type ${input.recordType.id}`
        );
      }

      // Note: These values are populated by the FileDropzone component
      let files: FileValue[] = [];
      try {
        files = FileValueSchema.parse(value) as FileValue[];
      } catch (error) {
        console.error(error);
        throw new Error(
          "Invalid file value found. Unable to upload files to record."
        );
      }

      const allowMultiple = recordField.type.file.allowMultiple;
      if (allowMultiple) {
        const filePaths: string[] = [];
        for (const file of files) {
          const filePathToAdd = await getFilePathToAdd(file, input, fieldId);
          filePaths.push(filePathToAdd);
        }
        input.fieldValues[fieldId] = filePaths;
        continue;
      }

      // Empty required field
      if (field.required && files.length === 0) {
        throw new Error(
          `Expected 1 file, got ${files.length} for field ${fieldId}`
        );
      }

      // Empty optional field
      if (!field.required && files.length === 0) {
        input.fieldValues[fieldId] = null;
        continue;
      }

      // Single file upload
      const file = files[0];
      const filePathToAdd = await getFilePathToAdd(file, input, fieldId);
      input.fieldValues[fieldId] = filePathToAdd;
    }
  }

  return fileFieldUploadPaths;
};

const executeAction = async (input: ExecuteActionParams) => {
  const fileFieldUploadPaths = await handleFileUploads(input);

  // Merge file uploads into input field values
  const updatedFieldValues = { ...input.fieldValues, ...fileFieldUploadPaths };

  // Create request shape for backend
  const executeActionRequest: ExecuteActionRequest = {
    tenantId: input.tenantId,
    workflowId: input.action.workflowId,
    actionId: input.action.id,
    recordId: input.recordId ?? "",
    fieldValues: updatedFieldValues,
  };

  const { data, error } = await supabase.functions.invoke("actions/execute", {
    method: "POST",
    body: executeActionRequest,
  });

  if (error) {
    const apiError = await maybeConvertApiError(error);
    return Promise.reject(apiError);
  }

  try {
    return JSON.parse(data);
  } catch (e) {
    console.error("Failed to parse action result", e);
  }
};

export const useExecuteAction = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: Omit<ExecuteActionParams, "tenantId">) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return executeAction({ ...input, tenantId: tenant.tenant?.tenantId });
    },
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: [
          "records",
          tenant.tenant.recordsSchema,
          params.action.recordTypeId,
        ],
      });
      if (params.recordId) {
        queryClient.invalidateQueries({
          queryKey: ["recordHistory", tenant.tenant.tenantId, params.recordId],
        });
      }
    },
  });
};

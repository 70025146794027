import EmptyPage from "@/components/EmptyPage";
import Form from "@/components/forms";
import { useIsInitialLoading } from "@/hooks/useIsInitialLoading";
import { useTenantRedirect } from "@/hooks/useTenantRedirect";
import { useLogin } from "@/state/mutations/login";
import { useTenants } from "@/state/queries/tenants";
import { Box, Flex, Heading, Spinner, Text } from "@radix-ui/themes";
import { useForm } from "@tanstack/react-form";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import css from "./login.module.scss";

export const Route = createFileRoute("/login")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  component: Login,
});

interface LoginInput {
  email: string;
  password: string;
}

function Login() {
  const redirect = Route.useSearch({
    select: (search) => search.redirect,
  });
  useTenantRedirect(redirect);
  const isInitialLoading = useIsInitialLoading();

  const navigate = useNavigate();
  const { refetch: refetchTenants } = useTenants();
  const { mutate: login, isPending, error } = useLogin();

  const form = useForm<LoginInput, any>({
    defaultValues: {
      email: "",
      password: "",
    },
    validatorAdapter: zodValidator(),
    validators: {
      onSubmit: z.object({
        email: z.string().email({ message: "Enter a valid email address" }),
        password: z.string().min(1, { message: "Enter a password" }),
      }),
    },
    onSubmit: ({ value }) => {
      login(value, {
        onSuccess: async () => {
          await refetchTenants();
          navigate({ to: redirect || "/" });
        },
      });
    },
  });

  if (isInitialLoading) {
    return <EmptyPage icon={<Spinner />} />;
  }

  return (
    <Flex height="100vh" style={{ background: "var(--gray-2)" }}>
      <Flex align="center" gap="2" position="absolute" top="4" left="5">
        <img src="/apple-touch-icon.png" alt="Meridian Logo" width={24} />
        <Text size="4" weight="bold">
          Meridian
        </Text>
      </Flex>
      <Flex className={css.login} flexGrow="1" justify="center" align="center">
        <Box p="6" width="400px">
          <Heading size="6" mb="4">
            Welcome to Meridian
          </Heading>
          <Form.Root reactForm={form}>
            <Form.Fields gap="4">
              <Form.TextField<LoginInput>
                name="email"
                reactFormField={{ form, name: "email" }}
                fieldProps={{
                  label: "Email",
                }}
                placeholder="Email"
              />
              <Form.TextField<LoginInput>
                type="password"
                name="password"
                reactFormField={{ form, name: "password" }}
                fieldProps={{
                  label: "Password",
                }}
                placeholder="Password"
              />
            </Form.Fields>
            <Form.Footer
              form={form}
              isPending={isPending}
              error={error}
              buttonText="Log in"
              errorPrefix="Failed to log in: "
              hideCancelButton
              hideSeparator
            />
          </Form.Root>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;

import { useCreateProcessRevision } from "@/state/mutations/processes/revisions/createProcessRevision";
import { Process } from "@/state/queries/processes";
import { IconPencil } from "@tabler/icons-react";
import { PermissionedButton } from "../PermissionedButton";

interface CreateProcessRevisionButtonProps {
  process: Process;
  onSuccess?: (processRevisionId: string) => void;
}

export const CreateProcessRevisionButton: React.FC<
  CreateProcessRevisionButtonProps
> = ({ process, onSuccess }) => {
  const { mutate: createRevision, isPending } = useCreateProcessRevision();

  const handleClick = () => {
    createRevision(
      {
        process,
        latestRevision: process.latestRevision,
      },
      {
        onSuccess: (processRevisionId) => {
          onSuccess?.(processRevisionId);
        },
      }
    );
  };

  return (
    <PermissionedButton
      permission="write.processes"
      variant="ghost"
      size="1"
      onClick={handleClick}
      loading={isPending}
    >
      <IconPencil />
      New
    </PermissionedButton>
  );
};

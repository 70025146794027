import { ActionButton } from "@/components/actions/ActionButton";
import { ExecutableAction, useActionTypes } from "@/hooks/useActionTypes";
import { useRecords } from "@/state/queries/records";
import { ActionOperation } from "@/types/actions";
import { RecordFieldValues } from "@/types/records";
import { RecordType } from "@/types/recordTypes";
import { Button, DropdownMenu, Flex } from "@radix-ui/themes";
import { IconChevronDown } from "@tabler/icons-react";
import { sumBy } from "lodash-es";
import { useState } from "react";
import { ActionDialog } from "../actions/ActionDialog";
import { ActionDropdownItem } from "../actions/ActionDropdownItem";

const ACTION_THRESHOLD = 5;
const NAME_LENGTH_THRESHOLD = 60;

export const RecordActions: React.FC<{
  recordType: RecordType;
  selectedRecordIds: string[];
  onActionExecuted: (
    action: ExecutableAction,
    newRecordValues?: RecordFieldValues
  ) => void;
  actionCountThreshold?: number;
  nameLengthThreshold?: number;
}> = ({
  recordType,
  selectedRecordIds,
  onActionExecuted,
  actionCountThreshold = ACTION_THRESHOLD,
  nameLengthThreshold = NAME_LENGTH_THRESHOLD,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<ExecutableAction | null>(
    null
  );

  const { data: records = [] } = useRecords(recordType.id);
  const selectedRecords = records.filter((record) =>
    selectedRecordIds.includes(record.id)
  );

  const selectedStatuses = selectedRecords
    .filter((record) => record.status != null)
    .map((record) => record.status);

  const createActions = useActionTypes({
    recordTypeId: recordType.id,
    operations: ["create"],
  });
  const modifyActions = useActionTypes({
    recordTypeId: recordType.id,
    operations: ["update", "delete"],
    status:
      selectedStatuses.length === 1
        ? (selectedStatuses[0] ?? undefined)
        : undefined,
  });

  const showModifyActions = selectedRecordIds.length === 1;
  const actions = showModifyActions ? modifyActions : createActions;
  const actionNameLength = sumBy(actions, (action) => action.name.length);

  const renderDropdown =
    actions.length >= actionCountThreshold ||
    actionNameLength >= nameLengthThreshold;

  let maybeSortedActions = actions;
  if (showModifyActions) {
    if (renderDropdown) {
      maybeSortedActions = actions.sort((a, b) => {
        if (ActionOperation.isDelete(a.operation)) return 1;
        if (ActionOperation.isUpdate(a.operation)) return -1;
        return a.name.localeCompare(b.name);
      });
    } else {
      maybeSortedActions = actions.sort((a, b) => {
        if (ActionOperation.isDelete(a.operation)) return -1;
        if (ActionOperation.isDelete(b.operation)) return 1;
        return a.name.localeCompare(b.name);
      });
    }
  }

  const dialog = selectedAction ? (
    <ActionDialog
      key={selectedAction.id}
      isOpen={isDialogOpen}
      setIsOpen={setIsDialogOpen}
      action={selectedAction}
      recordType={recordType}
      selectedRecord={selectedRecords[0]}
      onActionExecuted={onActionExecuted}
    />
  ) : null;

  if (!renderDropdown) {
    return (
      <>
        {dialog}
        <Flex gap="2">
          {maybeSortedActions.map((action) => (
            <ActionButton
              key={action.id}
              action={action}
              onClick={() => {
                setSelectedAction(action);
                setIsDialogOpen(true);
              }}
            />
          ))}
        </Flex>
      </>
    );
  }

  return (
    <>
      {dialog}
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button>
            Actions <IconChevronDown />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="end">
          {maybeSortedActions.map((action, index) => (
            <ActionDropdownItem
              key={index}
              action={action}
              onClick={() => {
                setSelectedAction(action);
                setIsDialogOpen(true);
              }}
            />
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
};
